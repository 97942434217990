import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assests
import { theme } from '../../Theme';

export const StyledToastContainer = styled.div`
  display: flex;
  height: 60px;
  padding: var(--sizes-16, 16px);

  align-items: center;
  gap: 24px;
  flex-shrink: 0;

  border-radius: var(--notifications-notification-toast-corner-radius, 8px);
  border: var(--sizes-none, 1px) solid ${theme.colors.semantic.positive.normal};
  background: ${theme.colors.semantic.positive.light};
  color: ${theme.colors.neutral[700]} !important;

  /* Dropdowns */
  box-shadow: 1px 2px 6px 0px rgba(48, 103, 110, 0.20);

  ${({
  type,
}) => {
    switch (type) {
      case 'error':
        return `
          background: ${theme.colors.semantic.negative.light};
          border-color: ${theme.colors.semantic.negative.normal};
          
          svg {
            path {
              fill: ${theme.colors.semantic.negative.normal};
            }
          }
        `;
      case 'info':
        return `
          background: ${theme.colors.semantic.informative.light};
          border-color: ${theme.colors.semantic.informative.normal};
          svg {
            path {
              fill: ${theme.colors.semantic.informative.normal};
            }
          }
        `;
      case 'warning':
        return `
          background: ${theme.colors.semantic.warning.light};
          border-color: ${theme.colors.semantic.warning.normal};
          svg {
            path {
              fill: ${theme.colors.semantic.warning.normal};
            }
          }
        `;
      case 'success':
      default:
        return `
          background: ${theme.colors.semantic.positive.light};
          border-color: ${theme.colors.semantic.positive.normal};
          svg {
            path {
              fill: ${theme.colors.semantic.positive.normal};
            }
          }
        `;
    }
  }}
`;

export const StyledContent = styled.div`
  display: flex;
  padding: var(--sizes-none, 0px);
  align-items: center;
  gap: var(--sizes-8, 8px);
  color: ${theme.colors.neutral[700]} !important;
`;

export const StyledIcon = styled.div`
  margin-right: 10px;
  width: 22px;
  height: 22px;
  svg {
    min-height: 17px;
    min-width: 17px;
  }

  &:empty {
    display: none;
  }
`;


export const ToastContainerGeneral = styled(ToastContainer)`
  width: 100% !important;
  max-width: 395px !important;
  bottom: 0;
  
  ${({ theme }) => theme.mediaQueries.minTablet} {
    width: 395px !important;
  }
`;