import { styled } from "styled-components";
import { colors, theme } from "../../Theme";
import { Body } from "./Text";
import { forwardRef } from "react";

const StyledHamburger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  ${theme.mediaQueries.maxMobile} {
    gap: 16px;
    flex-direction: row;
  }
`;

const StyledHamburgerItem = styled.div`
  display: flex; 
  flex-direction: ${(props) => (props.$mirror ? "row-reverse" : "row")};
  align-items: end;
  margin-bottom: 5px;
  cursor: pointer;
  align-items: center;
  
  p {
    ${(props) => (props.$mirror ? "margin-left: 10px;" : "margin-right: 10px;")}
    margin-bottom: 0;
    ${theme.mediaQueries.maxMobile} {
      display: none;
    };
  }
  i {
    color: inherit;
  }

  ${theme.mediaQueries.maxMobile} {
    margin-right: 10px;
  }

  &:hover {
    color: ${colors.primary[100]};
    p {
      color: ${colors.primary[100]};
    }
    i {
      color: ${colors.primary[100]};
    }
  }
`;

export const HamburgerItem = forwardRef(({ label, icon, mirror = false, ...props }, checkmarkRef) => {
  return (
    <StyledHamburgerItem $mirror={mirror} {...props} ref={checkmarkRef}>
      <Body.MD.Regular>{label}</Body.MD.Regular>
      {icon}
    </StyledHamburgerItem>
  );
});

export const FooterItem = forwardRef(({ label, icon, mirror = false, ...props }, coachmarkRef) => {
  return (
    <StyledHamburgerItem {...props} $mirror={mirror} ref={coachmarkRef}>
      <Body.SM.Light>{label}</Body.SM.Light>
      {icon}
    </StyledHamburgerItem>
  );
});

export const Hamburger = ({ className, children }) => {
  return <StyledHamburger className={className}>{children}</StyledHamburger>;
};
