import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { colorThemeActions } from "../../Store/ColorThemeState";
import { loginActions } from "../../Store/LoginPageState";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../OurCustomHooks/useAxios";
import { Body, H5 } from "../../Commons/Text";
import FormInput, { ErrorBox, SucceededBox } from "../../Commons/FormInput";
import { ButtonWithIcons } from "../../Commons/Buttons";
import { serialize } from "object-to-formdata";
import ImageGallery from "../../Commons/Gallery";
import { StyledA } from "./styles";
import { DropdownSelect } from "../../Commons/DropdownSelect";
import { customToast } from "../../../utils/toasts";
import useBreakpoint from "../../../OurCustomHooks/useBreakpoints";
import { DeleteModal } from "./DeleteModal";
import { ModalWrapper } from "../../Commons/ModalWrapper";
import Tabs from "../../Commons/Tabs";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { theme } from "../../../Theme";
import { useLogout } from "../../../OurCustomHooks/useLogout";
import Icon from "../../Commons/Icon";
import { LogoutModal } from "../../Dashboard/LogoutModal";

const SettingsContainer = styled(Container)`
  ${theme.mediaQueries.maxMobile} {
    height: calc(100vh - 71px);
  }
`;

const TabContainer = styled(Col)`
  transition: all 0.5s ease-in-out;
  padding-bottom: 10px;

  ${theme.mediaQueries.maxMobile} {
  }
`;

const GeneralSettingMain = () => {
  const axiosClient = useAxios();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();
  const { isMobile } = useBreakpoint();

  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  // const selectedImage = useSelector((state) => state.colorTheme.imagee);

  const [extraClick, setExtraClick] = useState(false);

  const [axiosMsg, setAxiosMsg] = useState("");

  const [listID, setListID] = useState("");
  const [extra, setExtra] = useState(false);

  const [postStatus, setPostStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [changeImage, setChangeImage] = useState();

  const logoutFn = useLogout();

  const formMethods = useForm();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const imageChangeHandler = async (result) => {
    setChangeImage(result);
    // TODO: call new upload image endpoint
  };

  const updateUserData = (data) => {
    setPostStatus(null);
    let demoData = {
      ...data,
      listId: listID,
      userId: loginDataRedux.userID,
      _id: loginDataRedux._id,
    };

    const formData1 = serialize({ demoData });
    setLoading(true);
    axiosClient
      .post(process.env.REACT_APP_API_BASE_URL + `/generalSetting`, formData1, {
        onUploadProgress: (progressEvent) => {
          // let percentComplete = progressEvent.loaded / progressEvent.total;
          // percentComplete = parseInt(percentComplete * 100);
          // setPercentage(percentComplete);
        },
      })
      .then((response) => {
        setPostStatus("succeeded");
        setLoading(false);
        setTimeout(function () {
          // closeModal();
          // setPercentage(0);
          if (response.data.msg === "_successfully updated") {
            setAxiosMsg("_successfully updated");
            setExtraClick(true);
          } else if (response.data.msg === "_successfully added") {
            setAxiosMsg("_successfully added");
            setExtraClick(true);
          } else if (response.data.error === "File is required!") {
            // Swal.fire({
            //   title: "Warning!",
            //   text: "File is required!",
            //   icon: "warning",
            //   confirmButtonText: "OK",
            // });
          } else if (response.data.error === "Max file size 5MB allowed!") {
            // Swal.fire({
            //   title: "Warning!",
            //   text: "Max file size 5MB allowed!",
            //   icon: "warning",
            //   confirmButtonText: "OK",
            // });
          } else if (response.data.name === "TokenExpiredError") {
            localStorage.removeItem("fblst_519883613093909");
            localStorage.removeItem("fbssls_314930319788683");

            if (loginDataRedux.graphDomain === "facebook") {
              window.FB.api("/me/permissions", "delete", null, () =>
                window.FB.logout()
              );
              localStorage.setItem("fbLogin", false);
              // localStorage.removeItem("bgImage");
            }

            localStorage.removeItem("user_role");
            dispatch(loginActions.myLoginState(false));
            localStorage.setItem("manualLogin", false);
            localStorage.removeItem("manualLoginInfo");
            // localStorage.removeItem("bgImage");
            history("/");
          }

          setExtra(!extra);
        }, 300);
      })
      .catch((err) => {
        setPostStatus("error");
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/generalSetting" +
          "/" +
          loginDataRedux.userID
      )
      .then((response) => {
        dispatch(loginActions.myUpdatedName(response.data.name));
        if (response.data.name && response.data.allData.length === 0) {
          reset({ name: response.data.name });
        }
        if (response.data.allData.length !== 0) {
          setListID(response.data.allData[0]._id);
          reset({
            industry: response.data.allData[0].industry,
            career: response.data.allData[0].career,
            name: response.data.name,
            age: response.data.allData[0].age,
            gender: response.data.allData[0].gender,
          });

          let loginData = localStorage.getItem("manualLoginInfo");
          let dd = JSON.parse(loginData);
          let gg = {
            ...dd,
            name: response.data.name,
          };
          localStorage.setItem("manualLoginInfo", JSON.stringify(gg));
        }
        if (extraClick) {
          let aaa1 = localStorage.getItem("bgImage");
          let num1 = aaa1.indexOf("?st=");
          let text1 = aaa1.substring(0, num1);

          let aaa2 = response.data.allData[0].visionBoardImage;
          let num2 = aaa2.indexOf("?st=");
          let text2 = aaa2.substring(0, num2);

          if (text1 !== text2) {
            localStorage.setItem(
              "bgImage",
              response.data.allData[0].visionBoardImage
            );
            dispatch(
              colorThemeActions.mySelectedImage(
                response.data.allData[0].visionBoardImage
              )
            );
          }
          if (response.data.allData[0].visionBoardImage !== "undefined") {
            localStorage.setItem(
              "bgImage",
              response.data.allData[0].visionBoardImage
            );
            dispatch(
              colorThemeActions.mySelectedImage(
                response.data.allData[0].visionBoardImage
              )
            );
          }
        }

        if (axiosMsg === "_successfully updated") {
          // Swal.fire({
          //   title: "Success!",
          //   text: "Your account data is updated successfully",
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
        } else if (axiosMsg === "_successfully added") {
          // Swal.fire({
          //   title: "Success!",
          //   text: "Your account data is added successfully",
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
        } else {
          // Swal.close();
        }
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [
    axiosClient,
    axiosMsg,
    dispatch,
    extra,
    extraClick,
    loginDataRedux.userID,
    reset,
  ]);

  const [activeTab, setActiveTab] = useState(0);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      // console.log("User Swiped!", eventData);
      if (eventData.dir === "Left") {
        setActiveTab(1);
      } else if (eventData.dir === "Right") {
        setActiveTab(0);
      }
    },
  });

  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  return (
    <SettingsContainer className="h-100 position-relative" {...handlers}>
      <Row>
        <Col xs={12} className="text-center d-none d-md-block">
          <H5.Light className="my-3 my-md-0 mb-md-2">Settings</H5.Light>
        </Col>
        <Col xs={12} className="text-center d-block d-md-none">
          <Tabs
            tabs={["Personal Info", "Vision Board"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Col>
        {(activeTab === 0 || !isMobile) && (
          <TabContainer md={6} xs={12}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(updateUserData)}>
                <Body.LG.Regular className="text-start d-none d-md-block">
                  Personal Info
                </Body.LG.Regular>
                <Row>
                  <Col md={12}>
                    Name
                    <FormInput
                      className="mt-2"
                      type="text"
                      {...register("name", { required: "Name is required" })}
                      placeholder="Enter your Name"
                      error={errors.name}
                    />
                  </Col>
                  <Col md={12}>
                    Industry
                    <FormInput
                      className="mt-2"
                      type="text"
                      {...register("industry")}
                      placeholder="Your Industry"
                    />
                  </Col>
                  <Col md={12}>
                    Career
                    <FormInput
                      className="mt-2"
                      type="text"
                      {...register("career")}
                      placeholder="Your Career"
                    />
                  </Col>
                  {/* <Col md={6} xs={6}>
                  Gender
                  <DropdownSelect
                    className="mt-2"
                    placeholder="Your Gender"
                    formFieldName="gender"
                    options={[
                      {
                        label: "Male",
                        value: "Male",
                      },
                      {
                        label: "Female",
                        value: "Female",
                      },
                      {
                        label: "Non-binary",
                        value: "Non-binary",
                      },
                      {
                        label: "Gender non specified",
                        value: "Gender non specified",
                      },
                    ]}
                  />
                </Col>
                <Col md={6} xs={6}>
                  Age
                  <FormInput
                    className="mt-2"
                    type="text"
                    {...register("age")}
                    placeholder="Your Age"
                  />
                </Col> */}
                  <Col md={12} className="mb-3 mt-2">
                    {postStatus === "error" && (
                      <ErrorBox maxWidth="100%" justifyContent="flex-start">
                        Oops! Something went wrong while saving. Please try
                        again.
                      </ErrorBox>
                    )}

                    {postStatus === "succeeded" && (
                      <SucceededBox>Your changes have been saved.</SucceededBox>
                    )}
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <ButtonWithIcons type="submit" loading={loading}>
                    Save
                  </ButtonWithIcons>
                </div>
              </form>
            </FormProvider>
          </TabContainer>
        )}

        {(activeTab === 1 || !isMobile) && (
          <TabContainer md={6} xs={12}>
            <Body.LG.Regular className="d-none d-md-block">
              Vision Board
            </Body.LG.Regular>
            <ImageGallery
              ref={register}
              aria-invalid={errors.file ? "true" : "false"}
              {...register(
                "                                                                                                                                                                                                                                  "
              )}
              onChange={imageChangeHandler}
              value={changeImage}
            />
            {/* {changeImage !== "" ? (
            <img
              src={changeImage}
              alt="uploaded_image1"
              className="showImage"
            />
          ) : ["", "undefined"].includes(String(selectedImage)) ? (
            <img src={bgIMg} alt="uploaded_image2" className="showImage" />
          ) : (
            <img
              src={selectedImage}
              alt="uploaded_image3"
              className="showImage"
            />
          )} */}
          </TabContainer>
        )}

        {/*
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div id="progressbarContainer">
            <h6>uploading vision board...</h6>
            <ProgressBar
              style={{ color: "#25e3ce" }}
              now={percentage}
              label={`${percentage}%`}
            />
          </div>
        </Modal> */}
        {/* <Col lg={12}>
          <hr></hr>
          <Body.LG.Regular>Past Logs</Body.LG.Regular>
          <div className="d-flex w-100 justify-content-center">
            <ButtonWithIcons rightIcon={<i className="bi bi-download" />}>
              Export all logged journals
            </ButtonWithIcons>
          </div>
        </Col> */}
      </Row>

      <div className="d-flex justify-content-center w-100 flex-column d-md-none m-3">
        <ModalWrapper
            open={openLogoutModal}
            handleClose={() => setOpenLogoutModal(false)}
            hideClose
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <LogoutModal setOpenModal={setOpenLogoutModal} />
        </ModalWrapper>
        <Body.MD.Light className="m-0 p-0">
          {loginDataRedux.email}
        </Body.MD.Light>
        <ButtonWithIcons
          leftIcon={<Icon className="bi bi-box-arrow-left me-2" size={"lg"} />}
          variant="underlined"
          onClick={() => setOpenLogoutModal(true)}
        >{" "}
          Logout
        </ButtonWithIcons>
      </div>
      <div className="d-flex justify-content-center w-100 flex-column">
        <Body.SM.Light>
          To contact our team about bugs, data privacy, account deletion or
          feature requests, please email us at{" "}
          <StyledA href="mailto:support@growfrom.com?subject=GrowFrom Support Question">
            <strong>support@growfrom.com</strong>
          </StyledA>
          .
        </Body.SM.Light>
        <ButtonWithIcons
          rightIcon={<i className="bi bi-trash" />}
          variant="underlined"
          onClick={() => setOpen(true)}
        >
          Delete Account
        </ButtonWithIcons>
      </div>

      <ModalWrapper
        open={open}
        handleClose={() => setOpen(false)}
        hideClose
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <DeleteModal setOpenModal={setOpen} />
      </ModalWrapper>
    </SettingsContainer>
  );
};

export default GeneralSettingMain;
