import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useContext,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import { styled } from "styled-components";
import { Body, H5 } from "../Commons/Text";
import AddInput from "../Commons/AddInput";
import { CoachMarkContext } from "../../Contexts/coachmark";
import { Coachmarks } from "../Coachmarks";
import { StyledCoachmark } from "../Dashboard/styles";
import { HoverHabitsCss } from "./styles";
import HabitItem from "./HabitItem";
import { useCoachmarkRefresh } from "../../OurCustomHooks/useCoachmarkRefresh";
import { StyledContactContainer, StyledEmpty } from "./ContactRadarLocal";
import { DailyItemContext } from "../../Contexts/DailyItemContext";

const HabitsContainer = styled.div`
  height: 380px;
  border-radius: 12px;
  border: 1px solid var(--widgets-border-hover-habits, #18425b);
  padding: var(--sizes-16, 16px);

  background: var(
    --gradient-blue-widgets,
    linear-gradient(
      180deg,
      rgba(0, 101, 219, 0.1) 0.17%,
      rgba(0, 101, 219, 0.01) 99.83%
    )
  );
  
  ${HoverHabitsCss}
`;

const Habits = forwardRef((_, ref7) => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { currentDate } = useContext(DailyItemContext);

  const [habits, setHabits] = useState([]);
  // const [rerender, setRerender] = useState(0);

  const axiosClient = useAxios();
  const { coachmarkProps } = useContext(CoachMarkContext);

  const { control, reset, handleSubmit } = useForm();

  const getHabits = useCallback(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
        "/habits/" +
        loginDataRedux.userID
      )
      .then((response) => {
        setHabits(response.data);
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, loginDataRedux.userID]);

  const addNewHabit = useCallback(
    (data) => {
      axiosClient({
        method: "post",
        url: process.env.REACT_APP_API_BASE_URL + "/habits",
        data: {
          habit_description: data.habit_description,
          userId: loginDataRedux.userID,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            getHabits();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      reset();
    },
    [axiosClient, getHabits, loginDataRedux.userID, reset]
  );

  useEffect(() => {
    getHabits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const rerender = useCoachmarkRefresh('habits', ref7)

  return (
    <>
      <HabitsContainer className="mt-md-2 parent-hovered" ref={ref7}>
        {coachmarkProps.habits.visible && rerender > 0 && (
          <StyledCoachmark
            activate={coachmarkProps.habits.visible}
            component={<Coachmarks
              currentStep={7}
              handleNext={() => {
                coachmarkProps.habits?.handleNext?.()
              }}
              handlePrev={() => {
                coachmarkProps.habits.handlePrev()
              }}
            />}
            reference={ref7}
            tooltip={coachmarkProps.habits.tooltip}
          />)
        }
        <div className="d-flex justify-content-center justify-content-md-start overflow-hidden position-relative">
          <Body.SM.Regular className="d-block d-md-none" 
          style={{
            position: "absolute",
            left: -55 , 
            opacity: '0.5'
          }}>Sticky Reminder</Body.SM.Regular>
          <H5.Regular>Habits</H5.Regular>
          <Body.SM.Regular className="d-block d-md-none" 
          style={{
            position: "absolute",
            right: -10, 
            opacity: '0.5'
          }}>Journal</Body.SM.Regular>
        </div>
        <form onSubmit={handleSubmit(addNewHabit)}>
          <Controller
            control={control}
            name="habit_description"
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <AddInput
                placeholder="Add a habit..."
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                $placeholdercenter
              />
            )}
          />
        </form>

        <StyledContactContainer>
          {habits.length > 0 ? habits
            .filter((data) => data.habit_description?.length > 0)
            .map((data, index) => {
              const isChecked = data.checkedDates?.find(
                (checkedDate) =>
                  new Date(checkedDate).toDateString() ===
                  new Date(currentDate).toDateString()
              );
              return { ...data, isChecked: (!!isChecked) };
            })
            .filter((data) => !data.isDeleted || (data.isDeleted !== undefined && data.isDeleted && data.isChecked))
            .sort((x, y) => {
              return x.isChecked === y.isChecked
                ? 0
                : x.isChecked
                  ? 1
                  : -1;
            })
            .map((data, index) => <HabitItem
              habit={data}
              key={index}
              index={index}
              refresh={() => {
                getHabits();
              }}
              date={currentDate}
            />
            ) : <StyledEmpty>
            <Body.MD.Light>It looks like you haven’t added any habits.</Body.MD.Light>
          </StyledEmpty>}
        </StyledContactContainer>
      </HabitsContainer>
    </>
  );
});

export default Habits;
