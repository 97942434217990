import React, { useContext, useMemo } from "react";
import { H5 } from "../Commons/Text";
import { ButtonWithIcons } from "../Commons/Buttons";
import CalendarInput from "../Commons/CalendarInput";
import { DailyItemContext } from "../../Contexts/DailyItemContext";
import useTime from "../../utils/date";
import moment from "moment";
import styled from "styled-components";
import { theme } from "../../Theme";

const ThirdContainer = styled.div`
  justify-content: center;
  width: 33%;
  position: relative;

  @media screen and (max-width: 992px) {
    display: none !important;
    color: red;
  }
`;


const StyledDateButtonsContainers = styled(ThirdContainer)`
  justify-content: start;

  @media screen and (max-width: 991px) {
    display: flex !important;
    width: 100% !important;
    justify-content: center;
  }
`;

const StyledDateHeader = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  background-color: ${theme.colors.primary.transparent[500]};
  padding: 16px;
`;

const DateHeader = () => {
  const { currentDate, setCurrentDate, dailyItems } =
    useContext(DailyItemContext);
  const { formatDate } = useTime();
  const todayDate = moment();
  const tomorrowDate = moment().add(1, "day");

  const { isToday, isTomorrow } = useMemo(() => {
    return {
      isToday: formatDate(currentDate) === formatDate(todayDate),
      isTomorrow: formatDate(currentDate) === formatDate(tomorrowDate),
    };
  }, [currentDate, formatDate, todayDate, tomorrowDate]);

  return (
    <StyledDateHeader className="d-block d-lg-flex justify-content-md-between align-items-center mb-sm-2">
      <div className="d-block d-lg-none justify-content-center align-items-center text-center mb-3">
        <H5.Light className="m-0">{formatDate(currentDate)}</H5.Light>
      </div>
      <StyledDateButtonsContainers className="d-flex">
        <ButtonWithIcons
          $minwidth="140px"
          className={`me-2`}
          selected={isToday}
          onClick={() => {
            if (!isToday) {
              setCurrentDate(todayDate);  
            }
          }}
        >
          Today
        </ButtonWithIcons>
        <ButtonWithIcons
          $minwidth="140px"
          selected={isTomorrow}
          onClick={() => {
            if (!isTomorrow) {
              setCurrentDate(tomorrowDate);
            }
          }}
        >
          Tomorrow
        </ButtonWithIcons>
        <div
          className="d-flex d-lg-none pe-2"
          style={{ zIndex: 1000, position: "relative" }}
        >
          <CalendarInput
            label=" "
            value={undefined}
            onChange={(date) => {
              setCurrentDate(moment(new Date(date)));
            }}
            highlightDates={dailyItems
              .filter((di) => di.todayDate)
              .map((item) => new Date(item.todayDate))
              .filter((item) => item.toString() !== "Invalid Date")}
            $customLabelClass="d-none d-md-block"
          />
        </div>
      </StyledDateButtonsContainers>
      <ThirdContainer  className="d-flex">
        <H5.Light className="m-0">{formatDate(currentDate)}</H5.Light>
      </ThirdContainer>
      <ThirdContainer 
        className="d-flex justify-content-end"
        style={{ zIndex: 1000 }}
      >
        <CalendarInput  
          label="Pick a Day"
          value={undefined}
          onChange={(date) => {
            setCurrentDate(moment(new Date(date)));
          }}
          highlightDates={dailyItems
            .filter((di) => di.todayDate)
            .map((item) => new Date(item.todayDate))
            .filter((item) => item.toString() !== "Invalid Date")}
        />
      </ThirdContainer>
    </StyledDateHeader>
  );
};

export default DateHeader;
