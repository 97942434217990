import styled from "styled-components";
import { colors } from "../../Theme";

export const StyledA = styled.a`
  color: ${colors.neutral[100]};
`

export const StyledTooltipContainer = styled.div`
  display: inline-flex;
  min-width: 200px;
  min-height: 56px;
  padding: 16px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  border-radius: 8px;
  background: ${colors.primary[700]};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border-top: 10px solid ${colors.primary[700]};
    border-left: 10px solid transparent; 
    border-bottom: 0 solid transparent;
    border-right: 10px solid transparent;

  }
`

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: start;
  color: ${colors.neutral[100]};
  
`

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  width: 100%;
`

export const AppContainer = styled.div`
  height: ${({ $isiphone }) => !$isiphone ? '-webkit-fill-available !important' : '100vh'};
  overflow-y: auto;
`