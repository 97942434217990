import { theme } from '../Theme';
import useMedia from './useMedia';

export default function useBreakpoint() {
  // Hooks
  const [isMobile, isTablet, isDesktop] = useMedia(
    [
      `(max-width: ${theme.breakpoints.tablet - 1}px)`,
      `(min-width: ${theme.breakpoints.tablet}px) and (max-width: ${theme.breakpoints.bigTablet - 1}px)`,
      `(min-width: ${theme.breakpoints.bigTablet}px)`,
    ],
    [[true, false, false], [false, true, false], [false, false, true]],
    [true, false, false],
  );

  const out = {
    isMobile,
    isTablet,
    isDesktop,
  };

  return out;
}
