import { useCallback } from "react"
import { LogoutModalClose, LogoutModalContainer, LogoutModalContent } from "./styles"
import Icon from "../Commons/Icon"
import { Body, H4, H5 } from "../Commons/Text"
import { Image } from "react-bootstrap"

import QR from "../Images/Ads/MobileQR.png";
import Mobile from "../Images/Ads/Mobile.png";

const AdsAppModal = ({ setOpenModal }) => {

  return <div className="d-flex justify-content-center align-items-center h-100">
    <LogoutModalContainer>
      <LogoutModalContent>
        <H5.Regular className="text-center">
          Stay Productive on the Go
        </H5.Regular>

        <Body.MD.Regular>
          Look for GrowFrom in Google Play (GrowFrom Companion) or the App Store (GrowFrom or scan the QR code below)
        </Body.MD.Regular>

        <div className="d-flex justify-content-between" style={{gap: 20}}>
          <Image src={QR} alt="qr-code" width={180}  height={180} />
          <Image src={Mobile} alt="mobile"  width={148}  height={221} />
        </div>
      </LogoutModalContent>
    </LogoutModalContainer>
  </div>
}

export default AdsAppModal