import { css } from "styled-components";
import { theme } from "../../Theme";

export const HoverPlannerCss = css`
  &.parent-hovered:hover, &.parent-hovered:focus-within {
    border-radius: 12px;
    border: 1px solid var(--widgets-border-hover-today, #2ADFA4);
    background: var(--gradient-green-widgets, linear-gradient(180deg, rgba(32, 180, 131, 0.25) 0.17%, rgba(32, 180, 131, 0.03) 99.83%));

    /* Shadow-hover-green */
    box-shadow: 0px 0px 20px -7px #2ADFA4;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    ${theme.mediaQueries.maxMobile} {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
`
export const HoverUpcommingCss = css`
  &.parent-hovered:hover, &.parent-hovered:focus-within {
    border-radius: 12px;
    border: 2px solid var(--Accent-200---base, #FCC012);
    background: linear-gradient(180deg, rgba(252, 192, 18, 0.20) 0.17%, rgba(252, 192, 18, 0.00) 99.83%);

    /* Shadow-hover-yellow */
    box-shadow: 0px 0px 20px -7px rgba(252, 192, 18, 0.50);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);


    ${theme.mediaQueries.maxMobile} {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
`
export const HoverHabitsCss = css`
  &.parent-hovered:hover, &.parent-hovered:focus-within {
    border-radius: 12px;
    border: 1px solid var(--widgets-border-hover-Habits, #18425B);
    background: var(--gradient-blue-widgets, linear-gradient(180deg, rgba(0, 101, 219, 0.10) 0.17%, rgba(0, 101, 219, 0.01) 99.83%));

    /* Shadow-hover-blue */
    box-shadow: 0px 0px 20px -7px #0065DB;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    ${theme.mediaQueries.maxMobile} {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
`
export const HoverStickyCss = css`
  &.parent-hovered:hover, &.parent-hovered:focus-within {
    border-radius: 12px;
    border: 1px solid var(--widgets-border-hover-StickyReminders, #39301E);
    background: var(--gradient-red-widgets, linear-gradient(180deg, rgba(170, 72, 17, 0.10) 0.17%, rgba(170, 72, 17, 0.01) 99.83%));

    /* Shadow-hover-red */
    box-shadow: 0px 0px 20px -7px #AA4811;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    ${theme.mediaQueries.maxMobile} {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
`