import React, { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import useAxios from "../../OurCustomHooks/useAxios";
import { theme } from "../../Theme";
import BaseInput from "../Commons/BaseInput";
import { IconButton } from "../Commons/Buttons";
import CalendarInput from "../Commons/CalendarInput";
import Icon from "../Commons/Icon";
import { Body, H5 } from "../Commons/Text";
import GoalBox from "./GoalBox";
import { Col, Container, Row } from "react-bootstrap";
import Tabs from "../Commons/Tabs";
import { useSwipeable } from "react-swipeable";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";

const AddGoalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 12px 12px 0px 0px;
  border-top: 1px solid
    var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  border-right: 1px solid
    var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  border-left: 1px solid
    var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  background: var(--primary-transparent-500, rgba(13, 99, 71, 0.5));
`;

const EmptyGoalsList = styled.div`
  display: flex;
  height: 600px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
`;

const TabContainer = styled.div`
  transition: all 0.5s ease-in-out;
  padding-bottom: 10px;

  ${theme.mediaQueries.maxMobile} {
    div:first-child, h5 + div:first-child {
      border-radius: 12px 12px 0 0 !important;
    }

    div:last-child {
      border-radius: 0px 0px 12px 12px !important;
    }
  }
`;

const GoalContainer = styled(Container)`
  ${theme.mediaQueries.maxMobile} {
    height: calc(100vh - 71px);
  }
`;

const GoalsMain = () => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { isMobile } = useBreakpoint();

  const [goalList, setGoalList] = useState([]);
  const [milestoneList, setMilestoneList] = useState([]);

  const axiosClient = useAxios();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      goal: "",
      targetDate: undefined,
    },
  });

  const goal = useWatch({ control, name: "goal" });
  const targetDate = useWatch({ control, name: "targetDate" });

  const getGoals = React.useCallback(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/longtermgoal/" +
          loginDataRedux.userID
      )
      .then((response) => {
        // TODO Filter By Date
        // setGoalList(
        //   response.data
        //   .filter(
        //     (items) => formatDate(items.todayDate) === formatDate(date)
        //   )
        // );
        setGoalList(response.data);
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, loginDataRedux.userID]);

  const getMilestones = React.useCallback(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/shorttermgoal/" +
          loginDataRedux.userID
      )
      .then((response) => {
        setMilestoneList(response.data);
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, loginDataRedux.userID]);

  const addGoal = React.useCallback(() => {
    if (goal) {
      axiosClient({
        method: "post",
        url: process.env.REACT_APP_API_BASE_URL + "/longtermgoal",
        data: {
          longTermGoal: goal,
          date: targetDate || new Date(),
          userId: loginDataRedux.userID,
          status: "pending",
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            reset();
            getGoals();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [axiosClient, getGoals, goal, loginDataRedux.userID, reset, targetDate]);

  React.useEffect(() => {
    getGoals();
    getMilestones();
  }, [getGoals, getMilestones]);

  const refresh = {
    goals: getGoals,
    milestones: getMilestones,
  };

  const [activeTab, setActiveTab] = useState(0);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      // console.log("User Swiped!", eventData);
      if (eventData.dir === "Left") {
        setActiveTab(1);
      } else if (eventData.dir === "Right") {
        setActiveTab(0);
      }
    },
  });

  return (
    <GoalContainer {...handlers}>
      <Row>
        <Col xs={12} className="text-center d-none d-md-block">
          <H5.Light className="my-3 my-md-0 mb-md-2">Goals</H5.Light>
        </Col>
        <Col xs={12} className="text-center d-block d-md-none">
          <Tabs
            tabs={["Pending Goals", "Completed Goals"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Col>
        <Col xs={12}>
          {(activeTab === 0 || !isMobile) && (
            <TabContainer>
              <form onSubmit={handleSubmit(addGoal)}>
                <AddGoalContainer className="w-100 d-block d-md-flex p-3 p-md-4">
                  <div className="d-none d-md-block" style={{ width: 140 }}>
                    <H5.Light className="m-0">Add a goal</H5.Light>
                  </div>
                  <div className="d-block d-md-flex align-items-center w-100">
                    <Controller
                      control={control}
                      name="goal"
                      render={({ field: { value, onChange, onBlur, ref } }) => (
                        <BaseInput
                          placeholder="Add..."
                          ref={ref}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <Controller
                        control={control}
                        name="targetDate"
                        render={({
                          field: { value, onChange, onBlur, ref },
                        }) => (
                          <CalendarInput
                            className="justify-content-center"
                            label="Target Date"
                            ref={ref}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            highlightDates={goalList.map(
                              (goal) => new Date(goal.date)
                            )}
                            style={{
                              minWidth: 130,
                            }}
                          />
                        )}
                      />
                      <div className="d-flex">
                        <IconButton
                          type="button"
                          disabled={goal.length === 0}
                          onClick={() => {
                            reset();
                          }}
                          variant="cancel"
                        >
                          <Icon className="bi bi-x-lg" size="sm" />
                        </IconButton>
                        <IconButton
                          type="submit"
                          disabled={goal.length === 0}
                          className="p-0"
                        >
                          <Icon className="bi bi-check-lg" size="sm" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </AddGoalContainer>
              </form>

              {goalList.length === 0 && (
                <EmptyGoalsList className="mt-3">
                  <Icon
                    className="bi bi-flag"
                    size={"xl"}
                    style={{ color: theme.colors.primary[300] }}
                  ></Icon>
                  <Body.MD.Regular>
                    Add your goals and let the journey to success unfold!{" "}
                  </Body.MD.Regular>
                </EmptyGoalsList>
              )}

              {goalList
                .filter((goal) => goal.status === "pending")
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((goal, index) => {
                  return (
                    <GoalBox
                      goal={goal}
                      goals={goalList}
                      milestones={milestoneList.filter(
                        (milestone) => milestone.long_term_goal_id === goal._id
                      )}
                      key={goal._id}
                      refresh={refresh}
                    />
                  );
                })}
            </TabContainer>
          )}

          {(activeTab === 1 || !isMobile) && (
            <TabContainer>
              {goalList.filter((goal) => goal.status === "completed").length >
                0 && <H5.Light className="mt-2">Completed Goals</H5.Light>}
              {goalList
                .filter((goal) => goal.status === "completed")
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((goal) => {
                  return (
                    <GoalBox
                      goal={goal}
                      milestones={milestoneList.filter(
                        (milestone) => milestone.long_term_goal_id === goal._id
                      )}
                      key={goal._id}
                      refresh={refresh}
                    />
                  );
                })}
            </TabContainer>
          )}
        </Col>
      </Row>
    </GoalContainer>
  );
};

export default GoalsMain;
