import React, { useCallback, useState } from "react";
import Checkbox from "../Commons/Checkbox";
import Icon from "../Commons/Icon";
import { theme } from "../../Theme";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import EditableInlineComponent from "../Commons/EditableInput";
import { Body } from "../Commons/Text";
import CalendarInput from "../Commons/CalendarInput";

const MilestoneItem = ({ milestones, milestone, index, refresh }) => {
  const [editMode, setEditMode] = useState(false);
  const [milestoneDetail, setMilestoneDetail] = useState(
    milestone.short_term_goal
  );
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const axiosClient = useAxios();

  const updateMilestone = useCallback(
    (id, params) => {
      axiosClient({
        method: "put",
        url: process.env.REACT_APP_API_BASE_URL + "/shorttermgoal",
        data: {
          _id: id,
          userId: loginDataRedux.userID,
          ...params,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh.milestones?.();
          }
        })
        .catch((err) => {
          console.log("Error is ", err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh] // Add an empty array as the second argument to useCallback
  );

  const deleteMilestone = useCallback(
    (id) => {
      axiosClient({
        method: "delete",
        url: process.env.REACT_APP_API_BASE_URL + "/shorttermgoal",
        data: {
          list_id: id,
          userId: loginDataRedux.userID,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh.milestones?.();
          }
        })
        .catch((err) => {
          console.log("Error is ", err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  return (
    <div
      className="d-block d-md-flex w-100 justify-content-between align-items-center pe-2"
      key={milestone._id}
    >
      <div className="d-flex justify-content-between align-items-center">
        <EditableInlineComponent
          name={`milestone[${index}].shortTermGoal`}
          value={milestoneDetail}
          editMode={editMode}
          onChange={(e) => {
            setMilestoneDetail(e.target.value);
          }}
          onConfirm={() => {
            updateMilestone(milestone._id, {
              short_term_goal: milestoneDetail,
            });
            setEditMode(false);
          }}
          onCancel={() => {
            setEditMode(false);
            setMilestoneDetail(milestone.short_term_goal);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && milestoneDetail.length > 0) {
              updateMilestone(milestone._id, {
                short_term_goal: milestoneDetail,
              });
              setEditMode(false);
            }
          }}
          displayElement={
            <div className="d-flex align-item-center ">
              <Checkbox
                checked={milestone.status === "completed"}
                onChange={() => {
                  updateMilestone(milestone._id, {
                    status:
                      milestone.status === "completed"
                        ? "pending"
                        : "completed",
                  });
                }}
              />
              <Body.MD.Regular
                className="m-0"
                onClick={() => {
                  setEditMode(true);
                }}
                style={{
                  color:
                    milestone.status === "pending"
                      ? theme.colors.neutral[100]
                      : theme.colors.neutral[400],
                  textDecoration:
                    milestone.status === "completed" ? "line-through" : "none",
                }}
              >
                {milestone.short_term_goal}
              </Body.MD.Regular>
            </div>
          }
        />
        <Icon
          className="bi bi-trash d-block d-md-none ms-2"
          style={{
            color: theme.colors.semantic.negative.transparent.normal,
            cursor: "pointer",
          }}
          size="md"
          onClick={() => {
            deleteMilestone(milestone._id);
          }}
        />
      </div>
      <div className="d-flex align-items-center ps-4 ps-md-0">
        <CalendarInput
          label="Target Date"
          value={milestone.date ? new Date(milestone.date) : undefined}
          onChange={(date) => {
            updateMilestone(milestone._id, {
              date: date,
            });
          }}
          highlightDates={milestones
            .filter((ml) => ml.date)
            .map((milestone) => new Date(milestone.date))
            .filter((milestone) => milestone.toString() !== "Invalid Date")
          }
        />
        <Icon
          className="bi bi-trash d-none d-md-block ms-2"
          style={{
            color: theme.colors.semantic.negative.transparent.normal,
            cursor: "pointer",
          }}
          size="md"
          onClick={() => {
            deleteMilestone(milestone._id);
          }}
        />
      </div>
    </div>
  );
};

export default MilestoneItem;
