import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/LoginPageState";
import { Link, useNavigate } from "react-router-dom";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import FormInput, { ErrorBox } from "../Commons/FormInput";
import { Body, H1, H3, H4, H5 } from "../Commons/Text";
import { Dots } from "../Commons/Dots";
import { NextArrow, PrevArrow } from "../Commons/Buttons";
import useAxios from "../../OurCustomHooks/useAxios";
import { Logo } from "../Commons/Brand";
import PasswordInput from "../Commons/PasswordInput";
import Checkbox from "../Commons/Checkbox";
import { StyledA } from "./styles";
import { customToast } from "../../utils/toasts";
import { OverlayTrigger } from "react-bootstrap";

import { PasswordTooltip } from "./PasswordTooltip";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";
import { ModalWrapper } from "../Commons/ModalWrapper";
import TermsConditions from "./TermsConditions";


const ENABLE_APPLICATION_INSIGHTS = window.location.hostname !== "localhost";

const RegistrationForm = () => {
  const axiosClient = useAxios();
  const { isMobile } = useBreakpoint();

  // This is for tracking purpose
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "RegistrationForm");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [submitError, setSubmitError] = useState();
  const [agreeTerms, setAgreeTerms] = useState();
  const [pass, setPass] = useState();

  const {
    register,
    trigger,
    resetField,
    formState: { isValid, errors },
    getValues,
    setValue,
    setFocus
  } = useForm();

  const registrationFormData = React.useCallback(() => {
    const data = getValues();
    axiosClient({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "/manualReg",
      data: {
        name: data.Name,
        email: data.Email.toLowerCase(),
        password: data.Password,
        userId: data.Email.toLowerCase(),
      },
    })
      .then((response) => {
        if (response.data.msg === "_success") {
          customToast('Account created successfully', 'success')
          axiosClient({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/manualLogin",
            data: {
              email: data.Email,
              password: data.Password,
            },
          })
            .then((response) => {
              if (response.data.msg === "_successfully_login") {
                localStorage.setItem("manualLogin", true);
                dispatch(loginActions.myLoginState(true));
                localStorage.setItem("api_token", response.data.token);
                localStorage.setItem(
                  "manualLoginInfo",
                  JSON.stringify(response.data.res)
                );
                dispatch(loginActions.myLoginInfo(response.data.res));
                if (isMobile) {
                  navigate("/onboardingMobile");
                } else {
                  navigate("/onboarding");
                }
              }
            })
            .catch((err) => {
              console.log("Error is ", err);
            });
        } else if (response.data.msg === "user exists!") {
          // TODO What we do in this case?
          // Swal.fire({
          //   title: "Warning!",
          //   text: "This user is already Exist",
          //   icon: "warning",
          //   confirmButtonText: "OK",
          // });
          setSubmitError({
            error: response.data,
            message: "This user is already Exist",
          });
          setStep(1);
          resetField("Email");
          resetField("Password");
          resetField("confirmPassword");
        }
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, dispatch, getValues, isMobile, navigate, resetField]);

  const handleVerification = useCallback(() => {
    trigger();
    if (isValid) {
      if (step === 1) {
        setSubmitError();
      }
      if (step === 2) {
        if (!agreeTerms) {
          setAgreeTerms(false)
          return
        }
        registrationFormData();
      }
      setStep((s) => ++s);
    } else {
      console.log(errors)
    }
  }, [trigger, isValid, step, agreeTerms, registrationFormData, errors]);

  const setPassFn = useCallback((e) => {
    setValue("Password", e.target.value)
    setPass(e.target.value)
  }, [setValue])

  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    switch (step) {
      case 0 : { setFocus("Name"); break; }
      case 1 : { setFocus("Email"); break; }
      case 2 : { setFocus("Password"); break; }
      default : { break; }
    }
  }, [setFocus, step])

  return (
    <div
      onMouseOver={ENABLE_APPLICATION_INSIGHTS ? trackComponent : () => { }}
      onClick={ENABLE_APPLICATION_INSIGHTS ? trackComponent : () => { }}

    >
    <ModalWrapper
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
    >
      <TermsConditions 
        onConfirm={()=>{
          setAgreeTerms(true);
          setOpen(false);
        }}
        onClose={()=>{
          setAgreeTerms(false);
          setOpen(false);
        }}
      />
    </ModalWrapper>
      <Logo alt="GrowFrom" style={{ width: 90 }} className="mb-5" />
      {step === 0 &&
        (isMobile
          ? <>
            <H5.Light>Welcome to Your Home for Personal Growth!</H5.Light>
            <H3.Regular>What's your name?</H3.Regular>
          </>
          : <>
            <H4.Regular>Welcome to Your Home for Personal Growth!</H4.Regular>
            <H1.Regular>What's your name?</H1.Regular>
          </>
        )
      }
      {step === 1 && (isMobile
        ? <>
          <H5.Light>
            Good afternoon,
            {` ${getValues("Name")}`}
          </H5.Light>
          <H3.Regular>Please enter your email to get started</H3.Regular>
        </>
        :
        <>
          <H4.Regular>
            Good afternoon,
            {` ${getValues("Name")}`}
          </H4.Regular>
          <H1.Regular>Please enter your email to get started</H1.Regular>
        </>
      )}

      {step >= 2 && (isMobile
        ? <>
          <H5.Light>
            Good afternoon,
            {` ${getValues("Name")}`}
          </H5.Light>
          <H3.Regular>Create a password to secure your goals!</H3.Regular>
        </>
        : <>
          <H4.Regular>
            Good afternoon,
            {` ${getValues("Name")}`}
          </H4.Regular>
          <H1.Regular>Create a password to secure your goals!</H1.Regular>
        </>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{
          marginTop: step === 2 ? 40 : 100,
        }}
      >
        {step === 0 && (
          <div className="d-flex justify-content-center w-100">
            <FormInput
              ref={register}
              type="text"
              placeholder="Your Name"
              {...register("Name", {
                required: "Please Enter your Name", pattern: {
                  value: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{3,}$/,
                  message: 'Please enter a valid name'
                }
              })}
              error={errors.Name}
              style={{
                maxWidth: 550,
              }}
              onKeyDown={
                (e) => {
                  if (e.key === 'Enter') {
                    handleVerification()
                  }
                }
              }
            />
          </div>
        )}

        {step === 1 && (
          <>
            <div className="d-flex justify-content-center w-100">
              <FormInput
                ref={register}
                type="text"
                placeholder="Enter Email"
                {...register("Email", {
                  required: "Please enter a valid email address",
                  maxLength: {
                    value: 256,
                    message: "Please enter 256 character maximum",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email address",
                  },
                })}
                error={errors.Email}
                style={{
                  maxWidth: 550,
                }}
                onKeyDown={
                  (e) => {
                    if (e.key === 'Enter') {
                      handleVerification()
                    }
                  }
                }
              />
            </div>
            {submitError && <ErrorBox>{submitError.message}</ErrorBox>}
          </>
        )}

        {step === 2 && (
          <>
            <div className="d-flex justify-content-center w-100 position-relative">
              <OverlayTrigger
                placement="top"
                overlay={<div><PasswordTooltip pass={pass} /></div>}
                flip
                trigger={['focus', 'hover', 'click']}>
                <PasswordInput ref={register}
                  type="password"
                  placeholder="Your Password"
                  {...register("Password", {
                    required: "Please enter your Password",
                    minLength: {
                      value: 8,
                      message: "Please enter 8 character minimum",
                    },
                    validate: {
                      hasUpper: (value) => /[A-Z]/.test(value) || 'No Has Upper',
                      hasLower: (value) => /[a-z]/.test(value) || 'No Has Lower',
                      digits: (value) => (value && /\d/.test(value)) || 'No Has Digit'
                    },
                  })}
                  error={errors.Password}
                  hideError
                  style={{
                    maxWidth: 550,
                  }}
                  onChange={(e) => {
                    setPassFn(e)
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className="d-flex justify-content-center w-100">
              <PasswordInput
                style={{
                  maxWidth: 550,
                }}
                ref={register}
                type="password"
                placeholder="Confirm your password"
                {...register("confirmPassword", {
                  required: "Please confirm your Password",
                  validate: (value, formValues) => {
                    if (value === formValues.Password) {
                      return true
                    }
                    return "Passwords do not match. Please ensure they are identical and try again."
                  },
                })}
                onKeyDown={
                  (e) => {
                    if (e.key === 'Enter') {
                      handleVerification()
                    }
                  }
                }
                error={
                  errors.confirmPassword &&
                    errors.confirmPassword.type === "required"
                    ? errors.confirmPassword
                    : undefined
                }
              />
            </div>
            <div className="d-flex justify-content-center my-4">
              <Checkbox onChange={(e) => setAgreeTerms(e.target.checked)} checked={agreeTerms}/>
              <Body.MD.Light className="mb-0 text-left">
                By creating an account, you agree to our <StyledA onClick={() => setOpen(true)}>Terms and Conditions.</StyledA>
              </Body.MD.Light>
            </div>
            {(errors.confirmPassword || errors.Password || agreeTerms === false)
              && (
                <ErrorBox className='my-4' style={{ maxWidth: 'max-content' }}>
                  {errors.Password?.message || errors.confirmPassword?.message
                    || (agreeTerms === false && 'Please check our Terms and Conditions')}
                </ErrorBox>
              )}
          </>
        )}

        {step === 3 && (
          <>
            <div className="spinner-border mb-2" role="status" />
            <Body.LG.Regular>Your account is being created</Body.LG.Regular>
          </>
        )}

        {step !== 3 && (
          <div className="mt-4 d-flex justify-content-center w-100">
            <div
              className="w-100 justify-content-between align-items-center d-flex"
              style={{ maxWidth: 550 }}
            >
              <div style={{ minWidth: 48 }}>
                {step !== 0 &&
                  <PrevArrow
                    fontSize="24px"
                    onClick={() => {
                      setStep((s) => --s);
                    }}

                  />}
              </div>

              <div>
                {Array.from({ length: 3 }, (x, i) => {
                  return step === i ? (
                    <Dots key={`dots_${i}`} />
                  ) : (
                    <Dots fill key={`dots_${i}`} />
                  );
                })}
              </div>
              <NextArrow
                fontSize="24px"
                onClick={() => {
                  handleVerification();
                }}
              />
            </div>
          </div>
        )}
      </form>

      <Body.MD.Regular style={{ marginTop: 48 }}>
        Already have an account? <Link to="/login">Click here to login</Link>
      </Body.MD.Regular>
    </div >
  );
};

export default RegistrationForm;
