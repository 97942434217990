import { memo, useCallback } from "react";
import useAxios from "../../../OurCustomHooks/useAxios";
import { useLogout } from "../../../OurCustomHooks/useLogout";
import { customToast } from "../../../utils/toasts";
import { LogoutModalButtons, LogoutModalClose, LogoutModalContainer, LogoutModalContent } from "../../Dashboard/styles";
import Icon from "../../Commons/Icon";
import { Body, H4 } from "../../Commons/Text";
import { ButtonWithIcons } from "../../Commons/Buttons";
import { useSelector } from "react-redux";


const DeleteModalComponent = ({ setOpenModal }) => {
  const axiosClient = useAxios();
  const logoutFn = useLogout();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const deleteAccount = async () => {
    try {
      axiosClient({
        method: "delete",
        url: process.env.REACT_APP_API_BASE_URL + "/user/",
        data: {
          userID: loginDataRedux.userID,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            customToast('Account deleted successfully', 'success');
            logoutFn();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = useCallback(() => {
    setOpenModal?.(false)
  }, [setOpenModal])

  return <div className="d-flex justify-content-center align-items-center h-100">
    <LogoutModalContainer>
      <LogoutModalClose>
        <div role="button" aria-hidden>
          <Icon className="bi bi-x-circle fs-4" size="lg" onClick={handleClose} />
        </div>
      </LogoutModalClose>

      <LogoutModalContent>
        <H4.Regular className="text-center">
        Are you sure you want to delete your account?
        </H4.Regular>

        <Body.MD.Regular>
          This action is irreversible, and all your data will be permanently removed.
        </Body.MD.Regular>
      </LogoutModalContent>
      <LogoutModalButtons>
        <ButtonWithIcons variant="underlined" onClick={() => setOpenModal(false)}>Cancel</ButtonWithIcons>
        <ButtonWithIcons variant="solid" onClick={() => { deleteAccount() }}>Yes, Delete</ButtonWithIcons>
      </LogoutModalButtons>
    </LogoutModalContainer>
  </div>
}

export const DeleteModal = memo(DeleteModalComponent)