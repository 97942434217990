import { createContext, forwardRef, useCallback, useContext, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { Body } from "./Text"
import Icon from "./Icon"
import { Dropdown } from "react-bootstrap"
import { colors } from "../../Theme"
import { useFormContext } from "react-hook-form"

export const DrodownContext = createContext()

const StyledInputDropdown = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 100px;
  
  border: 1px solid var(--Neutral-transparent-100---white-20, rgba(255, 255, 255, 0.20));
  background: ${colors.neutral.transparent.white5};
  /* bakground blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  cursor: pointer;

  &:hover:not(:disabled) {
    border: 1px solid ${colors.primary[300]};
    /* buttons-on-hover */
    box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  &:focus, &:focus-visible {
    outline: none;
    border: 1px solid ${colors.primary[300]};
    /* bakground blur */
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

  }
`

const CustomInputDropdown = forwardRef(({ children, onClick }, ref) => {
  const { isOpen, setIsOpen } = useContext(DrodownContext)
  return <StyledInputDropdown
    onClick={(e) => {
      onClick(e)
      setIsOpen(o => !o)
    }}
    // tabIndex={0}
    role="button"
  >
    <Body.MD.Light className="mb-0" style={{ width: '90%' }}>{children}</Body.MD.Light>
    <Icon className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`} size="md" style={{ width: '10%' }} />
  </StyledInputDropdown>
})

const StyledDropdownContainer = styled.div`
  margin-top: 5px;
  display: flex;
  min-width: 160px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 12px;
  background: ${colors.primary[500]};
  /* Dropdown-shadow */
  box-shadow: 0px 0px 20px 0px rgba(31, 31, 31, 0.10);

`

const CustomizedUl = styled.ul`
  margin: 0;
  padding: 0;
`

const CustomItem = styled(Dropdown.Item)`
  color: ${colors.neutral[100]};
  width: ${({ width }) => width ? `${width - 32}px` : '100%'};
  &.active {
    background-color: ${colors.primary[400]} !important;
  }
  &:hover {
    background-color: ${colors.primary[300]} !important;
    color: ${colors.neutral[100]};
  }
`

const CustomMenuDropdown = forwardRef(
  ({ children, className, 'aria-labelledby': labeledBy }, ref) => {
    const { isOpen, componentWidth } = useContext(DrodownContext)
    return isOpen && (
      <StyledDropdownContainer
        className={className}
        aria-labelledby={labeledBy}
        style={componentWidth ? { width: componentWidth } : undefined}
      >
        <CustomizedUl >
          {children}
        </CustomizedUl>
      </StyledDropdownContainer>
    );
  },
);

export const DropdownSelect = ({ options, placeholder, onChange, formFieldName, ...rest }) => {
  const [isOpen, setIsOpen] = useState()
  const ref = useRef()
  const { setValue, watch } = useFormContext()

  const value = watch(formFieldName)
  const [selectedValue, setSelectedValue] = useState(value)

  const componentWidth = useMemo(() => {
    if (ref.current) {
      const width = ref.current?.getBoundingClientRect().width
      return width
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  const handleItemClick = useCallback((option) => {
    setSelectedValue(option)
    if (formFieldName) setValue(formFieldName, option.value)
    setIsOpen(false)
  }, [formFieldName, setValue])

  return (
    <DrodownContext.Provider value={{ isOpen, setIsOpen, componentWidth, selectedValue, setSelectedValue }}>
      <Dropdown
        className="custom-dropdown-menu"
        id="dropdown-basic-button"
        title={value}
        ref={ref}
        onBlur={() => setIsOpen(false)}
        {...rest}
      >
        <Dropdown.Toggle
          as={CustomInputDropdown}
          id="dropdown-custom-components"
        >
          {value || placeholder || "Select option..."}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenuDropdown} ref={ref}>
          {options.map((option, index) => (
            <CustomItem
              key={index}
              eventKey={option.value}
              active={option.value === selectedValue?.value}
              onClick={() => {
                handleItemClick(option)
              }}
              width={componentWidth}
            >
              {option.label}
            </CustomItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </DrodownContext.Provider>
  );
}