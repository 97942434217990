import React, { useCallback, useEffect } from "react";
import EditableInlineComponent from "../Commons/EditableInput";
import Checkbox from "../Commons/Checkbox";
import useAxios from "../../OurCustomHooks/useAxios";
import { useSelector } from "react-redux";
import { Body } from "../Commons/Text";
import { theme } from "../../Theme";
import Icon from "../Commons/Icon";
import { StrikedText } from "../Commons/StrikedText";

const StickyReminder = ({ reminder, index, refresh, date }) => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo)
  const [editMode, setEditMode] = React.useState(false);
  const [reminderDetail, setReminderDetail] = React.useState(
    reminder.contact_name
  );  

  const updateReminder = useCallback(
    (_id, params) => {
      // TODO: Add a Loader here

      // Swal.fire({
      //   text: "Please wait for a while...",
      //   imageUrl: loaderGif,
      //   showConfirmButton: false,
      // });
      axiosClient({
        method: "put",
        url: process.env.REACT_APP_API_BASE_URL + "/contactradar",
        data: {
          radar_id: _id,
          userId: loginDataRedux.userID,
          ...params,
        },
      })
        .then((response) => {
          // console.log("All_Data: ", response);
          if (response.data.msg === "_success") {
            // Swal.fire({
            //   icon: "success",
            //   title: "Checked Successfully",
            //   text: "One Contact is checked successfully from the list",
            // });
            // TODO: Add a toast here
            refresh?.();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  const deleteContactRadar = (radarId) => {
    axiosClient({
      method: "delete",
      url: process.env.REACT_APP_API_BASE_URL + "/contactradar",
      data: {
        list_id: radarId,
        userId: loginDataRedux.userID,
      },
    })
      .then((response) => {
        // console.log("All_Data: ", response);
        if (response.data.msg === "_success") {
          // Swal.fire({
          //   icon: "success",
          //   title: "Deleted Successfully",
          //   text: "One Contact is deleted successfully from the list",
          // });
          refresh?.();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [strike, setStrike] = React.useState(false)
  
  useEffect(() => {
    setStrike(false);
  }, [reminder]);

  return (
    <div
      key={reminder.radarId}
      className="d-flex justify-content-between align-items-center"
    >
      <EditableInlineComponent
        name={`reminder[${index}].contact_name`}
        editMode={editMode}
        value={reminderDetail}
        onCancel={() => {
          setEditMode(false);
          setReminderDetail(reminder.contact_name);
        }}
        onConfirm={() => {
          setEditMode(false);
          updateReminder(reminder._id, { contact_name: reminderDetail });
        }}
        onChange={(e) => {
          setReminderDetail(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && reminderDetail.length > 0) {
            updateReminder(reminder._id, { contact_name: reminderDetail });
            setEditMode(false);
          }
        }}
        displayElement={
          <StrikedText className="d-flex align-items-center">
            <Checkbox
              checked={reminder.isCheckedOff || strike}
              onChange={() => {
                setStrike(true);
                updateReminder(reminder._id, {
                  isCheckedOff: !reminder.isCheckedOff, 
                  checkedOffDate: !reminder.isCheckedOff ? new Date(date) : undefined,
                });
              }}
            />
            <Body.MD.Regular
              className={`m-0 ${strike ? "strike" : ""}`}
              onClick={() => {
                setEditMode(true);
              }}
              style={{
                color: !reminder.isCheckedOff
                  ? theme.colors.neutral[100]
                  : theme.colors.neutral[400],
                textDecoration: reminder.isCheckedOff ? "line-through" : "none",
              }}
            >
              {reminder.contact_name}
            </Body.MD.Regular>
          </StrikedText>
        }
      />
      <Icon
        className="bi bi-trash"
        size={"md"}
        style={{
          color: theme.colors.semantic.negative.transparent.normal,
          cursor: "pointer",
        }}
        onClick={() => {
          deleteContactRadar(reminder._id);
        }}
      />
    </div>
  );
};

export default StickyReminder;
