import React, { useContext } from "react";
import DailyItem from "./DailyItem";
import { Body } from "../Commons/Text";
import styled from "styled-components";
import { DailyItemContext } from "../../Contexts/DailyItemContext";

export const StyledEmpty = styled.div`
  margin-top: 135px;
`;

const compareTimes = (a, b) => {
  if (a.startTime && !b.startTime) {
    return -1;
  }
  if (!a.startTime && b.startTime) {
    return 1;
  }
  if (!a.startTime && !b.startTime) {
    return 0;
  }

  const aTime = a.startTime.replace("12", "00");
  const bTime = b.startTime.replace("12", "00");

  const aPeriod = a.startTime.slice(-2);
  const bPeriod = b.startTime.slice(-2);

  if (aPeriod === "AM" && bPeriod === "PM") {
    return -1;
  } else if (aPeriod === "PM" && bPeriod === "AM") {
    return 1;
  }

  if (aTime > bTime) {
    return 1;
  } else if (aTime < bTime) {
    return -1;
  } else {
    if (a.endTime && !b.endTime) {
      return -1;
    }
    if (!a.endTime && b.endTime) {
      return 1;
    }
    if (!a.endTime && !b.endTime) {
      return 0;
    }

    const aEndTime = a.endTime.replace("12", "00");
    const bEndTime = b.endTime.replace("12", "00");

    const aEndPeriod = a.endTime.slice(-2);
    const bEndPeriod = b.endTime.slice(-2);

    if (aEndPeriod === "AM" && bEndPeriod === "PM") {
      return -1;
    } else if (aEndPeriod === "PM" && bEndPeriod === "AM") {
      return 1;
    }

    if (aEndTime > bEndTime) {
      return 1;
    } else if (aEndTime < bEndTime) {
      return -1;
    }

    return 0;
  }
};

const ListDailyItems = () => {
  const { currentItems } = useContext(DailyItemContext);

  return currentItems.length > 0 ? (
    <div className="px-3">
      {currentItems
        .filter((item) => item.status === "pending")
        .sort(compareTimes)
        .map((item, index) => (
          <DailyItem
            index={index}
            key={`${item._id}-${item.startTime}-${item.endTime}`}
            item={item}
          />
        ))}
      {currentItems
        .filter((item) => item.status === "completed")
        .sort(compareTimes)
        .map((item, index) => (
          <DailyItem
            index={index}
            key={`${item._id}-${item.startTime}-${item.endTime}`}
            item={item}
          />
        ))}
    </div>
  ) : (
    <StyledEmpty className="d-flex align-items-center justify-content-center p-2">
      <Body.MD.Light>
        It looks like you haven't added any activity for this day.
      </Body.MD.Light>
    </StyledEmpty>
  );
};

export default ListDailyItems;
