import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useAxios from "../../OurCustomHooks/useAxios";
import { Body, FootNotes, H5 } from "../Commons/Text";
import { styled } from "styled-components";
import { ButtonWithIcons } from "../Commons/Buttons";
import { theme } from "../../Theme";
import Icon from "../Commons/Icon";
import { HoverUpcommingCss } from "../DailyLogPage/styles";
import { CoachMarkContext } from "../../Contexts/coachmark";
import { Coachmarks } from "../Coachmarks";
import { StyledCoachmark } from "../Dashboard/styles";
import { useCoachmarkRefresh } from "../../OurCustomHooks/useCoachmarkRefresh";

const MilestonesContainer = styled.div`
  min-height: 560px;
  border-radius: 12px;
  border: 2px solid var(--accent-500, #6a5001);
  /* bakground blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 16px;
  background: var(
    --gradient-yellow-widgets,
    linear-gradient(
      180deg,
      rgba(106, 73, 1, 0.5) 0.17%,
      rgba(106, 73, 1, 0.05) 99.83%
    )
  );
  z-index: 0;
  position: relative;

  ${HoverUpcommingCss}
`;

const Milestone = ({ data }) => {
  return (
    <div className="d-flex flex-column align-items-start mb-2">
      <FootNotes color="var(--accent-300, #C79604) !important" style={{ fontSize: 14 }}>
        {moment(new Date(data.date)).format("MMM DD, YYYY")}
      </FootNotes>
      <FootNotes color={`${theme.colors.neutral[100]}!important`} style={{ fontSize: 14 }}>
        {data.short_term_goal}
      </FootNotes>
    </div>
  );
};

const UpcomingMilestones = forwardRef(({ modalsControls }, coachmarkRef) => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { coachmarkProps } = useContext(CoachMarkContext)

  const [milestoneList, setMilestoneList] = useState([]);

  const getMilestones = React.useCallback(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
        "/shorttermgoal/" +
        loginDataRedux.userID
      )
      .then((response) => {
        setMilestoneList(
          response.data.filter((row) => row.date && row.status === "pending" && row.long_term_goal_id).sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        );
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, loginDataRedux.userID]);

  useEffect(
    () => {
      getMilestones();
    },
    // eslin-disable-next-line
    [getMilestones]
  );

  const rerender = useCoachmarkRefresh('upcomming', coachmarkRef)


  return (
    <>
      {coachmarkProps.upcomming.visible && rerender > 0 &&
        <StyledCoachmark
          activate={coachmarkProps.upcomming.visible}
          component={<Coachmarks
            currentStep={6}
            handleNext={() => {
              coachmarkProps.upcomming?.handleNext?.()
            }}
            handlePrev={() => {
              coachmarkProps.upcomming.handlePrev()
            }}
          />}
          reference={coachmarkRef}
          tooltip={coachmarkProps.upcomming.tooltip}
        />
      }
      <MilestonesContainer
        className={`${milestoneList.length === 0 ? 'd-none d-lg-block' : ''} parent-hovered h-100`}
        ref={coachmarkRef}
      >
        <div className="d-none d-sm-flex">
          <i className="bi bi-flag" style={{ fontSize: 32, marginRight: 10 }} />
          <H5.Regular>
            Upcoming <br></br> Milestones
          </H5.Regular>
        </div>
        <div className="d-flex justify-content-center justify-content-md-start overflow-hidden position-relative">
          <Body.SM.Regular
            className="d-block d-md-none"
            style={{
              position: "absolute",
              left: -10,
              opacity: "0.5",
            }}
          >
            Journal
          </Body.SM.Regular>
          <H5.Regular> Upcoming <br></br> Milestones</H5.Regular>
        </div>
        {milestoneList.filter(ml => ml.date).length > 0 ? milestoneList.slice(0, 5).map((goal) => (
          <Milestone data={goal} key={goal._id} />
        )) : <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "70%" }}>
          <Body.MD.Light>It looks like you haven't set any upcoming goal yet.</Body.MD.Light>
        </div>}

        <div style={{ position: "absolute", bottom: 10, right: 0 }}>
          <ButtonWithIcons
            variant="underlined"
            rightIcon={<Icon className="bi bi-arrow-right"></Icon>}
            // TODO CHECK HOW TO CLICK OTHER MODAL
            onClick={() => {
              modalsControls.setOpenGoals(true);
              modalsControls.setOpenJournal(false);
            }}
          >
            Go To Goals
          </ButtonWithIcons>
        </div>
      </MilestonesContainer>
    </>
  );
});

export default React.memo(UpcomingMilestones);
