import React, { useCallback, useContext, useEffect } from "react";
import SetTime from "../Commons/SetTime";
import Icon from "../Commons/Icon";
import { theme } from "../../Theme";
import { useForm, useWatch } from "react-hook-form";
import EditableInlineComponent from "../Commons/EditableInput";
import Checkbox from "../Commons/Checkbox";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import { Body } from "../Commons/Text";
import { DailyItemContext } from "../../Contexts/DailyItemContext";
import { customToast } from "../../utils/toasts";
import styled from "styled-components";
import { StrikedText } from "../Commons/StrikedText";

const TimeContainer = styled.div`
  ${theme.mediaQueries.maxMobile} {
    padding-left: 0;
    min-height: 48px;
    align-items: center;
    display: flex;
  }
`

const DailyItem = ({ item, index }) => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const axiosClient = useAxios();

  const { refetch } = useContext(DailyItemContext);

  const [taskDetail, setTaskDetail] = React.useState(item.taskDetail);

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      todo: {
        startTime: undefined,
        endTime: undefined,
      },
    },
  });

  useEffect(() => {
    setValue("todo.startTime", item.startTime);
    setValue("todo.endTime", item.endTime);
  }, [item, setValue]);

  const startTime = useWatch({
    control,
    name: "todo.startTime",
  });

  const endTime = useWatch({
    control,
    name: "todo.endTime",
  });

  const [editMode, setEditMode] = React.useState(false);

  const updateItem = useCallback(
    (id, params) => {
      if (taskDetail) {
        axiosClient({
          method: "put",
          url: process.env.REACT_APP_API_BASE_URL + "/addtodolist/",
          data: {
            _id: id,
            userID: loginDataRedux.userID,
            ...params,
          },
        })
          .then((response) => {
            refetch?.();
          })
          .catch((err) => {
            console.log(err);
            customToast('An error has occurred. Please try again', 'error')
          });
      }
    },
    [axiosClient, loginDataRedux.userID, refetch, taskDetail]
  );

  const deleteItem = useCallback(
    (id) => {
      axiosClient({
        method: "delete",
        url: process.env.REACT_APP_API_BASE_URL + "/addtodolist/",
        data: {
          _id: id,
          userID: loginDataRedux.userID,
        },
      })
        .then(() => {
          refetch?.();
          customToast('Activity deleted successfully', 'success')
        })
        .catch((err) => {
          console.log(err);
          customToast('An error has occurred. Please try again', 'error')
        });
    },
    [axiosClient, loginDataRedux.userID, refetch]
  );

  const [strike, setStrike] = React.useState(false)
  
  useEffect(() => {
    setStrike(false);
  }, [item]);

  return (
    <div
      key={item._id}
      className="d-block d-md-flex justify-content-between align-items-center"
    >
      <div className="d-flex w-100 justify-content-between">
        <EditableInlineComponent
          name={`todo[${index}].taskDetail`}
          editMode={editMode}
          value={taskDetail}
          onCancel={() => {
            setEditMode(false);
            setTaskDetail(item.taskDetail);
          }}
          onConfirm={() => {
            setEditMode(false);
            updateItem(item._id, { taskDetail: taskDetail });
          }}
          onChange={(e) => {
            setTaskDetail(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && taskDetail.length > 0) {
              updateItem(item._id, {
                taskDetail: taskDetail,
              });
              setEditMode(false);
            }
          }}
          displayElement={
            <StrikedText className="d-flex align-items-center">
              <Checkbox
                checked={item.status === "completed" || strike}
                onChange={() => {
                  setStrike(true);
                  updateItem(item._id, {
                    status:
                      item.status === "completed" ? "pending" : "completed",
                  });
                }}
              />
              <Body.MD.Regular
                className={`m-0 ${strike ? "strike" : ""}`}
                onClick={() => {
                  setEditMode(true);
                }}
                style={{
                  color:
                    item.status === "pending"
                      ? theme.colors.neutral[100]
                      : theme.colors.neutral[400],
                  textDecoration:
                    item.status === "completed" ? "line-through" : "none",
                }}
              >
                {item.taskDetail}
              </Body.MD.Regular>
            </StrikedText>
          }
        />
        <div className="d-flex d-md-none align-items-center">
          <Icon
            className="bi bi-trash"
            style={{
              color: theme.colors.semantic.negative.transparent.normal,
              cursor: "pointer",
            }}
            onClick={() => {
              deleteItem(item._id);
            }}
            size="lg"
          />
        </div>
      </div>
      <TimeContainer>
        <form onSubmit={handleSubmit} className="w-100">
          <SetTime
            setValue={setValue}
            item={item}
            editable={true}
            control={control}
            rootName="todo"
            index={index}
            onCancel={() => {
              reset();
              setValue("todo.startTime", item.startTime);
              setValue("todo.endTime", item.endTime);
            }}
            onConfirm={() => {
              updateItem(item._id, {
                startTime: startTime,
                endTime: endTime,
              });
            }}
            displayElement={
              <div className="d-none d-md-flex align-items-center">
                <Icon
                  className="bi bi-trash"
                  style={{
                    color: theme.colors.semantic.negative.transparent.normal,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    deleteItem(item._id);
                  }}
                  size="lg"
                />
              </div>
            }
          />
        </form>
      </TimeContainer>
      <hr className="d-block d-md-none m-2" />
    </div>
  );
};

export default DailyItem;
