import React, { useState, useCallback, useEffect, useContext } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import { styled } from "styled-components";
import { theme } from "../../Theme";
// import { ButtonWithIcons } from "../Commons/Buttons";
import moment from "moment";
import { useThrottledCallback } from "../../OurCustomHooks/useThrottledCallback";
import { FootNotes } from "../Commons/Text";
import { DailyItemContext } from "../../Contexts/DailyItemContext";

const StyledCKEditor = styled.div`
  .ck {
    border-radius: 12px;
    border: 1px solid
      var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
    * {
      border: 0;
    }
  }
  .ck-button {
    color: ${theme.colors.primary[100]} !important;
  }
  .ck-toolbar {
    border-radius: 11px 11px 0 0 !important;
    background-color: ${theme.colors.primary[500]};
  }
  .ck-content {
    background: var(
      --neutral-transparent-100-white-5,
      rgba(255, 255, 255, 0.05)
    ) !important;
    /* bakground blur */
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0 0 11px 11px !important;
  }

  .ck-editor__editable {
    height: 200px;
  }

  .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
    background: ${theme.colors.primary.transparent[100]} !important;
  }

  .ck.ck-button.ck-on{
    background: ${theme.colors.primary.transparent[100]} !important;
  }
  
  .ck-dropdown__panel ul {
    background: ${theme.colors.primary[500]} !important;

    li:hover {
      background: ${theme.colors.primary.transparent[100]} !important;
    }
    .ck-button.ck-on {
      background: ${theme.colors.primary.transparent[300]} !important;
    }
  }

  .ck-button-save {
    svg {
      path {
        fill: ${theme.colors.primary[400]} !important;
      }
    }
  }

  .ck-button-cancel {
    svg {
      path {
        fill: ${theme.colors.semantic.negative.normal} !important;
      }
    }
  }
`;

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "indent",
      "outdent",
      "|",
      "blockQuote",
      "insertTable",
      // "mediaEmbed",
      "undo",
      "redo",
    ],
  },
  language: "en",
  placeholder: "Capture your thoughts, reflections, and aspirations here."
};

const TextEditor = () => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { currentDate } = useContext(DailyItemContext);
  const [loading, setLoading] = useState(false);

  const [original, setOriginal] = useState();
  const [editedData, setEditedData] = useState();
  const [lastUpdated, setLastUpdated] = useState();

  const [saving, setSaving] = useState(false);

  const getEditorData = useCallback(() => {
    setLoading(true);
    try {
      axiosClient
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/daysummary/${loginDataRedux.userID
          }?date=${moment(new Date(currentDate)).format("YYYY-DD-MM")}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            setOriginal(response.data[0].editorTextData);
            setEditedData(response.data[0].editorTextData);
            setLastUpdated(response.data[0].updatedAt);
          } else {
            console.log("No data found");
            setOriginal("");
            setEditedData("");
            setLastUpdated(currentDate);
          }
        });
    } catch (err) {
      // Handle errors here
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  }, [axiosClient, currentDate, loginDataRedux.userID]);

  useEffect(() => {
    getEditorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);


  const updateJournal = useCallback(() => {
    setSaving(true);
    axiosClient({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/daysummary`,
      data: {
        todayDate: moment(new Date(currentDate)).format("YYYY-DD-MM"),
        editorData: editedData,
        userId: loginDataRedux.userID,
      },
    })
      .then((response) => {
        if (response.data.msg === "updated") {
          setLastUpdated(response.data.edit.updatedAt);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(function () {
          setSaving(false);
        }, 5000);
      });
  }, [axiosClient, currentDate, editedData, loginDataRedux.userID]);

  const handleAction = useThrottledCallback(
    updateJournal, // callback
    1000, // time
    [] // dependencies
  );

  useEffect(() => {
    if (!loading && editedData && editedData !== original) {
      handleAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedData]);

  return (original !== undefined &&
    <>
      <div className="d-flex justify-content-between mb-2">
        <FootNotes>
          Last Saved: {moment(lastUpdated).format("hh:mm a, MMM DD, YYYY")}
        </FootNotes>
        <FootNotes className="me-1">
          {saving && <span>Saving...</span>}
        </FootNotes>
      </div>
      <StyledCKEditor>
        <CKEditor
          /*disabled={dateFromRedux !== sortedTodayDate}*/
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editedData}
          onChange={(_, editor) => {
            const data = editor.getData();
            setEditedData(data);
          }}
          placeholder="Capture your thoughts, reflections, and aspirations here."
          disabled={loading}
        />
      </StyledCKEditor>
      {/* <ButtonWithIcons className="mt-3" type="submit" onClick={updateJournal}>
        Save Journal
      </ButtonWithIcons> */}
    </>
  );
};

export default TextEditor;
