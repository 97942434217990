import { forwardRef } from "react";
import { keyframes, styled } from "styled-components";
import { colors, theme } from "../../Theme";
import Icon from "./Icon";
import { Body } from "./Text";
import { ReactComponent as LoadingSvg } from '../../Assets/Icons/loading.svg'

export const StyledButton = styled.button`
  display: flex;
  height: 48px;
  min-width: 155px;
  min-height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;

  color: ${colors.neutral[100]} !important;

  border-radius: 100px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  /* background blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
`;

export const Button = (props) => {
  return <StyledButton {...props} />;
};

const StyledButtonWithIcons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    switch (props.$variant) {
      case "underlined":
        return `
          border: 0;
          text-decoration-line: underline;
          background: transparent;
          padding: 0;

          &:hover {
            color: ${colors.primary[300]} !important;
          }
          &:disabled {
            background: transparent!important;
          }
        `;
      case "outlined":
        return `
        border: 0;
        background: transparent;
        padding: 0;

        &:hover {
          color: ${colors.primary[300]} !important;
        }
        &:disabled {
          background: transparent!important;
        }
        ${theme.mediaQueries.maxMobile} {
          margin: 0 !important;
          p {
            margin: 0;
          }
        }
      `;

      case "solid":
        return `
          border-radius: 100px;
          border: 1px solid var(--Primary-transparent-300, rgba(32, 180, 131, 0.50));
          background: var(--Primary-transparent-300, rgba(32, 180, 131, 0.50));

          /* bakground blur */
          -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);

          &:hover {
            border: 1px solid var(--Primary-200---base, #2ADFA4);
            background: var(--Primary-400, #168A64);

            /* buttons-on-hover */
            box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
          }
          &:focus:not(:disabled), &:focus-visible:not(:disabled) {
            outline: none;
            border: 1px solid var(--Primary-200---base, #2ADFA4);
            background: var(--Primary-transparent-200---base, rgba(42, 223, 164, 0.50));

            /* buttons-focused */
            box-shadow: 0px 0px 0px 10px rgba(174, 254, 218, 0.05), 0px 0px 0px 4px rgba(174, 254, 218, 0.10);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
          }
          &:disabled {
            background: transparent!important;
          }
        `;

      default:
        return `
          border-radius: 100px;
          border: 1px solid var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.20));
          border: 1px solid var(--Primary-transparent-300, rgba(32, 180, 131, 0.50));
          &:hover {
            border: 1px solid var(--primary-200-base, #2ADFA4);
            ${props.$iconbutton ? '' : 'background: var(--primary-transparent-400, rgba(22, 138, 100, 0.50));'}
            box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
          }
          &:focus:not(:disabled), &:focus-visible:not(:disabled) {
            outline: none;
            border: 1px solid var(--Primary-200---base, #2ADFA4);
            /* buttons-focused */
            box-shadow: 0px 0px 0px 10px rgba(174, 254, 218, 0.05), 0px 0px 0px 4px rgba(174, 254, 218, 0.10);
            backdrop-filter: blur(20px);
          }
          background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
          /* bakground blur */
          -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
        `;
    }
  }}

  &:disabled, &:hover:disabled {
    color: ${theme.colors.neutral.transparent.white50} !important;
    border-color: ${theme.colors.neutral.transparent.white50} !important;
    cursor: not-allowed;
    background: var(
      --neutral-transparent-100-white-5,
      rgba(255, 255, 255, 0.05)
    );
  }

  color: ${colors.neutral[100]} !important;
  font-size: 16px;

  cursor: pointer;
  transition: background-color 0.3s ease;

  height: 48px;
  min-height: 48px;

  ${({ $minwidth }) => 
    $minwidth ? `min-width: ${$minwidth};` : "min-width: 155px;"}

  ${({ selected }) => selected ? `
    border-radius: 100px;
    border: 1px solid var(--Primary-200---base, #2ADFA4);
    background: var(--Primary-transparent-200---base, rgba(42, 223, 164, 0.50));

    /* buttons-focused */
    box-shadow: 0px 0px 0px 10px rgba(174, 254, 218, 0.05), 0px 0px 0px 4px rgba(174, 254, 218, 0.10);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    cursor: not-allowed;
  `: ''}
`;

const rotation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const Loading = styled(LoadingSvg)`
  animation: ${rotation} 2s linear infinite;
`

export const ButtonWithIcons = forwardRef(
  (
    {
      leftIcon,
      rightIcon,
      variant,
      children,
      type = "button",
      $customChildren,
      loading,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButtonWithIcons
        $variant={variant}
        {...props}
        ref={ref}
        type={type}
      >
        {leftIcon && <span className="left-icon">{leftIcon}</span>}
        {!$customChildren ? (
          <Body.MD.Regular className="my-0 ms-0">{children}</Body.MD.Regular>
        ) : (
          $customChildren
        )}
        {loading && <Loading className="ms-2"/>}
        {rightIcon && <span className="ms-2 right-icon">{rightIcon}</span>}
      </StyledButtonWithIcons>
    );
  }
);

const StyledIconButton = styled.button`
  color: ${colors.neutral[100]};
  min-width: var(--sizes-32, 32px);
  width: var(--sizes-32, 32px);
  height: var(--sizes-32, 32px);
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 100px;
  border: 1px solid var(--primary-300, #20B483);
  background: var(--primary-transparent-300, rgba(32, 180, 131, 0.50));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);

  &:hover {
    border: 1px solid var(--primary-200-base, #2ADFA4);
    background: var(--primary-400, #168A64);
  }

  &:disabled:not(.selected) {
    border: 1px solid var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.20));
    background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
    cursor: not-allowed;
  }

  &:focus:not(:disabled), &:focus-visible:not(:disabled) {
    outline: none;
    border: 1px solid var(--Primary-200---base, #2ADFA4);
    /* buttons-focused */
    box-shadow: 0px 0px 0px 10px rgba(174, 254, 218, 0.05), 0px 0px 0px 4px rgba(174, 254, 218, 0.10);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  ${(props) =>
    props.$variant === "cancel"
      ? `
      color: ${colors.semantic.negative.normal} !important;
      border: 0;
      background: none;
      backdrop-filter: none;
      margin-right: 5px;
      &:hover, &:disabled {
        border: 0 !important;
        background: none !important;
      }
    ` : ""}

`;

const StyledDropdownButton = styled.button`
  color: ${colors.neutral[100]};
  width: var(--sizes-32, 32px);
  height: var(--sizes-32, 32px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;

  &.dropdown-toggle::after {
    display: none !important;
  }
`;

export const IconButton = forwardRef(({ children, variant, ...props }, ref) => {
  return (
    <StyledIconButton $variant={variant} {...props} ref={ref}>
      {children}
    </StyledIconButton>
  );
});

export const DropdownButton = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledDropdownButton {...props} ref={ref}>
      <Icon
        size={"sm"}
        className="bi bi-three-dots-vertical me-2 align-items-center"
      />
    </StyledDropdownButton>
  );
});

export const NextArrow = ({ fontSize, ...props }) => {

  return <ButtonWithIcons $customChildren={
    <i
      className="bi bi-arrow-right"
      style={{
        fontSize: fontSize ? fontSize : 48,
        cursor: "pointer",
      }}
      {...props}
    />
  } $minwidth="48px" $iconbutton={true} />
};

export const PrevArrow = ({ fontSize, ...props }) => {

  return <ButtonWithIcons $customChildren={
    <i
      className="bi bi-arrow-left"
      style={{
        fontSize: fontSize ? fontSize : 48,
        cursor: "pointer",
      }}
      {...props}
    />
  } $minwidth="48px" $iconbutton={true} />
};


export const ButtonFooter = styled.button`
  width: 77px;
  height: 56px;
  padding: 0px 12px 8px 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border: 0;
  
  color: ${colors.neutral[100]} !important;
  border-radius: 8px;

  
  background: ${props => props.$activate === "true" ? `${theme.colors.primary[400]}` : "transparent" };

  p {
    color: ${colors.neutral[100]} !important;
  }
`