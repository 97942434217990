import { styled } from "styled-components";

const StyledIcon = styled.i`
  font-size: ${props => {
    switch (props.$size) {
      case 'xl': return '32';
      case 'lg': return '24';
      case 'md': return '20';
      case 'sm': return '16';
      default: return '12'
    }
  }}px;
  ${({ $color }) => $color ? `color: ${$color} !important;` : ''}
`;

const Icon = ({ size, color, ...props }) => {
  return <StyledIcon $size={size} $color={color} {...props}></StyledIcon>
}

export default Icon;