import React, { forwardRef } from "react";
import { Row, Col, Container } from "react-bootstrap";

import UpcomingGoals from "../GoalsPage/UpcomingMilestones";
import Journal from "./Journal";
import ContactRadarLocal from "./ContactRadarLocal";
import Habits from "./Habits";
import TodoList from "./TodoList";
import { H5 } from "../Commons/Text";
import DateHeader from "./DateHeader";
import Tabs from "../Commons/Tabs";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";
import { theme } from "../../Theme";

const gradients = [
  `linear-gradient(
    180deg,
    rgba(5, 62, 43, 0.5) 0%,
    rgba(13, 99, 71, 0.05) 100%
  )`,
  `linear-gradient(
    180deg,
    rgba(170, 72, 17, 0.1) 0%,
    rgba(170, 72, 17, 0.01) 100%
  )`,
  `    linear-gradient(
    180deg,
    rgba(0, 101, 219, 0.1) 0.17%,
    rgba(0, 101, 219, 0.01) 99.83%
  )`,
``,
`linear-gradient(
  180deg,
  rgba(106, 73, 1, 0.5) 0.17%,
  rgba(106, 73, 1, 0.05) 99.83%
)`
  ];

const TabContainer = styled(Col)`

  ${theme.mediaQueries.maxMobile} {
    div:first-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

`;

const DailyLogContainer = styled(Container)`
  ${theme.mediaQueries.maxMobile} {
    height: calc(100vh - 71px);
  }
`;


const DailyLogMain = forwardRef(({ modalsControls }, refs) => {
  const { ref5, ref6, ref7, ref8, ref9 } = refs;

  const { isMobile } = useBreakpoint();

  const [activeTab, setActiveTab] = React.useState(0);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      // console.log("User Swiped!", eventData);
      if (eventData.dir === "Left") {
        if (activeTab < 4) {
          setActiveTab((s) => ++s);
        }
      } else if (eventData.dir === "Right") {
        if (activeTab > 0) {
          setActiveTab((s) => --s);
        }
      }
    },
  });
  return (
    <DailyLogContainer {...handlers}>
      <Row>
        <Col xs={12} className="text-center d-none d-md-block">
          <H5.Light className="my-3 my-md-0 mb-md-2">Daily Log</H5.Light>
        </Col>

        <Col xs={12} className="mt-2">
          <DateHeader />
        </Col>

        <Col xs={12} className="text-center d-block d-md-none">
          <Tabs
            tabs={[
              "Activity Planner",
              "Sticky Reminder",
              "Habits",
              "Journal",
              "Upcoming Milestones",
            ]}
            gradients={gradients}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            styled
          />
        </Col>
        {(activeTab === 0 || !isMobile) && <TabContainer xs={12} lg={9} className="mt-md-2">
          <TodoList ref={ref5} />
        </TabContainer>}
        {(activeTab === 4 || !isMobile) && <TabContainer xs={12} lg={3} className="mt-md-2">
          <UpcomingGoals modalsControls={modalsControls} ref={ref9} />
        </TabContainer>}
        {(activeTab === 1 || !isMobile) && <TabContainer xs={12} lg={6} className="mt-md-2">
          <ContactRadarLocal ref={ref6} />
        </TabContainer>}
        {(activeTab === 2 || !isMobile) && <TabContainer xs={12} lg={6} className="mt-md-2">
          <Habits ref={ref7} />
        </TabContainer>}
        {(activeTab === 3 || !isMobile) &&  <TabContainer xs={12}>
          <Journal ref={ref8} className="mt-2" />
        </TabContainer>}
      </Row>
    </DailyLogContainer>
  );
});

export default DailyLogMain;
