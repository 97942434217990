import React, { forwardRef, useState } from "react";
import { styled } from "styled-components";
import { colors } from "../../Theme";

const StyledInput = styled.input`
  width: 80%;
  text-align: center;
  font-family: Red Hat Display !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  &::placeholder {
    text-align: center;
    color: ${colors.neutral[200]};
  }
  color: ${colors.neutral[100]};
  border: 0;
  background: transparent;

  &:focus-visible {
    outline: 0px;
    &::placeholder {
      color: transparent;
    }
  }

  &:focus {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      display: none;
      color: transparent;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      display: none;
      color: transparent;

    }
    &:-ms-input-placeholder { /* IE 10+ */
      display: none;
      color: transparent;
    }
    &:-moz-placeholder { /* Firefox 18- */
      display: none;
      color: transparent;
    }
  }

  
  
`;

const InputContainer = styled.div`
  position: relative;
  padding: 16px;
  text-align: center;

  border-radius: 100px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  /* bakground blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  i {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 13px;
    &:first-child {
      left: 25px;
    }
    &:last-child {
      right: 25px;
    }
  }
  margin: 0 20px;

  &.focused {
    border: 1px solid var(--Neutral-100---White, #FFF);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    /* bakground blur */
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--Primary-300, #20B483);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    /* buttons-on-hover */
    box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  
`

export const Input = forwardRef(({ leftIcon, rightIcon = undefined, ...props }, ref) => {
  const [focused, setFocused] = useState(false)
  return <InputContainer className={focused ? 'focused' : ''}>
    {rightIcon}
    <StyledInput ref={ref} {...props} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
    {leftIcon}
  </InputContainer>
});