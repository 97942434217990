import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Home from "../Dashboard/Home";
import { useSelector } from "react-redux";
import Background from "../Images/background.png";
import LoginFormRouter from "./LoginFormRouter";
import { Route, Routes } from "react-router-dom";
import Onboarding from "../Onboarding/Onboarding";
import OnboardingMobile from "../Onboarding/OnboardingMobile";

import { AppContainer } from "./styles";
import { useDetectBrowser } from "../../OurCustomHooks/useDetectBrowser";
import ResetPasswordForm from "./ResetPasswordForm";
import ForgetPassword from "./ForgetPassword";
import LoginWrapper from "./LoginWrapper";

const Login = () => {
  const selectedImage = useSelector((state) => state.colorTheme.imagee);
  const loginState = useSelector((state) => state.login.loginState);
  const { isIphone } = useDetectBrowser()

  return (

    <AppContainer
      className="bgImg"
      $isiphone={isIphone.toString()}
      style={
        selectedImage
          ? {
            backgroundImage: `url(${selectedImage}), linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4))`,
            backgroundBlendMode: "overlay",
            // height: "100%",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            // minHeight: "100vh",

          }
          : {
            backgroundImage: `url(${Background}), linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4))`,
            backgroundBlendMode: "overlay",
            // height: "100%",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            // minHeight: "100vh",
          }
      }
    >
      {loginState ? (
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/onboarding" exact element={<Onboarding />} />
          <Route path="/onboardingMobile" exact element={<OnboardingMobile />} />
          <Route path="/forgot-password" exact element={<LoginWrapper><ForgetPassword showLinks={false}/></LoginWrapper>} />
          <Route path="/reset-password-form" exact element={<LoginWrapper><ResetPasswordForm /></LoginWrapper>} />
        </Routes>
      ) : (
        <Container
          className="d-flex justify-content-center overflow-y-scroll"
          style={{
            height: "100vh",
          }}
          fluid
        >
          <Row className="w-100">
            <Col
              className="d-flex flex-column text-center align-items-center"
              style={{ margin: "auto" }}
            >
              <LoginFormRouter />
            </Col>
          </Row>
        </Container>
      )}
    </AppContainer>
  );
};

export default Login;
