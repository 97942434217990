import React, { useCallback, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import Icon from "../Commons/Icon";
import { Body } from "../Commons/Text";
import { Collapse } from "react-bootstrap";
import BaseInput from "../Commons/BaseInput";
import CalendarInput from "../Commons/CalendarInput";
import { IconButton } from "../Commons/Buttons";
import MilestoneItem from "./MilestoneItem";

const MilestoneList = ({ milestones, goal, refresh }) => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      milestone: "",
      targetDate: undefined,
    },
  });
  const milestone = useWatch({
    control,
    name: "milestone",
  });
  const targetDate = useWatch({
    control,
    name: "targetDate",
  });

  const axiosClient = useAxios();

  const pendingMilestone = milestones
    .filter((milestone) => milestone.status === "pending")
    .sort((a, b) => {
      if (a.date === undefined) return 1;
      if (b.date === undefined) return -1;
      return new Date(a.date) - new Date(b.date);
    });
  const completedMilestone = milestones
    .filter((milestone) => milestone.status === "completed")
    .sort((a, b) => {
      if (a.date === undefined) return 1;
      if (b.date === undefined) return -1;
      return new Date(a.date) - new Date(b.date);
    });

  const [open, setOpen] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);

  const addMilestone = useCallback(() => {
    if (milestone.length > 0) {
      axiosClient({
        method: "post",
        url: process.env.REACT_APP_API_BASE_URL + "/shorttermgoal",
        data: {
          shortTermGoal: milestone,
          date: targetDate,
          userId: loginDataRedux.userID,
          status: "pending",
          long_term_goal_id: goal._id,
        },
      })
        .then((response) => {
          reset();
          if (response.data.msg === "_success") {
            refresh.milestones?.();
          }
        })
        .catch((err) => {
          console.log("Error is ", err);
        });
    }
  }, [
    axiosClient,
    goal._id,
    loginDataRedux.userID,
    milestone,
    refresh,
    reset,
    targetDate,
  ]);

  return (
    milestones && (
      <>
        <div
          className="d-flex text-start align-items-center mt-3"
          onClick={() => setOpen(!open)}
          aria-controls="collapse-text"
          aria-expanded={open}
          style={{ cursor: "pointer" }}
        >
          <Icon
            className={`me-2 bi ${
              open ? "bi-chevron-down" : "bi-chevron-right"
            }`}
          />
          <Body.SM.Regular className="m-0">
            Pending Milestone ({pendingMilestone.length})
          </Body.SM.Regular>
        </div>
        <Collapse in={open}>
          <div id="collapse-text">
            {goal.status === "pending" && (
              <form onSubmit={handleSubmit(addMilestone)}>
                <div className="d-block d-md-flex w-100 justify-content-between align-items-center pe-2">
                  <Controller
                    control={control}
                    name="milestone"
                    render={({ field: { value, onChange, onBlur, ref } }) => (
                      <BaseInput
                        placeholder="Add..."
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <Controller
                      control={control}
                      name="targetDate"
                      render={({ field: { value, onChange, onBlur, ref } }) => (
                        <CalendarInput
                          label="Target Date"
                          ref={ref}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          highlightDates={milestones
                            .filter((ml) => ml.date)
                            .map((milestone) => new Date(milestone.date))
                            .filter(
                              (milestone) => milestone.toString() !== "Invalid Date"
                            )
                          }
                        />
                      )}
                    />
                    <div className="d-flex">
                      <IconButton
                        type="button"
                        disabled={milestone.length === 0}
                        onClick={() => {
                          reset();
                        }}
                        variant="cancel"
                      >
                        <Icon className="bi bi-x-lg" size="sm" />
                      </IconButton>
                      <IconButton
                        type="submit"
                        disabled={milestone.length === 0}
                        className="p-0"
                      >
                        <Icon className="bi bi-check-lg" size="sm" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {pendingMilestone.map((milestone) => {
              return (
                <MilestoneItem
                  milestones={milestones}
                  milestone={milestone}
                  key={milestone._id}
                  refresh={refresh}
                />
              );
            })}
          </div>
        </Collapse>
        {completedMilestone && completedMilestone.length > 0 && (
          <>
            <div className="pe-3">
              <hr />
            </div>
            <div
              className="d-flex text-start align-items-center"
              onClick={() => setOpenCompleted(!openCompleted)}
              aria-controls="collapse-text"
              aria-expanded={openCompleted}
              style={{ cursor: "pointer" }}
            >
              <Icon
                className={`me-2 bi ${
                  openCompleted ? "bi-chevron-down" : "bi-chevron-right"
                }`}
              />
              <Body.SM.Regular className="m-0">
                Completed Milestone ({completedMilestone.length})
              </Body.SM.Regular>
            </div>
            <Collapse in={openCompleted}>
              <div id="collapse-text">
                {completedMilestone.map((milestone) => {
                  return (
                    <MilestoneItem
                      milestones={milestones}
                      milestone={milestone}
                      key={milestone._id}
                      refresh={refresh}
                    />
                  );
                })}
              </div>
            </Collapse>
          </>
        )}
      </>
    )
  );
};

export default MilestoneList;
