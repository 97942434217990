import { useCallback, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import useAxios from "../../OurCustomHooks/useAxios";
import { theme } from "../../Theme";
import { DropdownButton, ButtonWithIcons } from "../Commons/Buttons";
import Icon from "../Commons/Icon";
import { Body, FootNotes, H5 } from "../Commons/Text";
import EditableInlineComponent from "../Commons/EditableInput";
import MilestoneList from "./MilestoneList";
import CalendarInput from "../Commons/CalendarInput";

const StyledMobileCol = styled(Col)`
  border-right: 1px solid ${theme.colors.neutral.transparent.white20};

  ${theme.mediaQueries.maxMobile} {
    border-right: 0;
  }
`;

const StyledGoalBox = styled(Row)`
  display: flex;
  padding: 20px;
  align-items: flex-start;

  border-radius: 12px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: rgba(255, 255, 255, 0.1);

  ${theme.mediaQueries.maxMobile} {
    ${props => props.rounded === 'pending' ? `border-radius: 0;`: ''}
  }
`;

const Item = styled(Dropdown.Item)`
  color: ${theme.colors.neutral[100]} !important;
  margin: 0;
  padding: 0 10px;

  &:hover {
    background-color: ${theme.colors.primary[400]};
  }
`;

const calculateDaysLeft = (date) => {
  const days = Math.floor((date - new Date()) / (1000 * 60 * 60 * 24)) + 1;
  switch (true) {
    case days < 0:
      return (
        <FootNotes
          color={`${theme.colors.semantic.negative.normal} !important`}
        >{`${days * -1} day overdue`}</FootNotes>
      );
    case days === 0:
      return (
        <FootNotes
          color={`${theme.colors.accent[200]} !important`}
        >{`Expires today`}</FootNotes>
      );
    case days === 1:
      return (
        <FootNotes
          color={`${theme.colors.semantic.positive.normal} !important`}
        >{`${days} day away`}</FootNotes>
      );
    case days > 90 && days <= 730:
      return (
        <FootNotes
          color={`${theme.colors.semantic.positive.normal} !important`}
        >{`${Math.floor(days / 30)} months away`}</FootNotes>
      );
    case days > 730:
      return (
        <FootNotes
          color={`${theme.colors.semantic.positive.normal} !important`}
        >{`${Math.floor(days / 365)} years away`}</FootNotes>
      );
    default:
      return (
        <FootNotes
          color={`${theme.colors.semantic.positive.normal} !important`}
        >{`${days} days away`}</FootNotes>
      );
  }
};

const GoalBoxDetails = ({ goal, updateGoal, deleteGoal, goals = [] }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center ps-0 ps-md-3">
        <CalendarInput
          value={new Date(goal.date)}
          onChange={(date) => {
            updateGoal(goal._id, {
              date: date,
            });
          }}
          highlightDates={goals.map((goal) => new Date(goal.date)).filter((item) => item.toString() !== "Invalid Date")}
        />

        <Dropdown drop="left" data-bs-theme="light">
          <Dropdown.Toggle as={DropdownButton} id="dropdown-basic" key="left">
            Dropdown Button
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              background: theme.colors.primary[500],
              border: `1px solid ${theme.colors.neutral.transparent.white20}`,
              color: theme.colors.neutral[100],
            }}
            drop="left"
          >
            <Item
              onClick={() => {
                deleteGoal(goal._id);
              }}
            >
              <Body.MD.Regular className="m-0"> Delete </Body.MD.Regular>
            </Item>
            {goal.status === "completed" && (
              <Item
                onClick={() => {
                  updateGoal(goal._id, { status: "pending" });
                }}
              >
                <Body.MD.Regular className="m-0"> Restore </Body.MD.Regular>
              </Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {goal.status === "pending" && (
        <div className="d-flex justify-content-start ps-0 ps-md-3">
          {calculateDaysLeft(new Date(goal.date))}
        </div>
      )}
      {goal.status === "pending" && (
        <div className="d-flex justify-content-between align-items-center ps-0 ps-md-3 pt-2">
          <ButtonWithIcons
            className="w-100"
            leftIcon={<Icon className="bi bi-check" size="lg"></Icon>}
            style={{
              background: theme.colors.primary.transparent[300],
            }}
            onClick={() => {
              updateGoal(goal._id, { status: "completed" });
            }}
          >
            Mark Complete
          </ButtonWithIcons>
        </div>
      )}
    </>
  );
};

const GoalBox = ({ goal, index, goals = [], milestones, refresh, type = "pending"}) => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const [editMode, setEditMode] = useState(false);
  const [goalDetail, setGoalDetail] = useState(goal.long_term_goal);

  const axiosClient = useAxios();

  const updateGoal = useCallback(
    (id, params) => {
      axiosClient({
        method: "put",
        url: process.env.REACT_APP_API_BASE_URL + "/longtermgoal",
        data: {
          _id: id,
          userId: loginDataRedux.userID,
          ...params,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh.goals();
          }
        })
        .catch((err) => {
          console.log("Error is ", err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  const deleteGoal = useCallback(
    (id) => {
      axiosClient({
        method: "delete",
        url: process.env.REACT_APP_API_BASE_URL + "/longtermgoal",
        data: {
          list_id: id,
          userId: loginDataRedux.userID,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh.goals();
          }
        })
        .catch((err) => {
          console.log("Error is ", err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  return (
    <StyledGoalBox className="my-md-3 g-0" rounded={type}>
      <StyledMobileCol xs={12} md={8} lg={9}>
        <EditableInlineComponent
          name={`goal[${index}].long_term_goal`}
          value={goalDetail}
          onChange={(e) => setGoalDetail(e.target.value)}
          onConfirm={() => {
            setEditMode(false);
            updateGoal(goal._id, { long_term_goal: goalDetail });
          }}
          onCancel={() => {
            setEditMode(false);
            setGoalDetail(goal.long_term_goal);
          }}
          editMode={editMode}
          onKeyDown={(e) => {
            if (e.key === "Enter" && goalDetail.length > 0) {
              updateGoal(goal._id, {
                long_term_goal: goalDetail,
              });
              setEditMode(false);
            }
          }}
          displayElement={
            <div className="d-flex align-item-center ">
              <H5.Regular
                style={{
                  color:
                    goal.status === "pending"
                      ? theme.colors.accent[200]
                      : theme.colors.neutral[100],
                }}
              >
                {goal.long_term_goal}
              </H5.Regular>
              <Icon
                className="bi bi-pencil-square ms-2"
                size="lg"
                onClick={() => setEditMode(true)}
                style={{
                  cursor: "pointer",
                  color: theme.colors.neutral.transparent.white20,
                }}
              />
            </div>
          }
        />
        <div className="d-block d-md-none">
          <GoalBoxDetails
            goal={goal}
            updateGoal={updateGoal}
            deleteGoal={deleteGoal}
            goals={goals}
          />
        </div>
        <MilestoneList goal={goal} milestones={milestones} refresh={refresh} />
      </StyledMobileCol>
      <Col xs={6} md={4} lg={3} className="d-none d-md-block">
        <GoalBoxDetails
          goal={goal}
          updateGoal={updateGoal}
          deleteGoal={deleteGoal}
          goals={goals}
        />
      </Col>
    </StyledGoalBox>
  );
};

export default GoalBox;
