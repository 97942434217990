import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DefaultDashboard from "./DefaultDashboard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { colorThemeActions } from "../Store/ColorThemeState";
import { loginActions } from "../Store/LoginPageState";
import useAxios from "../../OurCustomHooks/useAxios";
import { FooterItem, Hamburger, HamburgerItem } from "../Commons/Hamburger";
import { theme } from "../../Theme";
import { ModalWrapper } from "../Commons/ModalWrapper";
import DailyLogMain from "../DailyLogPage/DailyLogMain";
import GeneralSettingMain from "../SettingFolder/GeneralSetting/GeneralSettingMain";
import GoalsMain from "../GoalsPage/GoalsMain";
import Logout from "./Logout";
import { FullLogo } from "../Commons/Brand";
import { Coachmarks, CoachmarksModal } from "../Coachmarks";
import Icon from "../Commons/Icon";
import {
  AbsoluteDivCoachmark,
  LogoContainer,
  MobileFooter,
  StyledCoachmark,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledHomeContainer,
  TransparentDiv,
} from "./styles";
import { CoachMarkContext } from "../../Contexts/coachmark";
import "../Css/coachmark.css";
import { DailyItemProvider } from "../../Contexts/DailyItemContext";
import { useSkipCoachmarks } from "../../OurCustomHooks/useSkipCoachmarks";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";
import { useDetectBrowser } from "../../OurCustomHooks/useDetectBrowser";
import { ButtonFooter, ButtonWithIcons } from "../Commons/Buttons";
import { FootNotes } from "../Commons/Text";
import styled from "styled-components";
import AdsAppModal from "./AdsAppModal";
import AdsExtension from "./AdsExtension";

const AdsContainers = styled.div`
  justify-content: center;
  width: 33%;
  button p,
  button {
    color: ${theme.colors.neutral.transparent.white50} !important;
  }
`;

const Home = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const axiosClient = useAxios();
  const handleSkip = useSkipCoachmarks(false);
  const { isMobile } = useBreakpoint();
  const { isIphone } = useDetectBrowser();

  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const imageLoaderRef = useRef(false);

  let uid = loginDataRedux.userID || localStorage.getItem("user_mail");
  let tk = window.localStorage.getItem("api_token");

  const generalSettingApiToken = useCallback(async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/generalSetting/${uid}?api_token=${tk}`
      );

      imageLoaderRef.current = false;
      if (response.data.length !== 0) {
        if (
          localStorage.getItem("bgImage") === undefined &&
          response.data?.allData[0]?.visionBoardImage === "undefined"
        ) {
        }
        if (
          localStorage.getItem("bgImage") === undefined &&
          response.data.allData[0]?.visionBoardImage !== "undefined"
        ) {
          localStorage.setItem(
            "bgImage",
            response.data.allData[0]?.visionBoardImage
          );
          dispatch(
            colorThemeActions.mySelectedImage(
              response.data.allData[0]?.visionBoardImage
            )
          );
        }
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  }, [axiosClient, dispatch, tk, uid]);

  useEffect(() => {
    imageLoaderRef.current = true;

    generalSettingApiToken();
    setTimeout(() => {
      if (Boolean(imageLoaderRef.current)) {
        // Swal.fire({
        //   title: "Server Error",
        //   text: "Something went wrong! Please try to login again",
        //   icon: "warning",
        //   confirmButtonText: "OK",
        // });

        localStorage.removeItem("bgImage");
        localStorage.removeItem("user_role");
        dispatch(loginActions.myLoginState(false));
        localStorage.setItem("manualLogin", false);
        localStorage.removeItem("manualLoginInfo");
        dispatch(colorThemeActions.mySelectedImage(""));
        history("/", { replace: true });
      }
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDataRedux, dispatch, history]);

  const [openJournal, setOpenJournal] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openGoals, setOpenGoals] = useState(false);
  const [openCoachmarks, setOpenCoachmarks] = useState(
    loginDataRedux.showCoachmarks
  );
  const [coachmarkProps, setCoachmarkProps] = useState({
    journal: {
      visible: false,
    },
    habits: {
      visible: false,
    },
    sticky: {
      visible: false,
    },
    planner: {
      visible: false,
    },
    upcomming: {
      visible: false,
    },
  });

  const [step, setStep] = useState(0);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);

  const modalsControls = {
    setOpenGoals: setOpenGoals,
    setOpenJournal: setOpenJournal,
    setOpenSettings: setOpenSettings,
    setOpenCoachmarks: setOpenCoachmarks,
  };

  const handleNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const handlePrev = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  const coachList = useMemo(
    () => [
      {
        activate: step === 1,
        component: (
          <Coachmarks
            currentStep={1}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref1,
        tooltip: { position: "bottom" },
      },
      {
        activate: step === 2,
        component: (
          <Coachmarks
            currentStep={2}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref2,
        tooltip: {
          position: "top",
        },
      },
      {
        activate: step === 3,
        component: (
          <Coachmarks
            currentStep={3}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref3,
        tooltip: { position: "left" },
      },
      {
        activate: step === 4,
        component: (
          <Coachmarks
            currentStep={4}
            handleNext={() => {
              setOpenJournal(true);
              handleNext();
            }}
            handlePrev={() => {
              setOpenJournal(false);
              handlePrev();
            }}
          />
        ),
        reference: ref4,
        tooltip: { position: "left" },
        handleNext,
        handlePrev,
      },
      {
        activate: step === 5,
        reference: ref5,
        tooltip: { position: "right" },
        handleNext: () => {
          handleNext();
          setCoachmarkProps((c) => {
            const planner = c.planner;
            const newProps = { ...c, planner: { ...planner, visible: false } };
            return newProps;
          });
        },
        handlePrev: () => {
          handlePrev();
          setOpenJournal(false);
          setCoachmarkProps((c) => {
            const planner = c.planner;
            const newProps = { ...c, planner: { ...planner, visible: false } };
            return newProps;
          });
        },
      },
      {
        activate: step === 6,
        reference: ref6,
        tooltip: { position: "right" },
        handleNext: () => {
          handleNext();
          setCoachmarkProps((c) => {
            const sticky = c.sticky;
            const habits = c.habits;
            const newProps = {
              ...c,
              sticky: { ...sticky, visible: false },
              habits: { ...habits, visible: true },
            };
            return newProps;
          });
        },
        handlePrev: () => {
          handlePrev();
          setCoachmarkProps((c) => {
            const sticky = c.sticky;
            const planner = c.planner;
            const newProps = {
              ...c,
              sticky: { ...sticky, visible: false },
              planner: { ...planner, visible: true },
            };
            return newProps;
          });
        },
      },
      {
        activate: step === 7,
        component: (
          <Coachmarks
            currentStep={7}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref7,
        tooltip: { position: "left" },
        handleNext: () => {
          handleNext();
          setCoachmarkProps((c) => {
            const habits = c.habits;
            const journal = c.journal;
            const newProps = {
              ...c,
              habits: { ...habits, visible: false },
              journal: { ...journal, visible: true },
            };
            return newProps;
          });
        },
        handlePrev: () => {
          handlePrev();
          setCoachmarkProps((c) => {
            const sticky = c.sticky;
            const habits = c.habits;
            const newProps = {
              ...c,
              habits: { ...habits, visible: false },
              sticky: { ...sticky, visible: true },
            };
            return newProps;
          });
        },
      },
      {
        activate: step === 8,
        component: (
          <Coachmarks
            currentStep={8}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref8,
        tooltip: { position: "top" },
        handleNext: () => {
          handleNext();
          setCoachmarkProps((c) => {
            const journal = c.journal;
            const upcomming = c.upcomming;
            const newProps = {
              ...c,
              journal: { ...journal, visible: false },
              upcomming: { ...upcomming, visible: true },
            };
            return newProps;
          });
        },
        handlePrev: () => {
          handlePrev();
          setCoachmarkProps((c) => {
            const journal = c.journal;
            const habits = c.habits;
            const newProps = {
              ...c,
              journal: { ...journal, visible: false },
              habits: { ...habits, visible: true },
            };
            return newProps;
          });
        },
      },
      {
        activate: step === 9,
        component: (
          <Coachmarks
            currentStep={9}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref9,
        tooltip: { position: "left" },
        handleNext: () => {
          handleNext();
          setCoachmarkProps((c) => {
            const upcomming = c.upcomming;
            const newProps = {
              ...c,
              upcomming: { ...upcomming, visible: false },
            };
            return newProps;
          });
          setOpenJournal(false);
        },
        handlePrev: () => {
          handlePrev();
          setCoachmarkProps((c) => {
            const journal = c.journal;
            const upcomming = c.upcomming;
            const newProps = {
              ...c,
              upcomming: { ...upcomming, visible: false },
              journal: { ...journal, visible: true },
            };
            return newProps;
          });
        },
      },
      {
        activate: step === 10,
        component: (
          <Coachmarks
            currentStep={10}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ),
        reference: ref10,
        tooltip: { position: "bottom" },
      },
    ],
    [handleNext, handlePrev, step]
  );

  const coachComponent = useMemo(() => {
    const foundCoach = coachList.find((x) => x.activate);
    if (step === 5) {
      return setCoachmarkProps((c) => {
        const newProps = { ...c, planner: { ...foundCoach, visible: true } };
        return newProps;
      });
    }
    if (step === 6) {
      return setCoachmarkProps((c) => {
        const newProps = { ...c, sticky: { ...foundCoach, visible: true } };
        return newProps;
      });
    }
    if (step === 7) {
      return setCoachmarkProps((c) => {
        const newProps = { ...c, habits: { ...foundCoach, visible: true } };
        return newProps;
      });
    }
    if (step === 8) {
      return setCoachmarkProps((c) => {
        const newProps = { ...c, journal: { ...foundCoach, visible: true } };
        return newProps;
      });
    }
    if (step === 9) {
      return setCoachmarkProps((c) => {
        const newProps = { ...c, upcomming: { ...foundCoach, visible: true } };
        return newProps;
      });
    }
    return <StyledCoachmark {...foundCoach} />;
  }, [coachList, step]);

  const [openAds, setOpenAds] = useState(false);
  const [openAdsExtension, setOpenAdsExtension] = useState(false);

  return (
    <StyledHomeContainer
      $isiphone={isIphone.toString()}
      className="d-flex flex-column justify-content-between"
    >
      <CoachMarkContext.Provider
        value={{
          coachmarkProps,
          setCoachmarkProps,
          step,
          setStep,
        }}
      >
        <ModalWrapper
          open={openAds}
          handleClose={() => {
            setOpenAds(false);
          }}
        >
          <AdsAppModal />
        </ModalWrapper>
        <ModalWrapper
          open={openAdsExtension}
          handleClose={() => {
            setOpenAdsExtension(false);
          }}
        >
          <AdsExtension />
        </ModalWrapper>
        <ModalWrapper
          open={openSettings}
          handleClose={() => {
            setOpenSettings(false);
          }}
        >
          <GeneralSettingMain />
        </ModalWrapper>
        <DailyItemProvider>
          <ModalWrapper
            open={openJournal}
            handleClose={() => {
              setOpenJournal(false);
            }}
            onEscapeKeyDown={(e) => step && step > 0 && e.preventDefault()}
            enforceFocus={false}
          >
            <DailyLogMain
              modalsControls={modalsControls}
              ref={{ ref5, ref6, ref7, ref8, ref9 }}
            />
          </ModalWrapper>

          <ModalWrapper
            open={openGoals}
            handleClose={() => {
              setOpenGoals(false);
            }}
          >
            <GoalsMain />
          </ModalWrapper>

          <ModalWrapper
            open={!isMobile && openCoachmarks}
            handleClose={() => {
              setOpenCoachmarks(false);
              handleSkip();
            }}
            hideClose
          >
            <CoachmarksModal
              showModal={setOpenCoachmarks}
              setStep={setStep}
              firstTime={loginDataRedux.showCoachmarks}
            />
          </ModalWrapper>

          <AbsoluteDivCoachmark className="d-flex justify-content-end align-items-center">
            <TransparentDiv ref={ref1} />
          </AbsoluteDivCoachmark>
          <AbsoluteDivCoachmark
            $big="true"
            className="d-flex justify-content-end align-items-center"
          >
            <TransparentDiv ref={ref10} />
          </AbsoluteDivCoachmark>

          <StyledHeaderContainer
            fluid
            className="justify-content-between align-items-center d-flex"
            style={{
              height: isMobile ? 80 : 110,
            }}
          >
            <LogoContainer>
              <FullLogo alt="GrowFrom" style={{ width: 252, height: 70 }} />
            </LogoContainer>
            <Hamburger className="d-none d-md-flex">
              <HamburgerItem
                label="Daily Log"
                icon={<Icon className="bi bi-clock" size={"xl"} />}
                onClick={() => {
                  setOpenJournal((s) => true);
                }}
                ref={ref4}
              />
              <HamburgerItem
                label="Goals"
                icon={<Icon className="bi bi-flag" size={"xl"} />}
                onClick={() => {
                  setOpenGoals((s) => true);
                }}
                ref={ref3}
              />
            </Hamburger>
          </StyledHeaderContainer>
          <DefaultDashboard />
        </DailyItemProvider>

        <div className="position-relative d-none d-md-flex w-100 justify-content-between">
          <div
           style={{ width: '33%'}}
           ></div>
          <AdsContainers className="d-none d-sm-flex">
            <ButtonWithIcons
              variant="underlined"
              className="m-0 p-0"
              onClick={() => setOpenAds(true)}
            >
              Get the App
            </ButtonWithIcons>
            <ButtonWithIcons
              variant="underlined"
              className="m-0 p-0"
              onClick={() => setOpenAdsExtension(true)}
            >
              Get the Extension
            </ButtonWithIcons>
          </AdsContainers>

          <StyledFooterContainer
            className="d-flex align-items-end px-3 py-2 justify-content-end"
            style={{ width: '33%'}}
          >
            <FooterItem
              className="d-flex justify-content-end"
              icon={<Icon className="bi bi-gear" size={"lg"} />}
              style={{
                marginRight: 15,
                color: theme.colors.neutral.transparent.white50,
                width: 250,
              }}
              onClick={() => {
                setOpenSettings((s) => true);
              }}
              ref={ref2}
            />

            {!isMobile && (
              <FooterItem
                icon={<Icon className="bi bi-life-preserver" size={"lg"} />}
                style={{
                  marginRight: 21,
                  color: theme.colors.neutral.transparent.white50,
                }}
                onClick={() => setOpenCoachmarks(true)}
              />
            )}
            <Logout />
          </StyledFooterContainer>
        </div>

        {!isMobile ? coachComponent : undefined}

        <MobileFooter className="d-flex d-md-none w-100 justify-content-between align-items-end px-3 py-2">
          <ButtonFooter
            $activate={(!openGoals && !openJournal && !openSettings).toString()}
            onClick={() => {
              setOpenGoals((s) => false);
              setOpenJournal((s) => false);
              setOpenSettings((s) => false);
            }}
          >
            <Icon className="bi bi-house" size="lg" />
            <FootNotes>Home</FootNotes>
          </ButtonFooter>
          <ButtonFooter
            $activate={openJournal.toString()}
            onClick={() => {
              setOpenSettings((s) => false);
              setOpenGoals((s) => false);
              setOpenJournal((s) => true);
            }}
          >
            <Icon className="bi bi-clock" size="lg" />
            <FootNotes>Daily Log</FootNotes>
          </ButtonFooter>
          <ButtonFooter
            $activate={openGoals.toString()}
            onClick={() => {
              setOpenSettings((s) => false);
              setOpenJournal((s) => false);
              setOpenGoals((s) => true);
            }}
          >
            <Icon className="bi bi-flag" size="lg" />
            <FootNotes>Goals</FootNotes>
          </ButtonFooter>
          <ButtonFooter
            $activate={openSettings.toString()}
            onClick={() => {
              setOpenJournal((s) => false);
              setOpenGoals((s) => false);
              setOpenSettings((s) => true);
            }}
          >
            <Icon className="bi bi-gear" size="lg" />
            <FootNotes>Settings</FootNotes>
          </ButtonFooter>
        </MobileFooter>
      </CoachMarkContext.Provider>
    </StyledHomeContainer>
  );
};

export default Home;
