import React, { forwardRef } from "react";
import styled from "styled-components";
import { theme } from "../../Theme";
import Icon from "./Icon";
import { Body } from "./Text";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  display: none;
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  margin-right: 10px;
  background: ${theme.colors.neutral.transparent.white100};
  border: 1px solid ${theme.colors.primary[400]};
  border-radius: 0.2em;

  &::disabled {
    cursor: not-allowed;
  }

  &:focus, &:focus-within {
    border-color: ${theme.colors.primary[200]} !important;
    outline: 0;
  }
`;

const Label = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  padding: 3px 0;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  &:hover * {
    border-color: ${theme.colors.primary[200]} !important;
    i {
      color: ${theme.colors.primary[200]} !important;
    }
  }

  i {
    display: none;
  }

  ${Input}:checked + ${Indicator} {
    i {
      color: ${theme.colors.primary[400]};
      display: block;
      position: relative;
      top: -8px;
      left: -4px;
    }
  }

  ${Input}:checked + ${Indicator} + p {
    text-decoration: line-through;
    color: ${theme.colors.neutral[400]} !important;
  }
`;

const Checkbox = forwardRef(({
  value,
  checked,
  onChange,
  name,
  id,
  label,
  className,
  disabled
}, ref) => {
  return (
    <Label htmlFor={id} disabled={disabled} className={className}>
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        ref={ref}
      />
      <Indicator tabIndex="0" onKeyDown={(e) => {
            if (e.code === 'Enter' || e.code === 'Space') {
              onChange({ target: { value, checked: !checked } });
            }
        }}>
        <Icon className="bi bi-check" size="lg"/>
      </Indicator>
      <Body.MD.Regular className="m-0">
        {label}
      </Body.MD.Regular>
    </Label>
  );
});

export default Checkbox;