import React, { forwardRef } from "react";
import styled from "styled-components";
import { IconButton } from "./Buttons";
import Icon from "./Icon";

const EditableInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 4px;
  border-radius: 4px;

  .text-label {
    color: white;
    flex: 1;
  }

  input {
    border: none;
    background: transparent;
    flex: 1;
    color: white;
    padding: 4px;
    outline: none;
    display: block;
  }

  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }

  .counter {
    color: white;
    font-size: 12px;
  }
`;

const EditableInlineComponent = forwardRef(
  (
    {
      name,
      value,
      onChange,
      onConfirm,
      onCancel,
      onKeyDown,
      maxLength = 100,
      displayElement = <></>,
      editMode = false,
    },
    ref
  ) => {

    return editMode ? (
      <div className="d-flex align-items-center w-100 pe-2">
        <EditableInputContainer className="w-100 me-2">
          <input
            name={name}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            type="text"
            ref={ref}
          />
          <span className="counter">
            {value?.length}/{maxLength}
          </span>
        </EditableInputContainer>
        <IconButton
          type="button"
          onClick={onCancel}
          variant="cancel"
        >
          <Icon className="bi bi-x-lg" size="sm" />
        </IconButton>
        <IconButton
          type="submit"
          disabled={value?.length === 0}
          onClick={onConfirm}
          className="p-0"
        >
          <Icon className="bi bi-check-lg" size="sm" />
        </IconButton>
      </div>
    ) : (
      displayElement
    );
  }
);

export default EditableInlineComponent;
