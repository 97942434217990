import React, { useContext, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import useAxios from "../../OurCustomHooks/useAxios";
import { useSelector } from "react-redux";
import Icon from "../Commons/Icon";
import { IconButton } from "../Commons/Buttons";
import SetTime from "../Commons/SetTime";
import BaseInput from "../Commons/BaseInput";
import { Body, H5 } from "../Commons/Text";
import styled from "styled-components";
import { theme } from "../../Theme";
import { DailyItemContext } from "../../Contexts/DailyItemContext";
import { customToast } from "../../utils/toasts";


const TimeContainer = styled.div`
  ${theme.mediaQueries.maxMobile} {
    min-height: 48px;
  }
`;

const AddDailyItem = () => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const { currentDate, refetch } = useContext(DailyItemContext);

  const [clear, setClear] = useState(() => { });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      taskDetail: "",
      startTime: undefined,
      endTime: undefined,
    },
  });

  const taskDetail = useWatch({ control, name: "taskDetail" });
  const startTime = useWatch({ control, name: "startTime" });
  const endTime = useWatch({ control, name: "endTime" });


  const addTodoItem = React.useCallback(() => {
    if (taskDetail) {
      axiosClient({
        method: "post",
        url: process.env.REACT_APP_API_BASE_URL + "/addtodolist",
        data: {
          taskDetail: taskDetail,
          startTime: startTime,
          endTime: endTime,
          todayDate: currentDate,
          status: "pending",
          userId: loginDataRedux.userID,
        },
      })
        .then((response) => {
          refetch?.();
          reset();
          clear();
          customToast('Activity added successfully', 'success')
        })
        .catch((err) => {
          console.log(err);
          customToast('An error has occurred. Please try again', 'error')
        });
    }
  }, [
    axiosClient,
    clear,
    currentDate,
    endTime,
    loginDataRedux.userID,
    refetch,
    reset,
    startTime,
    taskDetail,
  ]);

  return (
    <>
      <div className="p-3">
        <div className="d-flex justify-content-center justify-content-md-start overflow-hidden position-relative">
          <H5.Regular>Activity Planner</H5.Regular>
          <Body.SM.Regular className="d-block d-md-none" 
          style={{
            position: "absolute",
            right: -50, 
            opacity: '0.5'
          }}>Sticky Reminder</Body.SM.Regular>
        </div>
        <div>
          <form onSubmit={handleSubmit(addTodoItem)}>
            <div className="d-block d-md-flex align-items-center">
              <Controller
                control={control}
                name="taskDetail"
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <BaseInput
                    placeholder="Add Activity to Your Plan"
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    $placeholdercenter={true}
                  />
                )}
              />
              <TimeContainer className="d-flex justify-content-between align-items-center">
                <SetTime control={control} setClear={setClear} setValue={setValue} />
                <div>
                  <IconButton type="submit" disabled={taskDetail.length === 0} className="p-0">
                    <Icon className="bi bi-check-lg" size="sm" />
                  </IconButton>
                </div>
              </TimeContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDailyItem;
