import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Dots } from "../Commons/Dots";
import { Body, H3, H4 } from "../Commons/Text";

import { coachmarksMobileArray } from "../../utils/coachmarks";
import { CoachmarkContent } from "../Coachmarks/styles";
import { useSwipeable } from "react-swipeable";
import { ButtonWithIcons } from "../Commons/Buttons";
import Icon from "../Commons/Icon";

const OnboardingMobile = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      // console.log("User Swiped!", eventData);
      if (eventData.dir === "Left") {
        if (step === coachmarksMobileArray.length - 1) {
          navigate("/onboarding");
        } else {
          setStep((s) => ++s);
        }
      } else if (eventData.dir === "Right") {
        if (step > 0) {
          setStep((s) => --s);
        }
      }
    },
  });

  return (
    <Container
      className="d-flex flex-column justify-content-between overflow-y-scroll"
      style={{
        height: "100vh",
      }}
      fluid
      {...handlers}
    >
      <div className="w-100" style={{ margin: "auto", position: "relative" }}>
        {coachmarksMobileArray.map((currentCoachmark, index) => (
          <CoachmarkContent
            className="d-flex flex-column align-items-center justify-content-center w-100 h-100 text-center"
            key={`coach-${index}`}
            style={{
              opacity: index === step ? 1 : 0,
              position: "absolute",
              visibility: index === step ? "visible" : "hidden",
            }}
          >
            {currentCoachmark.subSection && (
              <Body.LG.Semibold>{currentCoachmark.subSection}</Body.LG.Semibold>
            )}
            <H3.Semibold>{currentCoachmark.section}</H3.Semibold>
            {currentCoachmark.title && (
              <H4.Semibold>{currentCoachmark.title}</H4.Semibold>
            )}
            <Body.MD.Regular>{currentCoachmark.description}</Body.MD.Regular>

            {coachmarksMobileArray.length === index + 1 && (
              <ButtonWithIcons
                variant="solid"
                rightIcon={<Icon className="bi bi-arrow-right" size="md" />}
                onClick={() => navigate("/onboarding")}
              >
                Get Started
              </ButtonWithIcons>
            )}
          </CoachmarkContent>
        ))}
      </div>

      <div>
        {step !== coachmarksMobileArray.length && (
          <div className="mt-4 d-flex justify-content-center w-100">
            <div>
              {Array.from({ length: coachmarksMobileArray.length }, (x, i) => {
                return step === i ? (
                  <Dots key={`dots_${i}`} onClick={() => setStep(i)} />
                ) : (
                  <Dots fill key={`dots_${i}`} onClick={() => setStep(i)} />
                );
              })}
            </div>
          </div>
        )}
        <Body.MD.Regular style={{ marginTop: 48 }}>
          <Link to="/onboarding">Skip</Link>
        </Body.MD.Regular>
      </div>
    </Container>
  );
};

export default OnboardingMobile;
