import { useCallback, useMemo } from "react"

// Components
import { Body } from "../Commons/Text"

// Assets
import { ReactComponent as EmptyPass } from '../../Assets/Icons/pass-empty.svg'
import { ReactComponent as CheckedPass } from '../../Assets/Icons/pass-checked.svg'


import { StyledItem, StyledItemsContainer, StyledTooltipContainer } from "./styles"

export const PasswordTooltip = ({ pass }) => {

  const passwordState = useMemo(() => {
    let out = {}
    if (!pass || pass === "") return out
    out.hasLength = pass.length >= 8
    out.hasUpper = /[A-Z]/.test(pass)
    out.hasLower = /[a-z]/.test(pass)
    out.hasNumbers = /\d/.test(pass)

    return out
  }, [pass])

  const getPasswordStatusIcon = useCallback((prop) => {

    if (passwordState && passwordState[prop]) return <CheckedPass />

    return <EmptyPass />
  }, [passwordState])

  return <StyledTooltipContainer id="tooltip" trigger={['focus', 'hover', 'click']}>
    <StyledItemsContainer>
      <Body.MD.Regular className="mb-0">Your password must contain:</Body.MD.Regular>
      <StyledItem>
        {getPasswordStatusIcon('hasLength')}
        <Body.SM.Regular className="mb-0">At least 8 Characters</Body.SM.Regular>
      </StyledItem>
      <StyledItem>
        {getPasswordStatusIcon('hasLower')}
        <Body.SM.Regular className="mb-0">Lower case letters (a-z)</Body.SM.Regular>
      </StyledItem>
      <StyledItem>
        {getPasswordStatusIcon('hasUpper')}
        <Body.SM.Regular className="mb-0">Upper case letters (A-Z)</Body.SM.Regular>
      </StyledItem>
      <StyledItem>
        {getPasswordStatusIcon('hasNumbers')}
        <Body.SM.Regular className="mb-0">Numbers (0-9)</Body.SM.Regular>
      </StyledItem>
    </StyledItemsContainer>
  </StyledTooltipContainer>
}