import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

let localLoginData;
if (localStorage.getItem(("manualLoginInfo"))) {
    localLoginData = JSON.parse(localStorage.getItem(("manualLoginInfo")))
}
else {
    localLoginData = []
}

const initialState = {
    counter: 0,
    loginState: localStorage.getItem("manualLogin") === "true" ? true : false,
    loginInfo: (localLoginData === null) ? {} : localLoginData,
    selectedDate: moment().format('MM-DD-YYYY'),
    dateFromPastLog: moment().format('MM-DD-YYYY'),
    extraRedux: false,
    loginRouting: false,
    openDashboardRedux: true,
    animationState: false,
    daysSummary: [],
    reFetchDaysSummary: 0,
    postId: '',
    color: '',
    updatedName: ''

};

const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        myCounter(state) {
            state.counter++;
        },
        myLoginState(state, action) {
            state.loginState = action.payload
        },
        myLoginInfo(state, action) {
            state.loginInfo = action.payload
        },
        mySelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        mySelectedDateFromPastLog(state, action) {
            state.dateFromPastLog = action.payload;
        },
        myExtraState(state, action) {
            state.extraRedux = action.payload;
        },
        myLoginRoutingStatus(state, action) {
            state.loginRouting = action.payload;
        },
        myOpenDashboardFromRedux(state, action) {
            state.openDashboardRedux = action.payload;
        },
        myAnimationState(state, action) {
            state.animationState = action.payload;
        },
        myDaysSummary(state, action) {
            state.daysSummary = action.payload;
        },
        reFetchDaysSummary(state, action) {
            state.reFetchDaysSummary += 1;
        },
        // myTodayTodoList(state, action) {
        //     state.todayTodoList = action.payload;
        // }
        mySelectedPostId(state, action) {
            state.postId = action.payload;
        },
        mySelectedColor(state, action) {
            state.color = action.payload
        },
        myUpdatedName(state, action) {
            state.updatedName = action.payload
        }
    }

});

export const loginActions = loginSlice.actions;
export default loginSlice.reducer;

