import { configureStore } from "@reduxjs/toolkit";
import loginReducer from './LoginPageState';
import colorThemeReducer from './ColorThemeState';

const store = configureStore({
    reducer: {
        login: loginReducer,
        colorTheme: colorThemeReducer
    },

});
export default store;