import React, { useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../OurCustomHooks/useAxios";
import useTime from "../../utils/date";
import { FullLogo, Logo } from "../Commons/Brand";
import { NextArrow, PrevArrow } from "../Commons/Buttons";
import { Dots } from "../Commons/Dots";
import FormInput, {
  FormInputWithRightElement,
  Separator,
} from "../Commons/FormInput";
import ImageGallery from "../Commons/Gallery";
import { Body, H1, H3, H4, H5 } from "../Commons/Text";
import CalendarInput from "../Commons/CalendarInput";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";

const Onboarding = () => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const updatedName = useSelector((state) => state.login.updatedName);
  const { isMobile } = useBreakpoint();

  const {
    control,
    register,
    trigger,
    formState: { isValid, errors },
    setFocus
  } = useForm();

  const axiosClient = useAxios();
  const navigate = useNavigate();
  const now = new Date();
  const goal = useWatch({ control, name: "goal" });
  const todoItem = useWatch({ control, name: "todoItem" });

  const calendarRef = useRef(null);

  const [step, setStep] = useState(0);
  const [dateGoal, setDateGoal] = useState(now);
  // const [calendarWidth, setCalendarWidth] = useState(0);

  const { getGreetings } = useTime();

  const handleVerification = React.useCallback(() => {
    trigger();
    if (isValid) {
      setStep((s) => ++s);
      //   if (step === 1) {
      //     setSubmitError();
      //   }
      if (step === 2) {
        Promise.all([
          axiosClient({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/longtermgoal",
            data: {
              longTermGoal: goal,
              date: dateGoal,
              userId: loginDataRedux.userID,
              status: "pending",
            },
          }),
          axiosClient({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/addtodolist",
            data: {
              taskDetail: todoItem,
              startTime: undefined,
              endTime: undefined,
              todayDate: new Date(),
              status: "pending",
              userId: loginDataRedux.userID,
            },
          }),
          // axiosClient
          //   .post(process.env.REACT_APP_API_BASE_URL + `/generalSetting`, {
          //     data: {
          //       'demoData[file][0]': image,
          //     }
          //   }, {
          //     onUploadProgress: (progressEvent) => {
          //       // let percentComplete = progressEvent.loaded / progressEvent.total;
          //       // percentComplete = parseInt(percentComplete * 100);
          //       // setPercentage(percentComplete);
          //     },
          //   })
        ]).then((data) => {
          navigate("/");
        }).catch((err) => {
          // TODO: Handle Errors
          navigate("/");
        });
      }
    }
  }, [
    trigger,
    isValid,
    step,
    axiosClient,
    goal,
    loginDataRedux.userID,
    todoItem,
    navigate,
    dateGoal,
    // image
  ]);

  // useEffect(() => {
  //   const widthNumber = calendarRef.current?.getBoundingClientRect().width;
  //   setCalendarWidth(widthNumber || 0);
  // }, []);

  React.useEffect(() => {
    switch (step) {
      case 0 : { setFocus("todoItem"); break; }
      case 1 : { setFocus("goal"); break; }
      default : { break; }
    }
  }, [setFocus, step])


  return (
    <Container
      className="d-flex justify-content-center overflow-y-scroll"
      style={{
        height: "100vh",
      }}
      fluid
    >
      <Row className="w-100">
        <Col
          className="d-flex flex-column text-center align-items-center"
          style={{ margin: "auto" }}
        >
          <Logo
            alt="GrowFrom"
            style={{ width: 90, marginBottom: "48px" }}
            className="d-none d-md-block"
          />
          <FullLogo className="d-block d-md-none mt-3" style={{ width: 180 }} />
    
          {isMobile ? (
            <H5.Light
              className={`mb-3${isMobile ? " mt-4" : ""}`}
            >{`${getGreetings()}, ${
              updatedName === "" ? loginDataRedux.name : updatedName
            }`}</H5.Light>
          ) : (
            <H4.Regular className="mb-3">{`${getGreetings()}, ${
              updatedName === "" ? loginDataRedux.name : updatedName
            }`}</H4.Regular>
          )}
          {step === 0 &&
            (isMobile ? (
              <H3.Regular className="mb-3">
                What's one thing you want to achieve today?
              </H3.Regular>
            ) : (
              <H1.Regular className="mb-3">
                What's one thing you want to achieve today?
              </H1.Regular>
            ))}
          {step === 1 &&
            (isMobile ? (
              <H3.Regular>
                What do you intend to achieve in the near future?
              </H3.Regular>
            ) : (
              <H1.Regular>
                What do you intend to achieve in the near future?
              </H1.Regular>
            ))}

          {step === 2 &&
            (isMobile ? (
              <H3.Regular >
                Choose a background that inspires you and reflects your style.
              </H3.Regular>
            ) : (
              <H1.Regular>
                Choose a background that inspires you and reflects your style.
              </H1.Regular>
            ))}

          <div className="mt-4 w-100">
            <form>
              {step === 0 && (
                <div className="d-flex justify-content-center w-100">
                  <FormInput
                    id="todoItem"
                    type="text"
                    placeholder="Your today plan"
                    {...register("todoItem", {
                      required: "Please Enter your plan",
                    })}
                    error={errors.todoItem}
                    style={{
                      maxWidth: 550,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleVerification();
                      }
                    }}
                  />
                </div>
              )}

              {step === 1 && (
                <>
                  {/* TODO: Input + DayPicker */}
                  <div className="d-flex justify-content-center w-100">
                    <FormInputWithRightElement
                      type="text"
                      placeholder="Your Goal"
                      $paddingright="175px"
                      {...register("goal", {
                        required: "Please Enter your goal",
                      })}
                      error={errors.goal}
                      style={{
                        maxWidth: 550,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleVerification();
                        }
                      }}
                      rightElement={
                        <>
                          <Separator className="me-2" />
                          <CalendarInput
                            ref={calendarRef}
                            label="Pick a Day"
                            value={dateGoal}
                            onChange={(date) => {
                              setDateGoal(date);
                            }}
                          />
                        </>
                      }
                    />
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <Controller
                    control={control}
                    name="bg-image"
                     render={({ field: { value, onChange, ref } }) => (
                      <ImageGallery
                        ref={ref}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </>
              )}

              {step !== 3 && (
                <div className="mt-4 d-flex justify-content-center w-100">
                  <div
                    className="w-100 justify-content-between align-items-center d-flex"
                    style={{ maxWidth: 550 }}
                  >
                    <div style={{ minWidth: 48 }}>
                      {step !== 0 && (
                        <PrevArrow
                          fontSize="24px"
                          onClick={() => {
                            setStep((s) => --s);
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {Array.from({ length: 3 }, (x, i) => {
                        return step === i ? (
                          <Dots key={`dots_${i}`} />
                        ) : (
                          <Dots fill key={`dots_${i}`} />
                        );
                      })}
                    </div>
                    <NextArrow
                      fontSize="24px"
                      onClick={() => {
                        handleVerification();
                      }}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>

          {step > 2 && <Spinner />}

          <Body.MD.Regular style={{ marginTop: 48 }}>
            {step === 0 ? <Link to="/">Skip</Link> : " "}
          </Body.MD.Regular>
        </Col>
      </Row>
    </Container>
  );
};

export default Onboarding;
