import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { styled } from "styled-components";
import { theme } from "../../Theme";
import { CoachMarkContext } from "../../Contexts/coachmark";
import Icon from "./Icon";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";

const StyledModal = styled(Modal)`
  .modal-dialog {
    position: relative;
    color: ${theme.colors.neutral[100]};
    .modal-content {
      background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
      /* bakground blur */
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }

  z-index: 900;
`;

const CloseContainer = styled.div`
  i {
    cursor: pointer;
  }
  position: absolute;
  z-index: 901;
  right: 0;
  top: 0;
  z-index: 100000;
`

const StyledBody = styled(Modal.Body)`
  ${({ scrollhidden }) => scrollhidden === "true" ? 'overflow-y: hidden !important;' : ''}
`

export const ModalWrapper = ({ children, open, handleClose, hideClose, ...rest }) => {
  const { step } = useContext(CoachMarkContext);
  const { isMobile } = useBreakpoint()
  return (
    <StyledModal
      show={open}
      onHide={handleClose}
      fullscreen={true}
      {...rest}
    >
      <StyledBody className={isMobile ? "p-2" : "p-5"} scrollhidden={(step && step > 0 )? step.toString() : "false" }>
        {!hideClose && <CloseContainer className="d-none d-md-block p-4">
          <Icon className="bi bi-x-circle" onClick={handleClose} size={"md"} />
        </CloseContainer>}
        {children}
      </StyledBody>
    </StyledModal>
  );
};
