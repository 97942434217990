import { Col, Container, Row } from "react-bootstrap";

const LoginWrapper = ({children}) => {
  return (
    <Container
      className="d-flex justify-content-center overflow-y-scroll"
      style={{
        height: "100vh",
      }}
      fluid
    >
      <Row className="w-100">
        <Col
          className="d-flex flex-column text-center align-items-center"
          style={{ margin: "auto" }}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginWrapper;
