import { forwardRef, useState } from "react";
import { styled } from "styled-components";
import { colors } from "../../Theme";
import Icon from "./Icon";

const InputWithIcon = styled.div`
  width: 100%;
  padding: 16px;
  text-align: center;

  border-radius: 100px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  /* bakground blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  height: 58px;

  input {
    padding: 0;
    height: 24px;
    width: 100%;
    background: transparent;
    border: 0px;
    text-align: center;
    &::placeholder {
      text-align: center;
      color: ${colors.neutral[200]};
    }
    &:focus-visible {
      outline: none;
    }
    
    &:focus {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        display: none;
        color: transparent;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        display: none;
        color: transparent;

      }
      &:-ms-input-placeholder { /* IE 10+ */
        display: none;
        color: transparent;
      }
      &:-moz-placeholder { /* Firefox 18- */
        display: none;
        color: transparent;
      }
    }
  }

  color: ${colors.neutral[100]};
  display: flex;

  &.focused{
    outline: none;
    border: 1px solid var(--Primary-300, #20B483);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    /* bakground blur */
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--Primary-300, #20B483);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    /* buttons-on-hover */
    box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  i {
    &:hover:not(:disabled){
      color: ${colors.primary[100]}
    }
  }
`;

const PasswordInput = forwardRef(({ error, style, hideError, ...props }, ref) => {
  const [show, setShow] = useState(false);
  const [focused, setFocused] = useState(false)

  return (
    <InputWithIcon style={{ marginBottom: error && !hideError ? -1 : 24, ...style }} className={focused ? 'focused' : ''}>
      <input ref={ref}
        {...props}
        type={show ? "text" : "password"}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Icon
        className={show ? "bi bi-eye" : "bi bi-eye-slash"}
        onClick={() => {
          setShow((s) => !s);
        }}
        size="md"
        style={{ cursor: "pointer" }}
      />
    </InputWithIcon>
  );
});

export default PasswordInput;
