import { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { theme } from "../../Theme";
import { ButtonWithIcons, IconButton } from "./Buttons";
import Icon from "./Icon";
import TimeInput from "./TimeInput";
import styled from "styled-components";

const StyledControllerContainer = styled.div`
  min-width: 280px;

  ${theme.mediaQueries.maxMobile} {
    min-width: 0;
  }
`;

const SetTime = ({
  item,
  editable = false,
  onCancel,
  onConfirm,
  control,
  rootName = "",
  setClear = () => {},
  setValue = () => {},
  displayElement = <></>,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showTimesInput, setShowTimeInput] = useState(false);
  const [disabledEndTime, setDisabledEndTime] = useState(true);

  const startTime = useWatch({
    control,
    name: rootName ? rootName + ".startTime" : "startTime",
  });
  const endTime = useWatch({
    control,
    name: rootName ? rootName + ".endTime" : "endTime",
  });

  useEffect(() => {
    if (setClear) {
      setClear(() => () => {
        setShowTimeInput(false);
        setDisabledEndTime(true);
      });
    }
  }, [setClear]);

  useEffect(() => {
    if (startTime || endTime) {
      setShowTimeInput(true);
      setDisabledEndTime(false);
    }
  }, [startTime, endTime]);

  return (
    <div className="d-flex justify-content-center justify-content-md-between">
      {showTimesInput ? (
        <StyledControllerContainer
          className="d-flex align-items-center justify-content-center p-0 p-md-2"
        >
          <Controller
            control={control}
            name={rootName ? rootName + ".startTime" : "startTime"}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <TimeInput
                name={name}
                label="Start Time"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(rootName ? rootName + ".endTime" : "endTime", e);
                  if (editable) {
                    setEditMode(true);
                  }
                }}
                onBlur={onBlur}
                onClick={() => {
                  setDisabledEndTime(false);
                  setEditMode(true);
                }}
              />
            )}
          />
          <Icon className="bi bi-arrow-right mx-1" size="md" />
          <Controller
            control={control}
            name={rootName ? rootName + ".endTime" : "endTime"}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <TimeInput
                name={name}
                label="End Time"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (editable) {
                    setEditMode(true);
                  }
                }}
                onBlur={onBlur}
                disabled={disabledEndTime}
                onClick={() => {
                  setEditMode(true);
                }}
              />
            )}
          />
        </StyledControllerContainer>
      ) : (
        <div className="d-flex">
          <ButtonWithIcons
            variant={"underlined"}
            onClick={() => {
              setShowTimeInput(true);
              setEditMode(true);
            }}
            rightIcon={
              <Icon
                className="bi bi-clock"
                size={"sm"}
                style={{ color: theme.colors.primary[300] }}
              />
            }
          >
            Set Time
          </ButtonWithIcons>
        </div>
      )}
      {editable && (
        <>
          {!editMode && displayElement}
          {editMode && (
            <div className="d-flex align-items-center">
              <IconButton
                type="button"
                onClick={() => {
                  onCancel();
                  setEditMode(false);
                  if (!item.startTime && !item.endTime) setShowTimeInput(false);
                }}
                variant="cancel"
              >
                <Icon className="bi bi-x-lg" size="sm" />
              </IconButton>
              <IconButton
                type="button"
                onClick={() => {
                  onConfirm();
                  setEditMode(false);
                  if (!item.startTime && !item.endTime) setShowTimeInput(false);
                }}
                className="p-0"
              >
                <Icon className="bi bi-check-lg" size="sm" />
              </IconButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SetTime;
