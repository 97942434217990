import React from "react";
import { styled } from "styled-components";
import { colors, theme } from "../../Theme";
import { Body } from "./Text";

const StyledInput = styled.input`
  width: 100%;
  padding: 16px;
  text-align: center;

  border-radius: 100px;
  border: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  &::placeholder {
    text-align: center;
    color: ${colors.neutral[200]};
  }

  color: ${colors.neutral[100]} !important;

  ${({ $paddingRight }) => $paddingRight ? `padding-right: ${$paddingRight} !important` : 'text-deration: underlined !important'}

  &:focus {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      display: none;
      color: transparent;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      display: none;
      color: transparent;

    }
    &:-ms-input-placeholder { /* IE 10+ */
      display: none;
      color: transparent;
    }
    &:-moz-placeholder { /* Firefox 18- */
      display: none;
      color: transparent;
    }
  }

  &:focus, &:focus-visible{
    outline: none;
    border: 1px solid var(--Primary-300, #20B483);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    
    &::placeholder {
      color: transparent;
    }
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--Primary-300, #20B483);
    background: var(--Neutral-transparent-100---white-5, rgba(255, 255, 255, 0.05));
    /* buttons-on-hover */
    box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
`;

const StyledErrorBox = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth} !important` : '520px'};
  padding: 8px 12px;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'center'};
  align-items: center;
  margin-top: 4px;
  color: ${colors.semantic.negative.normal} !important;
  gap: 8px;

  border-radius: 8px;
  background: var(--semantic-negative-transparent-dark, rgba(140, 45, 45, 0.4));

  i {
    color: ${colors.semantic.negative.normal} !important;
    path: {
      fill: ${colors.semantic.negative.normal} !important;
    }
  }

  ${theme.mediaQueries.minTablet} {
    max-width: 300px;
    ${({ maxWidth }) => maxWidth ? `max-width: ${maxWidth};` : ''}
  }

  ${({ maxWidth }) => maxWidth ? `max-width: ${maxWidth};` : ''}
`;

const StyledSucceededBox = styled.div`
  display: flex;
  margin: 16px 0;
  padding: 8px 12px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  color: ${colors.semantic.positive.normal} !important;
  width: 100%;
  gap: 16px;

  border-radius: 8px;
  background: var(--Semantic-Positive-transparent-dark, rgba(27, 83, 56, 0.40));

  i {
    color: ${colors.semantic.positive.normal} !important;
    path: {
      fill: ${colors.semantic.positive.normal} !important;
    }
  }
`;

const StyledError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;

  color: ${colors.semantic.negative.normal} !important;
  p {
    color: ${colors.semantic.negative.normal} !important;
  }
  i {
    color: ${colors.semantic.negative.normal} !important;
    path: {
      fill: ${colors.semantic.negative.normal} !important;
    }
  }
`;

const FormInput = React.forwardRef(({ error, style, ...props }, ref) => {
  return (
    <div className="w-100" style={{ marginBottom: error ? -1 : 24, ...style }}>
      <StyledInput ref={ref} {...props} />
      {error && (
        <StyledError>
          <Body.SM.Regular style={{ margin: 0 }}>{error.message}</Body.SM.Regular>
        </StyledError>
      )}
    </div>
  );
});

export const ErrorBox = ({ children, ...rest }) => {
  return <StyledErrorBox {...rest}>
    <i className="bi bi-exclamation-circle"></i>
    <Body.SM.Regular style={{ margin: 0 }}>
      {children}
    </Body.SM.Regular>
  </StyledErrorBox>
}

export const SucceededBox = ({ children }) => {
  return <StyledSucceededBox>
    <i className="bi bi-check-lg"></i>
    <Body.SM.Regular style={{ margin: 0 }}>
      {children}
    </Body.SM.Regular>
  </StyledSucceededBox>
}

export const Separator = styled.span`
  border-left: 1px solid ${colors.neutral[300]};
  width: 1px;
  height: 25px;
`

const StyledRightContent = styled.span`
  position: absolute;
  right: 0;
  height: 58px;
  display: flex;
  align-items: center;
`

export const FormInputWithRightElement = React.forwardRef(({ error, style, rightElement, $paddingright = 0, ...props }, ref) => {
  return (
    <div className="w-100" style={{ marginBottom: error ? -1 : 24, ...style }}>
      <div className="d-flex position-relative">
        <StyledInput
          ref={ref}
          {...props}
          style={{ paddingRight: $paddingright }}
        />
        <StyledRightContent>
          {rightElement}
        </StyledRightContent>
      </div>
      {error && (
        <StyledError>
          <Body.SM.Regular style={{ margin: 0 }}>{error.message}</Body.SM.Regular>
        </StyledError>
      )}
    </div>
  );
});

export default FormInput;
