import LogoIcon from "../Images/Logo/Logo.svg";
import LogoColorIcon from "../Images/Logo/LogoColor.svg";

import ISOLogoIcon from "../Images/Logo/ISOLogo.svg";
import ISOLogoColorIcon from "../Images/Logo/ISOLogoColor.svg";

import LogoFullIcon from "../Images/Logo/LogoFull.svg";
import LogoFullColorIcon from "../Images/Logo/LogoFullColor.svg";

import { Image } from "react-bootstrap";

export const Logo = ({ color = false, ...props }) => {
  return <Image src={color ? LogoIcon : LogoColorIcon} alt="GrowFrom" {...props} />;
};

export const IsoLogo = ({ color = false, ...props }) => {
  return <Image src={color ? ISOLogoIcon : ISOLogoColorIcon} alt="GrowFrom" {...props} />;
};

export const FullLogo = ({ color = false, ...props }) => {
  return <Image src={color ? LogoFullIcon : LogoFullColorIcon} alt="GrowFrom" {...props} />;
};
