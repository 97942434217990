import { useState } from "react";
import { theme } from "../../Theme";
import { FooterItem } from "../Commons/Hamburger";
import Icon from "../Commons/Icon";
import { ModalWrapper } from "../Commons/ModalWrapper";
import { LogoutModal } from "./LogoutModal";

const Logout = () => {
  const [open, setOpen] = useState(false);

  return (<>
    <ModalWrapper
      open={open}
      handleClose={() => setOpen(false)}
      hideClose
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <LogoutModal setOpenModal={setOpen} />
    </ModalWrapper>
    <FooterItem
      label="Logout"
      icon={<Icon className="bi bi-box-arrow-left" size={"lg"} />}
      mirror
      style={{ color: theme.colors.neutral.transparent.white50 }}
      onClick={
        () => setOpen(true)
      }
    />
  </>
  );
};

export default Logout;
