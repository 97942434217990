import { memo, useCallback } from "react"
import { LogoutModalButtons, LogoutModalClose, LogoutModalContainer, LogoutModalContent } from "./styles"
import Icon from "../Commons/Icon"
import { Body, H4 } from "../Commons/Text"
import { ButtonWithIcons } from "../Commons/Buttons"
import { useLogout } from "../../OurCustomHooks/useLogout"

const LogoutModalComponent = ({ setOpenModal }) => {
  const logoutFn = useLogout()
  const handleClose = useCallback(() => {
    setOpenModal?.(false)
  }, [setOpenModal])

  return <div className="d-flex justify-content-center align-items-center h-100">
    <LogoutModalContainer>
      <LogoutModalClose>
        <div role="button" aria-hidden>
          <Icon className="bi bi-x-circle fs-4" size="lg" onClick={handleClose} />
        </div>
      </LogoutModalClose>

      <LogoutModalContent>
        <H4.Regular className="text-center">
          Are you sure you want to log out?
        </H4.Regular>

        <Body.MD.Regular>
          Logging out will end your current session. You'll need to log in again to access your account.
        </Body.MD.Regular>
      </LogoutModalContent>
      <LogoutModalButtons>
        <ButtonWithIcons variant="underlined" onClick={() => setOpenModal(false)}>Cancel</ButtonWithIcons>
        <ButtonWithIcons variant="solid" onClick={() => { logoutFn() }}>Yes, Logout</ButtonWithIcons>
      </LogoutModalButtons>
    </LogoutModalContainer>
  </div>
}

export const LogoutModal = memo(LogoutModalComponent)