import React, { useState, useEffect, useCallback, forwardRef, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import useAxios from "../../OurCustomHooks/useAxios";
import { styled } from "styled-components";
import { Body, H5 } from "../Commons/Text";
import AddInput from "../Commons/AddInput";
import StickyReminder from "./StickyReminder";
import { HoverStickyCss } from "./styles";
import { StyledCoachmark } from "../Dashboard/styles";
import { CoachMarkContext } from "../../Contexts/coachmark";
import { Coachmarks } from "../Coachmarks";
import { useCoachmarkRefresh } from "../../OurCustomHooks/useCoachmarkRefresh";
import { DailyItemContext } from "../../Contexts/DailyItemContext";

export const StyledEmpty = styled.div`
  margin-top: 90px;
`

export const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  overflow-y: auto;
  height: 255px;
`;

export const StickeyReminderContainer = styled.div`
  height: 380px;
  border-radius: 12px;
  border: 1px solid var(--widgets-border-hover-sticky-reminders, #39301e);
  background: var(
    --gradient-red-widgets,
    linear-gradient(
      180deg,
      rgba(170, 72, 17, 0.1) 0%,
      rgba(170, 72, 17, 0.01) 100%
    )
  );
  padding: var(--sizes-16, 16px);

  ${HoverStickyCss}
`;

const ContactRadarLocal = forwardRef((_, ref6) => {
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const dateFromRedux = useSelector((state) => state.login.dateFromPastLog);
  const [reminders, setReminders] = useState([]);

  const { currentDate } = useContext(DailyItemContext);

  const axiosClient = useAxios();

  const { control, reset, handleSubmit } = useForm();
  const { coachmarkProps } = useContext(CoachMarkContext)


  const getContactRadar = useCallback(() => {
    axiosClient
      .get(
        process.env.REACT_APP_API_BASE_URL +
        "/contactradar" +
        "/" +
        loginDataRedux.userID
      )
      .then((response) => {
        setReminders(
          response.data
            .filter((data) => {
              if (data.contact_name?.length > 0) {
                return data.checkedOffDate && new Date(data.checkedOffDate).toDateString() === new Date(currentDate).toDateString()
                  ? true
                  : !data.isCheckedOff;
              }
              return false;
            })
            .sort((x, y) => {
              return x.isCheckedOff === y.isCheckedOff
                ? 0
                : x.isCheckedOff
                  ? 1
                  : -1;
            })
        );
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  }, [axiosClient, loginDataRedux.userID, currentDate]);

  const addNewContact = useCallback(
    (data) => {
      if (data.contactName.length > 0) {
        axiosClient({
          method: "post",
          url: process.env.REACT_APP_API_BASE_URL + "/contactradar",
          data: {
            contactRadar: data.contactName,
            userId: loginDataRedux.userID,
            todayDate: dateFromRedux,
          },
        })
          .then((response) => {
            if (response.data.msg === "_success") {
              // Swal.fire({
              //   icon: "success",
              //   title: "Successfully Added",
              //   text: "One Contact is  successfully added to the list",
              // });

              // TODO: Add a toast here
              reset();
              getContactRadar();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [axiosClient, dateFromRedux, getContactRadar, loginDataRedux.userID, reset]
  );

  useEffect(() => {
    getContactRadar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const rerender = useCoachmarkRefresh('sticky', ref6)


  return (
    <>
      {coachmarkProps.sticky.visible && rerender > 0 &&
        <StyledCoachmark
          activate={coachmarkProps.sticky.visible}
          component={<Coachmarks
            currentStep={6}
            handleNext={() => {
              coachmarkProps.sticky?.handleNext?.()
            }}
            handlePrev={() => {
              coachmarkProps.sticky.handlePrev()
            }}
          />}
          reference={ref6}
          tooltip={coachmarkProps.sticky.tooltip}
        />
      }
      <StickeyReminderContainer className="mt-sm-2 parent-hovered" ref={ref6}>
        <div className="d-flex justify-content-center justify-content-md-start overflow-hidden position-relative">
          <Body.SM.Regular className="d-block d-md-none" 
          style={{
            position: "absolute",
            left: -55 , 
            opacity: '0.5'
          }}>Activity Planner</Body.SM.Regular>
          <H5.Regular>Sticky Reminders</H5.Regular>
          <Body.SM.Regular className="d-block d-md-none" 
          style={{
            position: "absolute",
            right: -10, 
            opacity: '0.5'
          }}>Habits</Body.SM.Regular>
        </div>
        <form onSubmit={handleSubmit(addNewContact)}>
          <Controller
            control={control}
            name="contactName"
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <AddInput
                placeholder="Add a reminder..."
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                $placeholdercenter={true}
              />
            )}
          />
        </form>

        <StyledContactContainer>
          {reminders.length > 0 ? reminders.map((reminder, index) => {
            return (
              <StickyReminder
                reminder={reminder}
                key={index}
                refresh={() => {
                  getContactRadar();
                }}
                index={index}
                date={currentDate}
              />
            );
          }) : <StyledEmpty>
            <Body.MD.Light>It looks like you haven't added any sticky reminders. </Body.MD.Light>
          </StyledEmpty>}
        </StyledContactContainer>
      </StickeyReminderContainer>
    </>
  );
});

export default ContactRadarLocal;
