import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/LoginPageState";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { H1, Body, H2 } from "../Commons/Text";
import FormInput, { ErrorBox } from "../Commons/FormInput";
import PasswordInput from "../Commons/PasswordInput";
import { FullLogo } from "../Commons/Brand";
import { ButtonWithIcons } from "../Commons/Buttons";
import useAxios from "../../OurCustomHooks/useAxios";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";

const ENABLE_APPLICATION_INSIGHTS = window.location.hostname !== "localhost";

const LoginForm = () => {
  const navigate = useNavigate();
  const axiosClient = useAxios();
  const { isMobile } = useBreakpoint()
  // This is for tracking
  const appInsights = useAppInsightsContext();

  if (ENABLE_APPLICATION_INSIGHTS) {
    const metricData = {
      average: 60,
      name: "React Component Engaged Time (seconds)",
      sampleCount: 1,
    };
    const additionalProperties = { "Component Name": "LoginForm" };
    appInsights.trackMetric(metricData, additionalProperties);
  }

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus
  } = useForm();

  const loginFormData = (data) => {
    localStorage.removeItem("user_role");
    setLoading(true)
    setInvalidCredentials(false)
    axiosClient({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "/manualLogin",
      data: {
        // name: data.Name,
        email: data.email.toLowerCase(),
        password: data.password,
      },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.msg === "_successfully_login") {
          localStorage.setItem("manualLogin", true);
          dispatch(loginActions.myLoginState(true));
          localStorage.setItem("api_token", response.data.token);
          localStorage.setItem("user_role", response.data.res.role);
          localStorage.setItem(
            "manualLoginInfo",
            JSON.stringify(response.data.res)
          );
          dispatch(loginActions.myLoginInfo(response.data.res));
          navigate("/");
          Swal.close();
        } else if (response.data.error === "invalid email and password!") {
          setInvalidCredentials(true)
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("Error is ", err);
      });
  };

  // useEffect(() => {
  //   setFocus("email")
  // }, [setFocus]);

  return (
    <>
      {isMobile
        ? <H2.Light className="mb-5">Welcome to</H2.Light>
        : <H1.Regular>Welcome to</H1.Regular>}
      <FullLogo style={{ marginBottom: 40, width: "252px" }} />
      <form onSubmit={handleSubmit(loginFormData)} className="w-100">
        <div className="d-flex flex-column align-items-center">
          <FormInput
            ref={register}
            type="text"
            autoComplete="username"
            placeholder="Your Email"
            {...register("email", {
              required: "Please enter a valid email address",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email address'
              }
            })}
            error={errors.email}
            style={{
              maxWidth: 550,
            }}
          />
          <PasswordInput
            ref={register}
            type="password"
            placeholder="Your Password"
            autoComplete="password"
            {...register("password", { required: "Please enter a Password" })}
            error={errors.password}
            style={{
              maxWidth: 550,
            }}
          />

          <Body.MD.Regular>
            <Link to="/forgot-password">Forgot password?</Link>
          </Body.MD.Regular>

          {invalidCredentials && <ErrorBox className="my-4 d-flex justify-content-start" style={{ maxWidth: "max-content" }}>
            Login failed. Please check your credentials and try again.
          </ErrorBox>}

          <ButtonWithIcons type="submit" className="my-2" loading={loading} minwidth={155}>
            Login
          </ButtonWithIcons>
        </div>
      </form >
      <Body.MD.Regular style={{ marginTop: 24 }}>
        Create a{" "}
        <Link to="/" exact="true">
          New Account
        </Link>
      </Body.MD.Regular>
    </>
  );
};

export default LoginForm;
