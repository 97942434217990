import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import './Component/Css/modal.css'

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import Login from './Component/Login/Login';

import { colors, fontNames, theme } from './Theme';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainerGeneral } from './Component/Toast/styles';

const StyledBaseApp = styled.div`
  * {
    font-family: ${fontNames.inter};
    color: ${colors.neutral[100]};
  }
`;


const ENABLE_APPLICATION_INSIGHTS = window.location.hostname !== 'localhost';


const App = () => {
  let reactPlugin = null;
  let appInsights = null;
  const appContainer = (
    <StyledBaseApp id="app">
      <ThemeProvider theme={theme}>
        <Login />
        <ToastContainerGeneral />
      </ThemeProvider>
    </StyledBaseApp>
  );

  if (ENABLE_APPLICATION_INSIGHTS) {
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        // extensionConfig: {
        //   [reactPlugin.identifier]: {history: browserHistory}
        // },
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
      }
    });

    try {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    } catch (error) {
      console.error('failed to load app insight', error);
    }
  }



  return (
    ENABLE_APPLICATION_INSIGHTS ? (
      <AppInsightsContext.Provider value={reactPlugin}>
        {appContainer}
      </AppInsightsContext.Provider>
    ) : appContainer
  );
}

export default App;
