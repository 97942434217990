import { CoachMark } from "react-coach-mark";
import styled from "styled-components";
import { colors, theme } from "../../Theme";
import { Container } from "react-bootstrap";

export const AbsoluteDivCoachmark = styled.div`
  position: absolute;
  width: 50%;
  height: 1%;
  
  ${theme.mediaQueries.minTablet} {
    width: calc(50% - 200px);
    height: 50%;
    ${({ $big }) => $big ? `
      height: 10%;
      width: calc(50% - 300px);
    ` : ''}
  };
  ${theme.mediaQueries.minSmallDesktop} {
    width: calc(50% - 200px);
    height: 50%;
    ${({ $big }) => $big ? `
      height: 25%;
      width: calc(50% - 300px);
    ` : ''}
  };

`

export const TransparentDiv = styled.div`
  background-color: transparent;
`

export const StyledCoachmark = styled(CoachMark)`
  .hcm-tooltip-base {
    background-color: ${colors.neutral.transparent.white20} !important;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px) !important;
  }
`

export const LogoutModalContainer = styled.div`
  display: flex;
  width: 440px;
  min-width: 343px;
  max-width: 440px;
  min-height: 300px;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
  border: 1px solid ${colors.primary[600]};
  background: ${colors.primary[700]};

  /* bakground blur */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
`

export const LogoutModalClose = styled.div`
  display: flex;
  padding-top: var(--sizes-4, 4px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--sizes-8, 8px);
  align-self: stretch;

  color: ${colors.primary[500]};
`

export const LogoutModalContent = styled.div`
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`

export const LogoutModalButtons = styled.div`
  display: flex;
  padding: 32px 0px 12px 0px;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
`

export const StyledFooterContainer = styled.div`
  ${theme.mediaQueries.maxMobile} {
    display: none !important;
  }
`

export const StyledHeaderContainer = styled(Container)`
  height: 10%;
`
export const StyledHomeContainer = styled.div`
  height: ${({ $isiphone }) => !$isiphone ? '-webkit-fill-available !important' : '100vh'};
`

export const MobileFooter = styled.div`
  z-index: 1000000000;

  ${theme.mediaQueries.maxMobile} {
    justify-content: center;
    align-items: center;
    background-color: ${colors.neutral[700]};
    z-index: 100;
  }
`

export const LogoContainer = styled.div`
  display: block;
  padding-top: 0;

  ${theme.mediaQueries.maxMobile} {
    display: flex;
    width: 100%;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`