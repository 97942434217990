import { ButtonWithIcons } from "../Commons/Buttons";

const TermsConditions = ({onConfirm, onClose}) => {

  return (
    <div>
      <div className="inner-container">
        <h1 className="entry-title mb-5">Terms and Conditions</h1>

        <section className="content-editor">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="758"
            className="elementor elementor-758"
            data-elementor-post-type="page"
          >
            <div
              className="elementor-element elementor-element-ea289a4 e-flex e-con-boxed e-con e-parent"
              data-id="ea289a4"
              data-element_type="container"
              data-settings='{"content_width":"boxed"}'
              data-core-v316-plus="true"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-82e8c46 elementor-widget elementor-widget-heading"
                  data-id="82e8c46"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2>
                      Our Promise to Each other (AKA Terms and Conditions) ​
                    </h2>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-f9697e3 elementor-widget elementor-widget-text-editor"
                  data-id="f9697e3"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      This page will provide you with an understanding of what
                      you can expect from GrowFrom Inc. and the rules by which
                      we govern ourselves and are governed by. They also outline
                      how we expect you to engage with us in the event we fall
                      short of your expectations.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Our Promise of Availability</h3>
                    <p>
                      We will do our best to make our app available as much as
                      possible and communicate when we may have maintenance. We
                      currently have no specified up-time guarantees.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Our Care of Your Data</h3>
                    <p>
                      Please see our privacy policy for details on how we make
                      sure your data is secured.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Disputes with Resources Provided</h3>
                    <p>
                      We do not make any guarantees about the effectiveness of a
                      resource you may engage with through GrowFrom Inc.
                    </p>
                    <p>
                      If you find yourself in a dispute with a resource you
                      found on GrowFrom, you are welcome to make us aware of the
                      dispute and we will take seriously whether we want to
                      continue supporting that resource.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Protection of our Resources</h3>
                    <p>
                      We reserve the right to ban any account that utilizes our
                      platform in a malicious or self-serving way. This may
                      include but is not limited to, harassment of our coaches,
                      data scraping, or spamming.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Settling Disputes Professionally</h3>
                    <p>
                      In the event you have a dispute with GrowFrom Inc. which
                      cannot be resolved through normal engagements. We will
                      provide a mediator at no cost to you to help resolve it.
                      We are located in, and subject to, the laws of Broward
                      County Florida.
                    </p>
                    <h3>&nbsp;</h3>
                    <h3>Changes to Terms</h3>
                    <p>
                      As we grow, our Terms and Conditions will change too. If
                      the changes are substantive we will let you know with a
                      notification within our app or an email or text message
                      (as allowed by your permissions).
                    </p>
                    <p>
                      If you have any questions or concerns that would prohibit
                      you from signing up or simply would like more information,
                      please feel free to contact us at&nbsp;
                      <a
                          href="mailto:info@growfrom.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@growfrom.com
                      </a>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 justify-content-center direction-row d-flex mt-3" style={{ gap: 10 }} >
              <ButtonWithIcons
                type="button"
                variant="underlined"
                classNameName="btn btn-primary btn-lg btn-block"
                onClick={onConfirm}
              >
                <span>Cancel</span>
              </ButtonWithIcons>
              <ButtonWithIcons
                type="button"
                variant="solid"
                classNameName="btn btn-primary btn-lg btn-block"
                onClick={onConfirm}
              >
                <span>Accept</span>
              </ButtonWithIcons>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default TermsConditions;
