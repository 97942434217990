import React, { useState } from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { ImageContainer } from "./Gallery";
import { Body, FootNotes } from "./Text";
import { theme } from "../../Theme";

const UploadContainer = styled.div`
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: var(--primary-700, #032e20);
  }

  width: 100%;
  height: 100%;
  max-width: 130px;
  max-height: 160px;
  border-radius: 10px;
  background: var(--primary-600, #053e2b);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 8px;
`;

const UploadImageButton = styled.label`
  cursor: pointer;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
  i {
    font-size: 36px;
  }
`;

const UploadImage = React.forwardRef(({ value, onChange }, ref) => {
  const [temp, setTemp] = useState();

  return temp?.target?.files[0] ? (
    !value ? (
      <UploadContainer
        className="d-flex justify-content-center"
        style={{
          border: `1px solid ${theme.colors.semantic.negative.normal}`,
          backgroundColor: theme.colors.semantic.negative.transparent.dark,
        }}
      >
        <UploadImageButton>
          <input
            ref={ref}
            type="file"
            onChange={(e) => {
              setTemp(e);
              onChange(e);
            }}
            accept="image/*"
          />
          <i className="bi bi-image"></i>
          <Body.SM.Regular
            className="mb-1 mt-1"
            style={{
              color: theme.colors.semantic.negative.normal,
            }}
          >
            Oops! Something went wrong
          </Body.SM.Regular>
          <Body.SM.Regular
            style={{
              color: theme.colors.semantic.negative.normal,
            }}
          >
            Please try again.
          </Body.SM.Regular>
        </UploadImageButton>
      </UploadContainer>
    ) : (
      <ImageContainer onClick={() => onChange(temp)} key={`tempimg`}>
        <Image
          src={value}
          alt={`temp-bg`}
          className="rounded"
          width={130}
          height={160}
          style={{ objectFit: "cover" }}
        />
      </ImageContainer>
    )
  ) : (
    <UploadContainer className="d-flex justify-content-center">
      <UploadImageButton>
        <input
          ref={ref}
          type="file"
          onChange={(e) => {
            setTemp(e);
            onChange(e);
          }}
          accept="image/*"
        />
        <i className="bi bi-image"></i>
        <Body.SM.Regular className="mb-1 mt-1">Custom Image</Body.SM.Regular>
        <FootNotes>Min. 1920 x 1080px</FootNotes>
        <FootNotes>JPEG or PNG</FootNotes>
        <FootNotes>Max. Size 5MB</FootNotes>
      </UploadImageButton>
    </UploadContainer>
  );
});

export default UploadImage;
