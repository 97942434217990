import { toast } from 'react-toastify';

// Components
import { Toast } from '../Component/Toast';

// CSS
import 'react-toastify/dist/ReactToastify.css';

const defaultToastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  closeButton: false,
  style: {
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    background: 'transparent',
  },
};

export function customToast(message, type, undo = false) {
  toast(<Toast message={message} type={type} undo={undo} />, defaultToastOptions)
}
