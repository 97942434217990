import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginActions } from "../Component/Store/LoginPageState";
import { colorThemeActions } from "../Component/Store/ColorThemeState";
import { useCallback } from "react";

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const loginDataRedux = useSelector((state) => state.login.loginInfo);


  const logoutFn = useCallback(() => {
    localStorage.removeItem("fblst_" + process.env.REACT_APP_FB_APP_ID);
    sessionStorage.removeItem("fbssls_" + process.env.REACT_APP_FB_APP_ID);

    if (loginDataRedux.graphDomain === "facebook") {
      window.FB.api("/me/permissions", "delete", null, () =>
        window.FB.logout()
      );
      localStorage.setItem("fbLogin", false);
      localStorage.removeItem("bgImage");
    }

    localStorage.removeItem("bgImage");
    localStorage.removeItem("user_role");
    dispatch(loginActions.myLoginState(false));
    localStorage.setItem("manualLogin", false);
    localStorage.removeItem("manualLoginInfo");

    dispatch(colorThemeActions.mySelectedImage(""));
    history("/", { replace: true });
    history("/", { replace: true });
  }, [dispatch, history, loginDataRedux.graphDomain])

  return logoutFn
}