import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/LoginPageState";
import { FullLogo } from "../Commons/Brand";
import { Body, H1 } from "../Commons/Text";
import { ButtonWithIcons } from "../Commons/Buttons";
import useAxios from "../../OurCustomHooks/useAxios";
import PasswordInput from "../Commons/PasswordInput";
import { OverlayTrigger } from "react-bootstrap";
import { PasswordTooltip } from "./PasswordTooltip";

const ResetPasswordForm = () => {
  const axiosClient = useAxios();

  const dispatch = useDispatch();
  const history = useNavigate();

  const location = window.location
  const { search } = location
  const searchParams = new URLSearchParams(search.trim());
  const secureToken = searchParams.get("token");
  const secureId = searchParams.get("id");

  const [loadingCheck, setLoadingCheck] = useState(false)

  console.log(secureToken);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [pass, setPass] = useState();

  const resetPassword = useCallback(() => {
    axiosClient({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/login/reset_password`,
      data: {
        secureToken: secureToken,
        _id: secureId,
      },
    })
      .then((response) => {
        if (response.data.msg === "Token_Matched") {
          setShow(true);
          // Swal.close();
        } else {
          // Swal.fire({
          //   title: "Token Expired",
          //   text: "Your current token is Expired, re-send an email.",
          //   icon: "warning",
          //   confirmButtonText: "OK",
          // });
          setShow(false);
          // Swal.close();
        }
      })
      .catch((err) => {
        console.log("Error is ", err);
      }).finally(() => {
        setLoadingCheck(true);
      });
  }, [axiosClient, secureToken, secureId]);

  useEffect(() => {
    if (secureToken) {
      resetPassword();
    }
  }, [resetPassword, secureToken]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    mode: "onTouched",
  });

  const setPassFn = useCallback((e) => {
    setValue("password", e.target.value)
    setPass(e.target.value)
  }, [setValue])

  // reset password function on submit
  const resetPasswordSubmit = (data) => {
    setLoading(true)
    axiosClient({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/login/change_password`,
      data: {
        password: data.password,
        _id: secureId,
      },
    })
      .then((response) => {
        setLoading(false)
        if (
          response.data.msg === "Password_changed_successfully" &&
          response.data.isExtension === false
        ) {
          axiosClient({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/manualLogin",
            data: {
              email: response.data.email,
              password: data.password,
            },
          })
            .then((response) => {
              if (response.data.msg === "_successfully_login") {
                localStorage.setItem("manualLogin", true);
                dispatch(loginActions.myLoginState(true));
                localStorage.setItem("api_token", response.data.token);
                localStorage.setItem(
                  "manualLoginInfo",
                  JSON.stringify(response.data.res)
                );
                dispatch(loginActions.myLoginInfo(response.data.res));
                history("/", { replace: true });
              }
            })
            .catch((err) => {
              console.log("Error is ", err);
            });
        } else if (response.data.isExtension === true) {
          history("/", { replace: true });
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("Error is ", err);
      });
    reset();
  };

  return (
    <>
      <FullLogo />
      {show ? (
        <>
          <H1.Light style={{ marginTop: 40 }}>Reset Password</H1.Light>
          <Body.MD.Regular style={{ marginBottom: "10%" }}>
            To ensure the security of your account, please create a new password that meets our security requirements.
          </Body.MD.Regular>
          <form onSubmit={handleSubmit(resetPasswordSubmit)} className="w-100">
            <div className="d-flex flex-column align-items-center">
              <OverlayTrigger
                placement="top"
                overlay={<div><PasswordTooltip pass={pass} /></div>}
                flip
                trigger={['focus', 'hover', 'click']}>
                <PasswordInput
                  style={{
                    maxWidth: 550,
                  }}
                  ref={register}
                  type="password"
                  placeholder="New password"
                  {...register("password", {
                    required: "Please confirm your Password",
                    validate: {
                      hasUpper: (value) => /[A-Z]/.test(value) || 'No Has Upper',
                      hasLower: (value) => /[a-z]/.test(value) || 'No Has Lower',
                      digits: (value) => (value && /\d/.test(value)) || 'No Has Digit'
                    },
                    minLength: {
                      value: 8,
                      message: "Please enter 8 character minimum",
                    },
                    onChange: setPassFn
                  })}
                  error={errors.password}
                  onChange={setPassFn}
                />
              </OverlayTrigger>
              <ButtonWithIcons type="submit" className="mt-4" disabled={loading} loading={loading}>
                Save New Password
              </ButtonWithIcons>
            </div>
          </form>
          <Body.MD.Regular style={{ marginTop: 48 }}>
            Already have an account? <Link to="/login">Login</Link>
          </Body.MD.Regular>
          <Body.MD.Regular>
            Create a{" "}
            <Link to="/" exact="true">
              New Account
            </Link>
          </Body.MD.Regular>
        </>
      ) : (
        loadingCheck && (<>
          <Body.MD.Regular style={{ marginTop: 40 }}>
            {`Sorry... your token is Expired :(`}
          </Body.MD.Regular>
          <Body.MD.Regular>
            <Link to="/forgot-password"> Resend an Email </Link>
          </Body.MD.Regular>
        </>)
      )}
    </>
  );
};

export default ResetPasswordForm;
