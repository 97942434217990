import {
  LogoutModalContainer,
  LogoutModalContent,
} from "./styles";
import { Body, H5 } from "../Commons/Text";
import { Image } from "react-bootstrap";

import Extension from "../Images/Ads/Extension.png";
import Chrome from "../Images/Ads/Chrome.svg";
import FF from "../Images/Ads/FF.svg";
import Safari from "../Images/Ads/Safari.svg";
import Edge from "../Images/Ads/Edge.svg";
import { theme } from "../../Theme";

const AdsExtension = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <LogoutModalContainer>
        <LogoutModalContent>
          <H5.Regular className="text-center">
            Supercharge Your Focus
          </H5.Regular>

          <Body.MD.Regular className="m-0">
            Make GrowFrom your default page when opening a new tab with our
            browser extension.
          </Body.MD.Regular>

          <div className="d-flex justify-content-center" style={{ gap: 20 }}>
            <Image src={Extension} alt="qr-code" width={390} height={250} />
          </div>

          <div className="d-flex justify-content-between align-items-end" style={{ gap: 20 }}>
            <Image src={Chrome} alt="qr-code" width={55} height={55} style={{marginBottom: 14, cursor: "pointer"}} onClick={()=>{
              window.open("https://chromewebstore.google.com/detail/growfrom-extension/igoameemmefhgphmhbhahfjikblninhf", "_blank")
            }}/>
            <Image src={Edge} alt="qr-code" width={60} height={60} style={{marginBottom: 14, cursor: "pointer"}} onClick={()=>{
              window.open("https://microsoftedge.microsoft.com/addons/detail/growfrom-extension/aajeoffgddahemcckkdioampadfbbifk", "_blank")
            }}/>
            <Image src={Safari} alt="qr-code" width={60} height={60} style={{marginBottom: 14, cursor: "pointer"}} onClick={()=>{
              window.open("https://apps.apple.com/us/app/growfrom/id6443436706", "_blank")
            }}/>
            <div style={{
              borderRadius: 8,
              padding: "12px 24px",
              backgroundColor: theme.colors.primary.transparent[600],
            }}>
              <Body.SM.Regular className="m-0">Coming Soon</Body.SM.Regular>
              <div className="d-flex justify-content-center" style={{gap: 10}}>

                <Image src={FF} alt="qr-code" width={60} height={60} />
              </div>
            </div>
          </div>
        </LogoutModalContent>
      </LogoutModalContainer>
    </div>
  );
};

export default AdsExtension;
