import { memo, useContext, useMemo } from "react"
import { CoachmarkContainer, CoachmarkContent, CoachmarkSkip, PaginatorContainer } from "./styles"
import { Body } from "../Commons/Text"
import { Dots } from "../Commons/Dots"
import { ButtonWithIcons, NextArrow, PrevArrow } from "../Commons/Buttons"
import { coachmarksArray } from "../../utils/coachmarks"
// import AndroidQR from '../../Assets/images/androidQR.png'
// import AndroidDownload from '../../Assets/images/androidDownload.png'
// import AppleQR from '../../Assets/images/appleQR.png'
// import AppleDownload from '../../Assets/images/appleDownload.png'

// Hooks
import { useSkipCoachmarks } from "../../OurCustomHooks/useSkipCoachmarks"
import Icon from "../Commons/Icon"
import { CoachMarkContext } from "../../Contexts/coachmark"

// const extensionURL = 'https://chrome.google.com/webstore/detail/growfrom-extension/igoameemmefhgphmhbhahfjikblninhf'

const CoachmarksComponent = ({ handleNext, handlePrev }) => {
  const { step: currentStep, setStep } = useContext(CoachMarkContext)
  const handleSkip = useSkipCoachmarks(false)
  const currentCoachmark = coachmarksArray.find(x => x.step === currentStep)

  const skipOrCloseComponent = useMemo(() => {
    if (currentCoachmark.finalStep) {
      return <Icon className="bi bi-x-circle fs-4" size={"sm"} onClick={()=>{
        handleSkip();
        setStep(0);
      }} />
    }

    return <ButtonWithIcons variant="underlined" $minwidth="fit-content"  onClick={()=>{
      handleSkip();
      setStep(0);
    }}
    >
      Skip
    </ButtonWithIcons>
  }, [currentCoachmark.finalStep, handleSkip, setStep])

  return <CoachmarkContainer $laststep={currentStep === 10 ? "true" : "false"}>
    <CoachmarkSkip>
      {skipOrCloseComponent}
    </CoachmarkSkip>
    <CoachmarkContent>
      <Body.LG.Light>
        {currentCoachmark.title}
      </Body.LG.Light>
      <Body.MD.Light>
        {currentCoachmark.description}
      </Body.MD.Light>
    </CoachmarkContent>
    {!currentCoachmark.finalStep
      ? <PaginatorContainer>
        {currentStep > 1 && <PrevArrow
          fontSize={24}
          onClick={handlePrev}
        />}
        <div className="d-flex">
          {coachmarksArray.map((x, i) => {
            if (x.step === currentStep) {
              return <Dots key={`dots_${i}`} $small="true" style={{ margin: currentStep > 4 ? "0 4px" : "0 7px"}} />
            }

            return <Dots fill key={`dots_${i}`} $small="true" style={{ margin: currentStep > 4 ? "0 4px" : "0 7px"}} />
          })}
        </div>
        {currentStep !== coachmarksArray.length && <NextArrow
          fontSize={24}
          onClick={handleNext}
        />}
      </PaginatorContainer>
      :
      <>
       {/*  <div className="d-flex justify-content-center w-100 mt-3">
          <ButtonWithIcons onClick={() => window.location.replace(extensionURL)}>
            Add Browser extension
          </ButtonWithIcons> 
        </div>
        <ImagesContainer className="mt-3">
          <ImageContainer>
            <img src={AndroidQR} alt="AndroidQR" />
            <img src={AndroidDownload} alt="AndroidDownload" />
          </ImageContainer>
          <ImageContainer>
            <img src={AppleQR} alt="AppleQR" />
            <img src={AppleDownload} alt="AppleDownload" />
          </ImageContainer>
        </ImagesContainer> */}

      </>
    }
  </CoachmarkContainer>
}

export const Coachmarks = memo(CoachmarksComponent)
