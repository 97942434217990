import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import Icon from "./Icon";
import { theme } from "../../Theme";

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    background-color: ${theme.colors.primary[500]};
    color: ${theme.colors.neutral[100]} !important;

    .dropdown-item {
      color: ${theme.colors.neutral[100]} !important;

      &:hover {
        background-color: ${theme.colors.primary[400]};
      }
    }
  }

  button {
    color: ${theme.colors.neutral[100]} !important;

    background-color: transparent !important;
    border: 0px;
    border-radius: 4px;
    
    &:hover {
      background-color: ${theme.colors.primary[400]};
      border: 1px solid ${theme.colors.primary[200]} !important;
    }
  }
  
  .custom-dropdown-menu {
    --bs-dropdown-min-width: 100px; /* Set the width as per your requirement */
  }
`;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <Icon
      className="ps-2 bi bi-clock"
      size={"sm"}
      style={{ color: theme.colors.primary[300] }}
    />
  </button>
));

const TimeDropdown = ({ onChange, value, label = "Set Time" }) => {
  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      let hours = i;
      if (hours >= 12) {
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12;
      }
      const minutes = j === 0 ? "00" : j;
      const period = i < 12 ? "AM" : "PM";
      times.push(`${hours < 10 ? `0${hours}`: hours}:${minutes} ${period}`);
    }
  }

  const handleSelect = (e) => {
    onChange(e);
  };

  return (
    <StyledDropdown
      className="custom-dropdown-menu"
      id="dropdown-basic-button"
      title={value}
      onSelect={handleSelect}
    >
      <StyledDropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {value || label}
      </StyledDropdown.Toggle>
      <StyledDropdown.Menu className="custom-dropdown-menu">
        {times.map((time, index) => (
          <StyledDropdown.Item key={index} eventKey={time}>
            {time}
          </StyledDropdown.Item>
        ))}
      </StyledDropdown.Menu>
    </StyledDropdown>
  );
};

export default TimeDropdown;
