import React, { memo, useMemo } from 'react';

// Assests
import { ReactComponent as Check } from '../../Assets/Icons/check.svg';
import { ReactComponent as Error } from '../../Assets/Icons/error.svg';
import { ReactComponent as Warning } from '../../Assets/Icons/warning.svg';

// Styles
import {
  StyledContent, StyledIcon, StyledToastContainer,
} from './styles';
import Icon from '../Commons/Icon';
import { colors } from '../../Theme';

function ToastComponent({
  type = 'error',
  message,
  // undo = false,
}) {
  // Memos
  const toastIcon = useMemo(() => {
    switch (type) {
      case 'error':
        return <Error />;
      case 'warning':
        return <Warning />;
      case 'info':
        return null;
      default:
        return <Check />;
    }
  }, [type]);

  return (
    <StyledToastContainer type={type}>
      <div className='d-flex w-100 justify-content-between'>
        <StyledContent>
          <StyledIcon>
            {toastIcon}
          </StyledIcon>
          {message}
        </StyledContent>
        <Icon className="bi bi-x" size="lg" color={`${colors.neutral[700]}`} />
      </div>
    </StyledToastContainer>
  );
}

export const Toast = memo(ToastComponent);
