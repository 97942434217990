import styled from "styled-components";

import { colors, theme } from '../../Theme/theme'

export const ModalContent = styled.div`
  display: flex;
  width: 544px;
  padding: 48px;
  flex-direction: column;
  align-items: center;
  gap: 63px;

  border-radius: 12px;
  /* background: ${colors.neutral.transparent.white20}; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(20.5px);
  -webkit-backdrop-filter: blur(20.5px);
`

export const CoachmarkHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

export const CoachmarkContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: min-content;
  max-width: 400px;

  ${theme.mediaQueries.minTablet} {
    ${({ $laststep }) => $laststep === "true" ? `
      width: 600px;
      max-width: none;
    ` : ''}
  }

  border-radius: 8px;
  background: ${colors.neutral.transparent.white20};
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
`

export const CoachmarkSkip = styled.div`
  display: flex;
  padding: 4px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  i {
    cursor: pointer;
  }
`

export const CoachmarkContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  transition: all .2s;
`

export const PaginatorContainer = styled.div`
  display: flex;
  padding: 12px 0px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 12px;
  justify-content: center;
`

export const PaginatorFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 13px;
  width: 100%;
`

export const StyledCloseIcon = styled.i`
  cursor: pointer;
`