export const coachmarksArray = [
  {
    title: "Our Goal for You  🌟",
    description:
      "We want to help you UNLOCK your potential and live the best life you possibly can- whatever that means for you. For that reason, this platform is packed with digital versions of powerful personal growth tools. Here's a look at what's available...",
    step: 1,
  },
  {
    title: "Fuel Your Motivation 🖼️",
    description:
      "Set your vision board or an inspirational image as your GrowFrom background.",
    step: 2,
  },
  {
    title: "Create your history of success 🚀",
    description:
      "Track your Goals and the steps you've planned (or already achieved) to accomplish them. Set dates and deadlines to keep your focus sharp.",
    step: 3,
  },
  {
    title: "Your All-in-one place for daily tools 📆",
    description:
      "Your daily log is a place for planning your day, checking off your to-dos and reflecting on your progress.",
    step: 4,
  },
  {
    title: "Supercharge Your Daily Productivity 🎯",
    description:
      "Sit down at night or in the morning to review your day and ensure you're adding activities that bring you closer to your goals.",
    step: 5,
  },
  {
    title: "Ditch the sticky notes. 📝",
    description:
      "Sticky reminders will show up every day until you get them done.",
    step: 6,
  },
  {
    title: "Reinforce Habits 🛠️",
    description:
      "Create a list of the habits you want to track and check them off each day.",
    step: 7,
  },
  {
    title: "Reflect on your Progress 📈",
    description:
      "Take a step back from the day to journal your thoughts and events so you can approach challenges with a fresh perspective!\nThis is a safe place. Your journals are stored with the highest levels of encryption to protect your privacy.",
    step: 8,
  },
  {
    title: "Your Goals Front of Mind 🏁",
    description:
      "See the milestones coming due soon so you can plan activities that will help you reach them!.",
    step: 9,
  },
  // {
  //   title: 'Multiple Ways to GrowFrom',
  //   description: 'Enhance your experience with GrowFrom by installing our browser extension. It brings your inspirational image right to your new tab, creating a motivating start to your online journey!',
  //   finalStep: true,
  //   step: 10
  // }
  {
    title: `You're Ready to Go!`,
    description: "",
    finalStep: true,
    step: 10,
  },
  // {
  //   title: 'Be Intentional About Networking 👥',
  //   description: 'Keep a list of people you want to connect with personally or professionally. They will stay on your daily log until you check them off.',
  //   step: 7
  // },
];

export const coachmarksMobileArray = [
  {
    section: "Our Goal for You  🌟",
    description:
      "We want to help you UNLOCK your potential and live the best life you possibly can- whatever that means for you. For that reason, this platform is packed with digital versions of powerful personal growth tools. Here's a look at what's available...",
    step: 1,
  },
  {
    section: "Goals & Milestones",
    title: "Create your history of success 🚀",
    description:
      "Track your Goals and the steps you've planned (or already achieved) to accomplish them. Set dates and deadlines to keep your focus sharp.",
    step: 2,
  },
  {
    section: "Daily Log",
    title: "Your All-in-one place for daily tools 📆",
    description:
      "Your daily log is a place for planning your day, checking off your to-dos and reflecting on your progress.",
    step: 3,
  },
  {
    subSection: "Daily Log:",
    section: "Activity Planner",
    title: "Supercharge Your Daily Productivity 🎯",
    description:
      "Sit down at night or in the morning to review your day and ensure you're adding activities that bring you closer to your goals.",
    step: 4,
  },
  {
    subSection: "Daily Log:",
    section: "Upcoming Milestones",
    title: "Your Goals Front of Mind 🏁",
    description:
      "See the milestones coming due soon so you can plan activities that will help you reach them!.",
    step: 5,
  },
  {
    subSection: "Daily Log:",
    section: "Sticky Reminders",
    title: "Ditch the sticky notes. 📝",
    description:
      "Sticky reminders will show up every day until you get them done.",
    step: 6,
  },
  {
    subSection: "Daily Log:",
    section: "Habit Tracker",
    title: "Reinforce Habits 🛠️",
    description:
      "Create a list of the habits you want to track and check them off each day.",
    step: 7,
  },
  {
    subSection: "Daily Log:",
    section: "Daily Journal",
    title: "Reflect on your Progress 📈",
    description:
      "Take a step back from the day to journal your thoughts and events so you can approach challenges with a fresh perspective!\nThis is a safe place. Your journals are stored with the highest levels of encryption to protect your privacy.",
    step: 8,
  },
  {
    section: "Customize Background",
    title: "Fuel Your Motivation 🖼️",
    description:
      "Set your vision board or an inspirational image as your GrowFrom background.",
    step: 9,
  },
];
