import axios from 'axios';

const apiBase = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

// Request interceptor to add the token to outgoing requests
apiBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('api_token');
    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default function useAxios() {
  return apiBase;
}
