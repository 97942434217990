import { createContext, useCallback, useEffect, useState } from "react";
import useAxios from "../OurCustomHooks/useAxios";
import { useSelector } from "react-redux";
import moment from "moment";

export const DailyItemContext = createContext({});

export const DailyItemProvider = ({ children }) => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);

  const [dailyItems, setDailyItems] = useState([]);
  const [dailyItemsLoading, setDailyItemsLoading] = useState(false);
  const [dailyItemsError, setDailyItemsError] = useState(false);

  const [currentDate, setCurrentDate] = useState(moment()) 

  const getDailyItems = useCallback(() => {
      setDailyItemsLoading(true);
      axiosClient.get(
        process.env.REACT_APP_API_BASE_URL +
          "/addtodolist/" +
          loginDataRedux.userID
      )
        .then((response) => {
          setDailyItems(response.data.filter((item) => item.todayDate && item.todayDate !== "Invalid date" && item.todayDate !== "Invalid Date"));
          setDailyItemsLoading(false);
        })
        .catch((err) => {
          setDailyItemsError(err);
          setDailyItemsLoading(false);
        });
    },
    [axiosClient, loginDataRedux.userID]
  );

  useEffect(() => {
    getDailyItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <DailyItemContext.Provider value={{
      currentItems: dailyItems.filter((item) => new Date(item.todayDate).toDateString() === new Date(currentDate).toDateString()),
      todayItems: dailyItems.filter((item) => new Date(item.todayDate).toDateString() === new Date().toDateString()),
      dailyItems: dailyItems,
      dailyItemsLoading,
      dailyItemsError,
      currentDate: currentDate,
      setCurrentDate,
      refetch: getDailyItems,
    }}>
      {children}
    </DailyItemContext.Provider>
  );
}
