import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../../Theme";
import UploadImage from "./UploadImage";

import Boat from "../Images/Gallery/boat.jpg";
import Car from "../Images/Gallery/car.png";
import CoupleTravel from "../Images/Gallery/coupletravel.jpg";
import GreatBarrierReef from "../Images/Gallery/greatbarrierreef.jpg";
import Mansion from "../Images/Gallery/mansion.png";
// import OpenSkies from "../Images/Gallery/openskies.png";
import DefaultImage from "../Images/Gallery/default.jpg";
import { useDispatch } from "react-redux";
import { colorThemeActions } from "../Store/ColorThemeState";

const ContainerGrid = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center; */

  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 12px;
  align-self: stretch;
  flex-wrap: wrap;


`;

export const ImageContainer = styled.div`
  /* flex: 0 0 calc(14.28% - 20px);
  color: white;
  padding: 0;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease; */

  display: flex;
  width: 155px;
  min-width: 155px;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: scale(1.05);
  }

  img {
    &:hover {
      border-radius: var(--sizes-12, 12px);

      /* buttons-on-hover */
      box-shadow: 0px 0px 12px 0px rgba(32, 180, 131, 0.70);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }
  &:hover::before {
    opacity: 1;
  }

  ${theme.mediaQueries.maxBigTablet} {
    flex: 0 0 calc(25% - 20px);
  }
`;

const ImageGallery = React.forwardRef(({ value, onChange }, ref) => {
  const dispatch = useDispatch();

  // Sample image URLs
  const images = [
    Boat,
    Car,
    CoupleTravel,
    GreatBarrierReef,
    Mansion,
    DefaultImage,
  ];

  const getBase64 = (file) =>
    new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject("Error: ", error);
    });

  const imageChangeHandler = async (event) => {
    const file = event.target.files[0];

    try {
      const result = await getBase64(file)
      localStorage.setItem("bgImage", result);
      dispatch(colorThemeActions.mySelectedImage(result));
      onChange(result);
    } catch (e) {
      console.log(e);
    };

    // getBase64(file)
    //   .then((result) => {
    //     localStorage.setItem("bgImage", result);
    //     dispatch(colorThemeActions.mySelectedImage(result));
    //     onChange(result);
    //   })
    //   .catch((e) => {
    //     // console.log(e);
    //   });
  };

  const handleClick = (index) => {
    // Handle click event for images
    localStorage.setItem("bgImage", images[index]);
    dispatch(colorThemeActions.mySelectedImage(images[index]));
    //onChange(images[index]);
  };

  return (
    <ContainerGrid>
      {images.map((imageUrl, index) => (
        <ImageContainer onClick={() => handleClick(index)} key={`${index + 1}`}>
          <Image
            src={imageUrl}
            alt={`${index + 1}`}
            className="rounded"
            width={155}
            height={160}
            style={{ objectFit: "cover" }}
          />
        </ImageContainer>
      ))}
      <ImageContainer>
        <UploadImage
          ref={ref}
          value={value}
          onChange={(event) => {
            imageChangeHandler(event);
          }}
        />
        {/* <input
          ref={ref}
          type="file"
          onChange={(event) => {
            imageChangeHandler(event);
          }}
          accept="image/*"
        /> */}
      </ImageContainer>
    </ContainerGrid>
  );
});

export default ImageGallery;
