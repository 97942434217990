import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Component/Store/Index";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {process.env.REACT_APP_IS_BUILDING_APP === "true" ? (
        <BrowserRouter>
          {console.log('BrowserRouter')}
          <App />
        </BrowserRouter>
      ) : (
        <MemoryRouter initialEntries={["/"]} initialIndex={0}>
        {console.log('MemoryRouter')}
          <App />
        </MemoryRouter>
      )}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
