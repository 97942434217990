import { memo, useCallback, useMemo } from "react"

// Hooks
import { useSkipCoachmarks } from "../../OurCustomHooks/useSkipCoachmarks"

// Assets
import { ReactComponent as PlusIcon } from '../../Assets/Icons/plus.svg'

// Components
import { H5, Body } from '../Commons/Text'
import { ButtonWithIcons } from "../Commons/Buttons"

// Styles
import { ModalContent, CoachmarkHeader } from './styles'

const CoachmarksModalComponent = ({ showModal, setStep, firstTime }) => {

  const handleSkip = useSkipCoachmarks(false)

  const titleText = useMemo(() => {
    return !firstTime ? 'Welcome to GrowFrom' : 'Congratulations, your GrowFrom account is ready for use!'
  }, [firstTime])

  const handleTakeTour = useCallback(() => {
    showModal(false)
    setStep(1)
  }, [setStep, showModal])

  return <div className="d-flex justify-content-center align-items-center h-100">
    <ModalContent>
      <CoachmarkHeader>
        <H5.Regular style={{ textAlign: 'center' }}>{titleText}</H5.Regular>
        <Body.MD.Light>The world's first, totally and forever free, place to manage your personal growth.</Body.MD.Light>
        <ButtonWithIcons
          rightIcon={<PlusIcon width={20} height={24} className='d-flex align-items-center' />}
          onClick={handleTakeTour}
        >
          Take the Tour
        </ButtonWithIcons>
        <ButtonWithIcons variant='underlined' onClick={() => {
          handleSkip()
          showModal(false)
        }}>Skip for Now</ButtonWithIcons>
      </CoachmarkHeader>
    </ModalContent>
  </div>
}

export const CoachmarksModal = memo(CoachmarksModalComponent)