export const colors = {
  neutral: {
    100: '#FFF',
    200: '#E9F2EF',
    300: '#D1D9D6',
    400: '#9FA6A4',
    500: '#878C8A',
    600: '#565958',
    700: '#171818',
    transparent: {
      white50: 'rgba(255, 255, 255, 50%)',
      white20: 'rgba(255, 255, 255, 20%)',
      white5: 'rgba(255, 255, 255, 5%)',
    }
  },
  primary: {
    100: '#AEFEDA',
    200: '#2ADFA4',
    300: '#20B483',
    400: '#168A64',
    500: '#0D6347',
    600: '#053E2B',
    700: '#011D12',
    transparent: {
      100: 'rgba(174, 254, 218, 50%)',
      200: 'rgba(42, 223, 164, 50%)',
      300: 'rgba(32, 180, 131, 50%)',
      400: 'rgba(22, 138, 100, 50%)',
      500: 'rgba(13, 99, 71, 50%)',
      600: 'rgba(5, 62, 43, 50%)',
      700: 'rgba(1, 29, 18, 50%)',
    }
  },
  accent: {
    100: 'rgba(255, 220, 188, 100%)',
    200: 'rgba(249, 171, 6, 100%)',
    300: 'rgba(199, 136, 4, 100%)',
    400: 'rgba(151, 104, 2, 100%)',
    500: 'rgba(106, 73, 1, 100%)',
    600: 'rgba(64, 44, 0, 100%)',
    700: 'rgba(26, 18, 0, 100%)',
    transparent: {
      100: 'rgba(255, 220, 188, 50%)',
      200: 'rgba(249, 171, 6, 50%)',
      300: 'rgba(199, 136, 4, 50%)',
      400: 'rgba(151, 104, 2, 50%)',
      500: 'rgba(106, 73, 1, 50%)',
      600: 'rgba(64, 44, 0, 50%)',
      700: 'rgba(26, 18, 0, 50%)',
    }
  },
  semantic: {
    negative: {
      normal: '#ED4C4C',
      dark: '#8C2D2D',
      light: '#EDD5D5',
      transparent: {
        dark: 'rgba(140, 45, 45, 0.4)',
        normal: 'rgba(237, 76, 76, 0.5)',
        light: 'rgba(237, 213, 213, 0.5)',
      }
    },
    informative: {
      normal: 'rgba(76, 150, 237, 100%)',
      dark: 'rgba(27, 56, 83, 100%)',
      light: 'rgba(209, 221, 235, 100%)',
      transparent: {
        normal: 'rgba(76, 150, 237, 50%)',
        dark: 'rgba(27, 56, 83, 50%)',
        light: 'rgba(209, 221, 235, 50%)',
      }
    },
    positive: {
      normal: 'rgba(31, 192, 114, 100%)',
      dark: 'rgba(24, 156, 92, 100%)',
      light: 'rgba(204, 233, 219, 100%)',
      transparent: {
        normal: 'rgba(31, 192, 114, 50%)',
        dark: 'rgba(24, 156, 92, 40%)',
        light: 'rgba(204, 233, 219, 50%)',
      }
    },
    warning: {
      normal: 'rgba(237, 182, 76, 100%)',
      dark: 'rgba(202, 148, 42, 100%)',
      light: 'rgba(244, 236, 220, 100%)',
      transparent: {
        normal: 'rgba(237, 182, 76, 50%)',
        dark: 'rgba(202, 148, 42, 40%)',
        light: 'rgba(244, 236, 220, 50%)',
      }
    },
  }
};

const fallbackFonts = 'Sans-Serif';

export const fontWeights = {
  regular: '400',
  semibold: '600',
  bold: '700',
};

export const fontNames = { inter: `"Inter", ${fallbackFonts}` };

export const fontSizes = {
  h1: 40,
  h2: 32,
  h3: 28,
  h4: 24,
  h5: 20,
  bodyL: 18,
  bodyM: 16,
  bodyS: 13,
  bodyXS: 11,
};

export const lineHeigths = {
  h1: 40,
  h2: 36,
  h3: 32,
  h4: 32,
  h5: 24,
  bodyL: 22,
  bodyM: 20,
  bodyS: 17,
  bodyXS: 15,
};

export const textDecorations = {
  strikeThrough: 'line-through',
  underLine: 'underline',
  normal: 'unset',
};

const breakpoints = {
  mobile: 0, // 0 to 767
  tablet: 768, // 768 to 1023
  bigTablet: 1024, // 1024 to 1199
  smallDesktop: 1200, // 1200 to 1439
  desktop: 1440, // 1440 to 1919
  fullWidth: 1920, // 1920 and above
  smallHeight: 830, // 0 to 830
};

const mediaQueries = {
  maxSmallMobile: '@media screen and (max-width: 320px)',
  minMobile: `@media screen and (min-width: ${breakpoints.mobile}px)`,
  maxMobile: `@media screen and (max-width: ${breakpoints.tablet - 1}px)`,
  minTablet: `@media screen and (min-width: ${breakpoints.tablet}px)`,
  maxTablet: `@media screen and (max-width: ${breakpoints.bigTablet - 1}px)`,
  minBigTablet: `@media screen and (min-width: ${breakpoints.bigTablet}px)`,
  maxBigTablet: `@media screen and (max-width: ${breakpoints.smallDesktop - 1}px)`,
  minSmallDesktop: `@media screen and (min-width: ${breakpoints.smallDesktop}px)`,
  maxSmallDesktop: `@media screen and (max-width: ${breakpoints.desktop - 1}px)`,
  minDesktop: `@media screen and (min-width: ${breakpoints.desktop}px)`,
  maxDesktop: `@media screen and (max-width: ${breakpoints.fullWidth - 1}px)`,
  minFullwidth: `@media screen and (min-width: ${breakpoints.fullWidth}px)`,
  minSmallHeight: `@media screen and (max-height: ${breakpoints.smallHeight}px)`,
};

export const theme = {
  colors,
  fallbackFonts,
  fontWeights,
  fontNames,
  fontSizes,
  lineHeigths,
  textDecorations,
  breakpoints,
  mediaQueries,
};
