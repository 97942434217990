import React, { useCallback, useEffect } from "react";
import EditableInlineComponent from "../Commons/EditableInput";
import Checkbox from "../Commons/Checkbox";
import useAxios from "../../OurCustomHooks/useAxios";
import { useSelector } from "react-redux";
import { Body } from "../Commons/Text";
import { theme } from "../../Theme";
import Icon from "../Commons/Icon";
import { StrikedText } from "../Commons/StrikedText";



const HabitItem = ({ habit, date, index, refresh }) => {
  const axiosClient = useAxios();
  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const [editMode, setEditMode] = React.useState(false);
  const [habitDetail, setHabitDetail] = React.useState(habit.habit_description);
  const [strike, setStrike] = React.useState(false);

  const updateHabit = useCallback(
    (_id, params) => {
      axiosClient({
        method: "put",
        url: process.env.REACT_APP_API_BASE_URL + "/habits",
        data: {
          list_id: _id,
          userId: loginDataRedux.userID,
          ...params,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh?.();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  const deleteHabit = useCallback(
    (id) => {
      axiosClient({
        method: "delete",
        url: process.env.REACT_APP_API_BASE_URL + "/habits",
        data: {
          list_id: id,
          userId: loginDataRedux.userID,
        },
      })
        .then((response) => {
          if (response.data.msg === "_success") {
            refresh?.();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [axiosClient, loginDataRedux.userID, refresh]
  );

  useEffect(() => {
    setStrike(false);
  }, [habit]);

  return (
    <div
      key={habit._id}
      className="d-flex justify-content-between align-items-center"
    >
      <EditableInlineComponent
        name={`habit[${index}].habit_description`}
        editMode={editMode}
        value={habitDetail}
        onCancel={() => {
          setEditMode(false);
          setHabitDetail(habit.habit_description);
        }}
        onConfirm={() => {
          setEditMode(false);
          updateHabit(habit._id, { habit_description: habitDetail });
        }}
        onChange={(e) => {
          setHabitDetail(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && habitDetail.length > 0) {
            updateHabit(habit._id, { habit_description: habitDetail });
            setEditMode(false);
          }
        }}
        displayElement={
          <StrikedText className="d-flex align-items-center">
            <Checkbox
              checked={habit.isChecked || strike}
              onChange={(e) => {
                setStrike(true);
                updateHabit(habit._id, {
                  checkedDate: new Date(date),
                  isChecked: !habit.isChecked,
                });
              }}
            />
            <Body.MD.Regular
              className={`m-0 ${strike ? "strike" : ""}`}
              onClick={() => {
                setEditMode(true);
              }}
              style={{
                color: !habit.isChecked
                  ? theme.colors.neutral[100]
                  : theme.colors.neutral[400],
                textDecoration: habit.isChecked ? "line-through" : "none",
              }}
            >
              {habit.habit_description}
            </Body.MD.Regular>
          </StrikedText>
        }
      />
      <Icon
        className="bi bi-trash"
        size={"md"}
        style={{
          color: theme.colors.semantic.negative.transparent.normal,
          cursor: "pointer",
        }}
        onClick={() => {
          deleteHabit(habit._id);
        }}
      />
    </div>
  );
};

export default HabitItem;
