import { useContext, useEffect, useState } from "react";
import { CoachMarkContext } from "../Contexts/coachmark";

export const useCoachmarkRefresh = (property, ref) => {
  const [rerender, setRerender] = useState(0)
  const { coachmarkProps } = useContext(CoachMarkContext)
  useEffect(() => { /* needed so that ref gets updates value to be passed as prop */
    if (coachmarkProps[property].visible) {
      setRerender(r => r + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, coachmarkProps[property].visible])

  useEffect(() => {
    if (coachmarkProps[property].visible && rerender > 0) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachmarkProps, property, ref, rerender])

  return rerender
}
