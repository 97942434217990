import { forwardRef, useContext } from "react";
import { Body, H5 } from "../Commons/Text";
import TextEditor from "./TextEditor";
import { CoachMarkContext } from "../../Contexts/coachmark";
import { Coachmarks } from "../Coachmarks";
import { StyledCoachmark } from "../Dashboard/styles";
import { useCoachmarkRefresh } from "../../OurCustomHooks/useCoachmarkRefresh";

const Journal = forwardRef((_, ref8) => {
  const { coachmarkProps, step } = useContext(CoachMarkContext);

  const rerender = useCoachmarkRefresh("journal", ref8);

  return (
    <>
      {coachmarkProps.journal?.visible && rerender > 0 && (
        <StyledCoachmark
          activate={step === 8}
          component={
            <Coachmarks
              currentStep={8}
              handleNext={() => {
                coachmarkProps.journal?.handleNext?.();
              }}
              handlePrev={() => {
                coachmarkProps.journal.handlePrev();
              }}
            />
          }
          reference={ref8}
          tooltip={coachmarkProps.journal.tooltip}
        />
      )}
      <div className="mt-md-3" ref={ref8}>
        <div className="d-flex justify-content-center justify-content-md-start overflow-hidden position-relative my-3">
          <Body.SM.Regular
            className="d-block d-md-none"
            style={{
              position: "absolute",
              left: -5,
              opacity: "0.5",
            }}
          >
            Habits
          </Body.SM.Regular>
          <H5.Regular>Journal</H5.Regular>
          <Body.SM.Regular
            className="d-block d-md-none"
            style={{
              position: "absolute",
              right: -85,
              opacity: "0.5",
            }}
          >
            Upcoming Milestones
          </Body.SM.Regular>
        </div>
        <TextEditor />
      </div>
    </>
  );
});

export default Journal;
