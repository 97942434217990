import React from "react";
import { styled } from "styled-components";
import { theme } from "../../Theme";

const StyleInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid
    var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
  background: var(--neutral-transparent-100-white-5, rgba(255, 255, 255, 0.05));
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 4px;
  margin-right: 10px;

  input {
    border: none;
    background: transparent;
    flex: 1;
    color: white;
    padding: 4px;
    outline: none;
    &::placeholder {
      color: ${theme.colors.neutral[200]};
      ${({ $placeholdercenter }) => $placeholdercenter ? 'text-align: center' : ''}
    }
    
  }

  .counter {
    color: white;
    font-size: 12px;
  }
`;

const BaseInput = React.forwardRef(({
  value = '',
  maxLength = 100,
  onChange,
  onBlur,
  $placeholdercenter,
  ...props
}, ref) => {
  return (
    <StyleInput $placeholdercenter={$placeholdercenter}>
      <input
        ref={ref}
        type="text"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />
      <span className="counter pe-1">
        {value?.length} / {maxLength}
      </span>
    </StyleInput>
  );
});

export default BaseInput