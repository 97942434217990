import React, {
  useEffect,
  useState,
} from "react";
import { styled } from "styled-components";
import TimeDropdown from "./TimeDropdown";
import { theme } from "../../Theme";

const StyleInput = styled.div`
  justify-content: center;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  display: flex;
  align-items: center;

  input {
    &::-webkit-inner-spin-button {
      opacity: 1;
    }
    border-radius: 4px;
    border-bottom: 1px solid
      var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
    background: var(
      --neutral-transparent-100-white-5,
      rgba(255, 255, 255, 0.05)
    );
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    width: 42px;
    height: 42px;
    border: none;
    color: white;
    padding: 4px;
    outline: none;
    &::placeholder {
      color: ${theme.colors.neutral[200]};
    }
  }
`;

const TimeInput = ({
  name,
  value = undefined,
  onChange,
  label = "Time",
  flat = true,
  setFlat = () => {},
  setReset = () => {},
  onClick = () => {},
}) => {
  const [timeValue, setTimeValue] = useState();

  useEffect(() => {
    if (!flat) {
      onChange?.(timeValue);
    }
  }, [flat, onChange, timeValue]);

  useEffect(() => {
    setReset(() => () => {
      if (value) {
        setTimeValue(value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReset]);

  useEffect(() => {
    setTimeValue(value);
  }, [value]);

  return (
    <StyleInput>
      <input type="hidden" name={name} />
      <TimeDropdown
        label={label}
        value={timeValue}
        onChange={(e) => {
          setTimeValue(e);
          onChange(e);
        }}
        onClick={onClick}
      />
    </StyleInput>
  );
};

export default TimeInput;
