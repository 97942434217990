import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FullLogo } from "../Commons/Brand";
import { Body, H1 } from "../Commons/Text";
import FormInput, { ErrorBox } from "../Commons/FormInput";
import { ButtonWithIcons } from "../Commons/Buttons";
import useAxios from "../../OurCustomHooks/useAxios";

const ForgetPassword = ({showLinks = true}) => {
  const axiosClient = useAxios();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm();

  /* Email_sent, Email_not_exist, undefined */
  const [statusState, setStatusState] = useState()
  const [loading, setLoading] = useState()

  const forgetPasswordHandler = (data) => {
    setLoading(true)
    axiosClient({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/login/forget_password`,
      data: {
        email: data.Email,
        isExtension: false,
        siteUrl: window.location.host,
      },
    })
      .then((response) => {
        if (response.data.msg === "Email_sent" || response.data.msg === "Email_not_exist") {
          setStatusState(response.data.msg)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("Error is ", err);
      });
  };

  const bodyContent = useMemo(() => {
    if (!statusState || statusState === "Email_not_exist") {
      return "Enter your email and we'll send you instructions on how to reset your password"
    }

    if (statusState === "Email_sent") {
      return 'Please check your email for further instructions on resetting your password.'
    }

    return ''
  }, [statusState])

  return (
    <>
      <FullLogo />
      {/* Assume Forgot Password option here...! */}

      <H1.Light style={{ marginTop: 40 }}>Reset Password</H1.Light>
      <Body.MD.Regular>
        {bodyContent}
      </Body.MD.Regular>

      <form
        className="w-100"
        autoComplete="one-time-code"
        onSubmit={handleSubmit(forgetPasswordHandler)}
      >
        <div className="d-flex flex-column align-items-center">
          {(!statusState || statusState !== "Email_sent") && <FormInput
            ref={register}
            type="text"
            placeholder="Enter Email"
            {...register("Email", {
              required: "Please enter a valid email address",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address",
              },
            })}
            error={errors.Email}
            style={{
              maxWidth: 550,
            }}
          />}
          {statusState === 'Email_not_exist' && <ErrorBox maxWidth='550px'>
            Email not found. Please check that you have entered the correct email address and try again.
          </ErrorBox>}

          {(!statusState || statusState !== "Email_sent") && <ButtonWithIcons type="submit" style={{ marginTop: 40 }}
            disabled={!isValid || loading}
            loading={loading}>
            Send Instructions
          </ButtonWithIcons>}
        </div>
      </form>

      {showLinks && (<>
          <Body.MD.Regular style={{ marginTop: 48 }}>
          Already have an account? <Link to="/login">Login from here</Link>
        </Body.MD.Regular>
        <Body.MD.Regular>
          Create a {" "}
          <Link to="/" exact="true">
            New Account
          </Link>
        </Body.MD.Regular>
        </>
      )}

    </>
  );
};

export default ForgetPassword;
