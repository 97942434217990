import React from "react";
import { styled } from "styled-components";
import { theme } from "../../Theme";
import { ButtonWithIcons } from "./Buttons";
import Icon from "./Icon";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { Body } from "./Text";

const StyleInput = styled.div`
  display: flex;
  align-items: center;

  margin-right: 10px;

  input {
    &::-webkit-inner-spin-button {
      opacity: 1;
    }
    border-radius: 4px;
    border-bottom: 1px solid
      var(--neutral-transparent-100-white-20, rgba(255, 255, 255, 0.2));
    background: var(
      --neutral-transparent-100-white-5,
      rgba(255, 255, 255, 0.05)
    );
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    width: 42px;
    height: 42px;
    border: none;
    color: white;
    padding: 4px;
    outline: none;
    &::placeholder {
      color: var(--neutral-400, #9fa6a4);
    }
  }

  .react-datepicker__navigation {
    top: 8px !important;
  }

  .react-datepicker {
    min-height: 260px;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px;
  }

  .react-datepicker__month-container,
  .react-datepicker__header,
  .react-datepicker {
    background-color: ${theme.colors.primary[700]};
    border-radius: var(--sizes-12, 12px);
    border: none;

    .react-datepicker__day,
    .react-datepicker__current-month {
      color: ${theme.colors.neutral[100]} !important;
      position: relative;
    }

    .react-datepicker__day-name,
    .react-datepicker__day--outside-month {
      color: ${theme.colors.neutral[400]} !important;
    }

    .react-datepicker__header {
      border: none;
      border-radius: var(--sizes-12, 12px);
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day:hover {
      border: 1px solid ${theme.colors.primary[200]} !important;
      background-color: transparent !important;
      border-radius: 50%;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--today {
      border-radius: 50%;
    }

    .react-datepicker__day--today {
      background-color: ${theme.colors.primary[300]} !important;
      border: 1px solid ${theme.colors.primary[400]} !important;
    }

    .react-datepicker__day--selected {
      border: 1px solid ${theme.colors.primary[400]} !important;
      background-color: transparent !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--highlighted,
    .react-datepicker__day--highlighted:not(.react-datepicker__day--today) {
      background: none;

      &:hover {
        background-color: ${theme.colors.primary[200]};
        border-radius: 50%;
      }
    }

    .react-datepicker__day--highlighted::before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${theme.colors.accent[200]};
    }
  }
`;

const StyleCalendar = styled.div`
  position: relative;
  background-color: ${theme.colors.primary[700]};
  border-radius: var(--sizes-12, 12px);
  border: 1px solid var(--Primary-600, #053e2b);
  font-family: "Inter" !important;
  padding: 0 10px;
  font-size: 14px;
`;

export const CalendarWithFooter = ({ children }) => (
  <StyleCalendar>
    {children}
    <div className="calendar-footer">
      {/* <ButtonWithIcons variant="underlined">Cancel</ButtonWithIcons> */}
    </div>
  </StyleCalendar>
);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CalendarInput = React.forwardRef(
  (
    {
      value,
      maxLength = 100,
      onChange,
      onBlur,
      label = "Time",
      highlightDates = [],
      $customlabel,
      $customLabelClass = "",
      style,
      className = "justify-content-start",
      ...props
    },
    ref
  ) => {
    const Input = React.forwardRef(({ value, onClick }, ref) => (
      <ButtonWithIcons
        className={className}
        variant={"outlined"}
        onClick={onClick}
        ref={ref}
        rightIcon={
          <Icon
            className={"ps-1 bi bi-calendar3"}
            size="sm"
            style={{ color: theme.colors.primary[300] }}
          ></Icon>
        }
        $customChildren={$customlabel}
        $minwidth="unset"
        style={style}
      >
        {!$customlabel && (
          <span className={$customLabelClass}>
            {value ? moment(new Date(value)).format("MMM DD, YYYY") : label}
          </span>
        )}
      </ButtonWithIcons>
    ));

    return (
      <StyleInput>
        <DatePicker
          renderCustomHeader={({
            date,
            decreaseMonth,
            decreaseYear,
            increaseMonth,
            increaseYear,
            prevMonthButtonDisabled,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              className="d-flex justify-content-between px-2 align-items-center w-100"
              style={{
                color: theme.colors.neutral[100],
              }}
            >
              <ButtonWithIcons
                variant="outlined"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                $minwidth="20px"
                $customLabelClass="me-0"
                $customChildren={"<"} 
              />
              <ButtonWithIcons
                variant="outlined"
                onClick={decreaseYear}
                disabled={prevYearButtonDisabled}
                $minwidth="20px"
                $customChildren={"<<"} 
              />
              <Body.SM.Regular className="p-0 m-0">
              {months[getMonth(date)] + " "}
              {getYear(date)}
              </Body.SM.Regular>
              <ButtonWithIcons
                variant="outlined"
                onClick={increaseYear}
                disabled={nextYearButtonDisabled}
                $minwidth="20px"
                $customChildren={">>"} 
              />
              <ButtonWithIcons
                variant="outlined"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                $minwidth="20px"
                $customChildren={">"} 
              />
            </div>
          )}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
          selected={value}
          onChange={onChange}
          customInput={<Input />}
          highlightDates={highlightDates}
          popperPlacement="bottom"
          popperModifiers={{
            flip: { behavior: ["bottom"] },
            preventOverflow: { enabled: false },
            hide: { enabled: false },
          }}
        />
      </StyleInput>
    );
  }
);

export default CalendarInput;
