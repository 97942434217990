import { styled } from "styled-components";
import { colors, theme } from "../../Theme";

const StyledI = styled.i`
  margin: 0 10px;
  cursor: pointer;
  ${({ margin }) => margin ? `margin: ${margin}` : ''}

  ${theme.mediaQueries.maxMobile} {
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
`;

const FilledDots = styled(StyledI)`
  opacity: 0.2;
  ${({ $small }) => $small ? `font-size: x-small;` : ''}
`;

const EmptyDots = styled(StyledI)`
  color: ${colors.primary[100]} !important;
  ${({ $small }) => $small ? `font-size: x-small;` : ''}
`;

export const Dots = ({ fill, ...props }) => {
  return fill ?
    <FilledDots
      className="bi bi-circle-fill"
      {...props}
    /> :
    <EmptyDots
      className={`bi bi-circle`}
      {...props}
    />
}