import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import useAxios from "./useAxios";

// Redux
import { loginActions } from "../Component/Store/LoginPageState";

export const useSkipCoachmarks = (navigate = true) => {
  const axios = useAxios()
  const dispatch = useDispatch()
  const loginDataRedux = useSelector(state => state.login.loginInfo);
  const handleSkip = useCallback(async () => {
    if (loginDataRedux.showCoachmarks) {

      try {
        const response = await axios({
          method: 'post',
          url: process.env.REACT_APP_API_BASE_URL + "/user/skip_coachmarks",
          data: {
            userId: loginDataRedux._id,
          }
        })

        if (response) {
          const newLoginInfo = { ...loginDataRedux, showCoachmarks: false }
          dispatch(loginActions.myLoginInfo(newLoginInfo))
          window.localStorage.setItem('manualLoginInfo', JSON.stringify(newLoginInfo))
        }
      } catch (e) {
        console.log(e)
      }
    }
    // if (navigate) {
    //   window.location.replace('/')
    // }
  }, [axios, dispatch, loginDataRedux])


  return handleSkip
}