import React from "react";
import { Body } from "./Text";
import { Dots } from "./Dots";
import styled from "styled-components";
import { theme } from "../../Theme";

const StyledTab = styled.div`
  &.styled {
    .tab-buttons {
      padding: 10px;
      background-color: ${theme.colors.primary.transparent[500]};
    }
    .title {
      margin-top: 10px;
    }
  }
`;

const Tabs = ({
  tabs,
  activeTab = 0,
  setActiveTab = () => {},
  styled = false,
  gradients = [],
}) => {
  const styledClass = styled ? "styled" : "";

  return (
    <StyledTab className={styledClass}>
      <div className="tab-buttons">
        {tabs.map((_, i) =>
          i === activeTab ? (
            <Dots
              key={`dots_${i}`}
              onClick={() => {
                setActiveTab(i);
              }}
            />
          ) : (
            <Dots
              fill
              key={`dots_${i}`}
              onClick={() => {
                setActiveTab(i);
              }}
            />
          )
        )}
      </div>
      {!styled && (
        <div
          style={{
            width: "100%",
            background: gradients[activeTab],
          }}
        >
          <Body.LG.Regular className="title">{tabs[activeTab]}</Body.LG.Regular>
        </div>
      )}
    </StyledTab>
  );
};

export default Tabs;
