import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgetPassword from "./ForgetPassword";
import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import ResetPasswordForm from "./ResetPasswordForm";

const LoginFormRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<RegistrationForm />} />
      <Route path="/login" exact element={<LoginForm />} />
      <Route path="/forgot-password" exact element={<ForgetPassword />} />
      <Route path="/reset-password-form" exact element={<ResetPasswordForm />} />
    </Routes>
  );
};

export default LoginFormRouter;
