import { createSlice } from "@reduxjs/toolkit";
import imggg from '../Images/Gallery/default.jpg';

const initialState = {
    colorTheme: 'default-theme',
    imagee: localStorage.getItem("bgImage") === null || localStorage.getItem("bgImage") === 'undefined' ? imggg : localStorage.getItem("bgImage"),

    colors: {
        sideBarColor: '',
        mainBodyColor: ''
    }

};

const colorThemeSlice = createSlice({
    name: 'colorTheme',
    initialState: initialState,
    reducers: {
        mySelectedColor(state, action) {
            state.colorTheme = action.payload;

            // if (action.payload === "blue") {
            //     state.colors.sideBarColor = "#1234";
            //         state.colors.mainBodyColor = "#12345"
            // }
            // else if (action.payload === "pink") {
            //     state.colors.sideBarColor = "#12346";
            //         state.colors.mainBodyColor = "#12347"
            // }
        },
        mySelectedImage(state, action) {
            state.imagee = action.payload;
        }

    }

});

export const colorThemeActions = colorThemeSlice.actions;
export default colorThemeSlice.reducer;

