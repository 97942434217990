import React, { forwardRef, useContext } from "react";
import { styled } from "styled-components";
import { theme } from "../../Theme";
import AddDailyItem from "./AddDailyItem";
import ListDailyItems from "./ListDailyItems";
import { HoverPlannerCss } from "./styles";
import { StyledCoachmark } from "../Dashboard/styles";
import { CoachMarkContext } from "../../Contexts/coachmark";
import { Coachmarks } from "../Coachmarks";
import { useCoachmarkRefresh } from "../../OurCustomHooks/useCoachmarkRefresh";

const TodoListContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${theme.colors.primary.transparent[400]};
  background: var(
    --gradient-green-widgets,
    linear-gradient(
      180deg,
      rgba(5, 62, 43, 0.5) 0%,
      rgba(13, 99, 71, 0.05) 100%
    )
  );
  min-height: 560px;

  ${HoverPlannerCss}
`;

const TodoList = forwardRef(({ date, setDate }, ref5) => {
  const { coachmarkProps } = useContext(CoachMarkContext);

  // useEffect(() => { /* needed so that ref gets updates value to be passed as prop */
  //   if (coachmarkProps.planner.visible) {
  //     setRerender(rerender + 1);
  //   }
  // }, [ref5.current])

  // useEffect(() => {
  //   if (coachmarkProps.planner.visible && rerender > 0) {
  //     setTimeout(() => {
  //       window.dispatchEvent(new Event('resize'));
  //     }, 500)
  //   }
  // }, [coachmarkProps.planner.visible, ref5, rerender])

  const rerender = useCoachmarkRefresh("planner", ref5);

  return (
    <>
      <TodoListContainer className="parent-hovered h-100" ref={ref5}>
        {coachmarkProps.planner.visible && rerender > 0 && (
          <StyledCoachmark
            activate={coachmarkProps.planner.visible}
            component={
              <Coachmarks
                currentStep={5}
                handleNext={() => {
                  coachmarkProps.planner?.handleNext?.();
                }}
                handlePrev={() => {
                  coachmarkProps.planner.handlePrev();
                }}
              />
            }
            reference={ref5}
            tooltip={coachmarkProps.planner.tooltip}
          />
        )}
        <AddDailyItem />
        <ListDailyItems />
      </TodoListContainer>
    </>
  );
});

export default TodoList;
