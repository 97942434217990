import styled from "styled-components";
import { theme } from "../../Theme";

export const StrikedText = styled.div`
  @keyframes strike {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .strike {
    position: relative;
    color: ${theme.colors.neutral[400]} !important;
  }
  .strike::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${theme.colors.neutral[400]};
    animation-name: strike;
    animation-duration: 0s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;