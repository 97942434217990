import React, { useState, useEffect, useRef, useContext } from "react";
import { Container } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { Body, Display, H5 } from "../Commons/Text";
import { Input } from "../Commons/Input";
import useTime from "../../utils/date";
import { styled } from "styled-components";
import { theme } from "../../Theme";
import Icon from "../Commons/Icon";
import { DailyItemContext } from "../../Contexts/DailyItemContext";
import useBreakpoint from "../../OurCustomHooks/useBreakpoints";

const StyledDashborad = styled(Container)`
  /* height: 68vh; */
  flex-wrap: wrap;

  ${theme.mediaQueries.maxMobile} {
    /* height: 80vh; */
  }
`;

const DefaultDashboard = (props) => {
  const { getGreetings } = useTime();
  const ref = useRef();
  const [searchText, setSearchText] = useState("");
  const { isMobile } = useBreakpoint()

  const { todayItems: plans } = useContext(DailyItemContext);

  const loginDataRedux = useSelector((state) => state.login.loginInfo);
  const updatedName = useSelector((state) => state.login.updatedName);

  const [time, setTime] = useState(moment().format("hh:mm a"));

  useEffect(() => {
    localStorage.setItem("user_role", loginDataRedux.role);
    localStorage.setItem("user_mail", loginDataRedux.email);
  }, [loginDataRedux]);

  useEffect(() => {
    const interval = setInterval(() => setTime(moment().format("hh:mm a")), 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <StyledDashborad
      fluid
      className="d-flex align-content-center justify-content-center justify-items-center flex-column text-center"
    >
      <Display.MD.Light>{time}</Display.MD.Light>
      <H5.Light>{moment().format("dddd, MMMM DD")}</H5.Light>
      {plans.filter(pl => pl.status === 'pending').length > 0 && <Body.SM.Light>{plans.filter(pl => pl.status === 'pending').length} Plan{plans.filter(pl => pl.status === 'pending').length > 1 ? 's' : ''} to complete today</Body.SM.Light>}
      {isMobile ? <Display.SM.Thin>
        {getGreetings()},{" "}
        {updatedName === "" ? loginDataRedux.name : updatedName}
      </Display.SM.Thin>
        : <Display.MD.Thin>
          {getGreetings()},{" "}
          {updatedName === "" ? loginDataRedux.name : updatedName}
        </Display.MD.Thin>}
      {!isMobile && <Input
        ref={ref}
        value={searchText}
        leftIcon={<Icon className="bi bi-search" size="sm" onClick={() => {
          if (searchText.length > 0) {
            window.open(`https://google.com/search?q=${searchText}`, "_blank");
          }
        }} />}
        placeholder="Google search"
        onKeyDown={(e) => {
          if (e.key === "Enter" && searchText.length > 0) {
            window.open(`https://google.com/search?q=${searchText}`, "_blank");
          }
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />}
    </StyledDashborad>
  );
};

export default DefaultDashboard;
