import moment from "moment";

const getGreetings = () => {
  var curHr = new Date().getHours();
  if (curHr < 12) {
    return "Good Morning";
  } else if (curHr < 18) {
    return "Good Afternoon";
  }
  return "Good Evening";
};

const formatDate = (date) => {
  const dateFormatted = moment(new Date(date)).format("dddd, MMMM DD");
  return  dateFormatted === 'Invalid date' ? moment().format("dddd, MMMM DD") : dateFormatted;
};

export default function useTime() {
  return {
    getGreetings: getGreetings,
    formatDate: formatDate
  };
}
