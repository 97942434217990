import { styled } from "styled-components";
import { fontNames, theme } from "../../Theme";

const baseText = `
  font-family: ${fontNames.inter};
  color: inherit;
`;
const baseMarging = `
  margin-bottom: 24px;
`;

const BaseH1 = styled.h1`
  ${baseText}
  font-size: 44px;
  line-height: 52px; /* 125% */
  letter-spacing: -0.44px;

  /* Mobile/H1/Regular */
  ${theme.mediaQueries.maxMobile} {
    font-size: 40px;
    line-height: 48px; /* 120% */
    letter-spacing: -0.4px;
  }
`;

export const H1 = {
  Light: styled(BaseH1)`
    font-weight: 300;
  `,
  Regular: styled(BaseH1)`
    font-weight: 400;
  `,
  Semibold: styled(BaseH1)`
    font-weight: 600;
  `,
};

const BaseH2 = styled.h2`
  ${baseText}
  font-size: 40px;
  line-height: 48px; /* 125% */
  letter-spacing: -0.4px;

  /* Mobile/H2/Regular */
  ${theme.mediaQueries.maxMobile} {
    font-size: 36px;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.36px;
  }
`;

export const H2 = {
  Light: styled(BaseH2)`
    font-weight: 300;
  `,
  Regular: styled(BaseH2)`
    font-weight: 400;
  `,
  Semibold: styled(BaseH2)`
    font-weight: 600;
  `,
};

const BaseH3 = styled.h3`
  ${baseText}
  font-size: 36px;
  line-height: 44px; /* 125% */
  letter-spacing: -0.36px;

  /* Mobile/H3/Regular */
  ${theme.mediaQueries.maxMobile} {
    font-size: 32px;
    line-height: 40px; /* 125% */
    letter-spacing: -0.32px;
  }
`;

export const H3 = {
  Light: styled(BaseH3)`
    font-weight: 300;
  `,
  Regular: styled(BaseH3)`
    font-weight: 400;
  `,
  Semibold: styled(BaseH3)`
    font-weight: 600;
  `,
};

const BaseH4 = styled.h4`
  ${baseText}
  font-size: 32px;
  line-height: 40px; /* 125% */
  letter-spacing: -0.32px;

  /* Mobile/H4/Regular */
  ${theme.mediaQueries.maxMobile} {
    font-size: 24px;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.24px;
  }
`;

export const H4 = {
  Light: styled(BaseH4)`
    font-weight: 300;
  `,
  Regular: styled(BaseH4)`
    font-weight: 400;
  `,
  Semibold: styled(BaseH4)`
    font-weight: 600;
  `,
};

const BaseH5 = styled.h5`
  ${baseText}
  font-size: 24px;
  line-height: 32px; /* 125% */
  letter-spacing: -0.24px;

  /* Mobile/H5/Regular */
  ${theme.mediaQueries.maxMobile} {
    font-size: 20px;
    line-height: 24px; /* 120% */
    letter-spacing: -0.2px;
  }
`;

export const H5 = {
  Light: styled(BaseH5)`
    font-weight: 300;
  `,
  Regular: styled(BaseH5)`
    font-weight: 400;
  `,
  Semibold: styled(BaseH5)`
    font-weight: 600;
  `,
};

const baseBody = `
  text-align: center;
  font-style: normal;
`;

export const Body = {
  SM: {
    Light: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 14px;
      font-weight: 300;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.14px;
    `,
    Regular: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.14px;
    `,
    Semibold: styled.p`
    ${baseText}
    ${baseMarging}
    ${baseBody}
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.14px;
  `,
  },
  MD: {
    Regular: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,
    Light: styled.p`
    ${baseText}
    ${baseMarging}
    ${baseBody}
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  `,
  Semibold: styled.p`
    ${baseText}
    ${baseMarging}
    ${baseBody}
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  `,
  },
  LG: {
    Regular: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 20px;
      font-weight: 400;
      line-height: 28px; /* 140% */
      letter-spacing: -0.2px;
    `,
    Light: styled.p`
    ${baseText}
    ${baseMarging}
    ${baseBody}
    font-size: 20px;
    font-weight: 300;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
  `,
  Semibold: styled.p`
    ${baseText}
    ${baseMarging}
    ${baseBody}
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
  `,
  },
  Link: {
    Regular: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      text-decoration-line: underline;
    `,
    Light: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 16px;
      font-weight: 300;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      text-decoration-line: underline;
    `,
    Semibold: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      text-decoration-line: underline;
    `,
  },
};

const BaseMobileMD = `
  ${theme.mediaQueries.maxMobile} {
    /* Mobile/Display/Md/Light */
    font-size: 56px;
    line-height: 64px; /* 114.286% */
    letter-spacing: -0.56px;
  }
`;

const BaseMobileSM = `
  ${theme.mediaQueries.maxMobile} {
    /* Mobile/Display/Sm/Light */
    font-size: 40px;
    line-height: 48px; /* 120% */
    letter-spacing: -0.4px;
  }
`

export const Display = {
  MD: {
    Thin: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      ${BaseMobileMD}
      font-size: 64px;
      font-weight: 100;
      line-height: 72px; /* 112.5% */
      letter-spacing: -0.64px;
    `,
    Light: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      ${BaseMobileMD}
      font-size: 64px;
      font-weight: 300;
      line-height: 72px; /* 112.5% */
      letter-spacing: -0.64px;
    `,
  },
  SM: {
    Thin: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      ${BaseMobileSM}

      font-size: 44px;
      font-weight: 100;
      line-height: 52px; /* 118.182% */
      letter-spacing: -0.44px;
    `,
    Light: styled.p`
      ${baseText}
      ${baseMarging}
      ${baseBody}
      ${BaseMobileSM}
      font-size: 44px;
      font-weight: 300;
      line-height: 52px; /* 118.182% */
      letter-spacing: -0.44px;
    `,
  },
};

export const FootNotes = styled.p`
  ${baseText}
  ${baseBody}
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.12px;
  color: ${(props) =>
    props.color ? props.color : `${theme.colors.neutral[400]}!important;`};
  padding: 0;
  margin: 0;
`;
